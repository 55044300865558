import React from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';

import { Styles } from '../../models/styles.model';
import { colors, sizes } from '../../themes';
import { fontFamily } from '../../themes/storyslab-light/fonts';

interface Props {
  defaultValue?: string;
  isPassword?: boolean;
  label: string;
  onChangeText: (text: string) => void;
  value: string;
}

const styles: Styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  inputLabel: {
    color: colors.text,
    fontFamily: fontFamily.regular,
  },
  inputText: {
    borderBottomWidth: 1,
    borderColor: colors.text,
    fontFamily: fontFamily.regular,
    fontSize: sizes.md,
    marginTop: sizes.sm,
  },
});

export function LabeledTextInput(props: Props): JSX.Element {
  const { defaultValue, isPassword, label, onChangeText, value } = props;

  return (
    <View style={styles.container}>
      <Text style={styles.inputLabel}>{label}</Text>

      <TextInput
        defaultValue={defaultValue}
        style={styles.inputText}
        onChangeText={(text: string): void => onChangeText(text)}
        value={value}
        secureTextEntry={isPassword || false}
      />
    </View>
  );
}
