import { CollectionsItem, CollectionItemChild } from 'models/data/collections.model';
import { WorkspaceContextProps } from 'models/workspace.model';

export async function getCollectionById({
    id,
  }: {
    id: number;
  }): Promise<CollectionsItem | null> {

    let collectionsItemByIdJSON = window.sessionStorage.getItem('collectionsItemById');

    if(collectionsItemByIdJSON) {

      let collectionsItemById = JSON.parse(collectionsItemByIdJSON);

      return collectionsItemById[id] || null;
    }

    return null;
  }
  
  export async function getCollectionChildrenById({
    id,
  }: {
    id: number;
  }): Promise<Array<CollectionItemChild> | null> {
    const currentCollection = await getCollectionById({id});
    
    if(currentCollection) {
        return currentCollection.children;
    }

    return null;
  }