import { NavigationItem } from '@storyslab/storyslab.common.models';

import { AuthState } from '../enums/auth.enum';
import { WorkspaceSyncState } from '../enums/sync.enum';
import { ContextI } from '../models/context.model';
import {
  TemporaryCognitoCredentials,
  WorkspaceContextProps,
} from '../models/workspace.model';
import { fetchTemporaryCredentials } from '../services/cognito.service';

import { FeatureTypes } from '../enums/feature.enum';

type WorkspaceContextI = ContextI<WorkspaceContextProps>;

export function workspaceActions(context: WorkspaceContextI): {
  [key: string]: (params?: any) => Promise<any> | void;
} {
  return {
    clearErrorState: (): void => clearErrorState(context),
    logout: (): void => logout(context),
    refreshTemporaryCredentials: (): void =>
      refreshTemporaryCredentials(context),
    setErrorState: (message: string): void => setErrorState(context, message),
    setFeatureUiState: ({ feature, state }): void =>
      setFeatureUiState(context, feature, state),
    setSelectedNavigationItem: (workspaceParams: NavigationItem): void =>
      setSelectedNavigationItem(context, workspaceParams),
  };
}

function setFeatureUiState(
  [, setWorkspace]: WorkspaceContextI,
  feature: FeatureTypes,
  state: boolean,
): void {
  console.log('FEATURE', feature);
  console.log('STATE', state);
  setWorkspace((prevState: Partial<WorkspaceContextProps>) => {
    console.log('PrevState', prevState);
    return {
      ...prevState,
      featureUiState: {
        ...prevState.featureUiState,
        [`${feature}Selected`]: state,
      },
    };
  });
}

function setErrorState(
  [, setWorkspace]: WorkspaceContextI,
  message: string,
): void {
  setWorkspace({
    errorState: { message: message },
  });
}

function clearErrorState([, setWorkspace]: WorkspaceContextI): void {
  setWorkspace({
    errorState: null,
  });
}

function logout([workspace, setWorkspace]: WorkspaceContextI): void {
  delete workspace.credentials;
  delete workspace.temporaryCognitoCredentials;
  delete workspace.user;

  workspace = {
    ...workspace,
    authState: AuthState.LOGGED_OUT,
    syncState: WorkspaceSyncState.NONE,
  };

  setWorkspace(workspace);
}

function setSelectedNavigationItem(
  [workspace, setWorkspace]: WorkspaceContextI,
  navigationItem: NavigationItem,
): void {
  setWorkspace({
    templateParams: {
      ...workspace.templateParams,
      selectedNavigationItem: navigationItem,
    },
  });
}

function refreshTemporaryCredentials([
  workspace,
  setWorkspace,
]: WorkspaceContextI): void {
  fetchTemporaryCredentials(workspace)
    .then((temporaryCredentials: TemporaryCognitoCredentials | null) => {
      setWorkspace({
        ...workspace,
        temporaryCognitoCredentials: temporaryCredentials,
      });
    })
    .catch((err: any) => {
      console.log(err);
      setWorkspace({
        ...workspace,
        temporaryCognitoCredentials: null,
      });
    });
}
