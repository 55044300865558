/* eslint-disable @typescript-eslint/no-require-imports */

import React from 'react';

import DefaultTemplate from './default/Template';

export enum TemplateNames {
  DEFAULT = 'default',
}

export function getTemplate(
  templateName: TemplateNames,
): React.FunctionComponent {
  switch (templateName) {
    case TemplateNames.DEFAULT:
     return DefaultTemplate;
    default:
      throw new Error(`Template ${templateName} does not exist`);
  }
}
