import React from 'react';

import { isDateExpiredGmt } from '../helpers/date.helper';
import {
  areCredentialsExpired,
  isUserLoggedInAndHasTempCredentials,
} from '../helpers/workspace-navigation.helper';
import { WorkspaceContextProps } from '../models/workspace.model';

import { isSyncRequired } from '../sync/helpers/sync.helper';

export function shouldStartSync(
  workspace: Partial<WorkspaceContextProps>,
): boolean {
  return !!(
    workspace.initialized &&
    !!workspace.entitlements &&
    workspace.entitlements?.length > 0 &&
    isUserLoggedInAndHasTempCredentials(workspace) &&
    isSyncRequired(workspace) &&
    !isDateExpiredGmt(
      workspace.temporaryCognitoCredentials?.credentials.expiration,
    )
  );
}

export function effectStartSyncDependencyList(
  workspace: Partial<WorkspaceContextProps>,
): React.DependencyList {
  return [
    workspace?.netInfoState,
    workspace?.initialized,
    workspace?.entitlements,
    workspace?.credentials,
    workspace?.temporaryCognitoCredentials,
  ];
}

export function shouldAuthenticateAppUser(
  workspace: Partial<WorkspaceContextProps>,
): boolean {
  return !!(
    !areCredentialsExpired(workspace) &&
    !workspace.entitlements &&
    workspace.applicationId &&
    workspace.credentials?.idToken &&
    workspace.credentials?.accessToken
  );
}

export function shouldFetchTemporaryCredentials(
  workspace: Partial<WorkspaceContextProps>,
): boolean {
  return (
    !workspace.temporaryCognitoCredentials ||
    isDateExpiredGmt(
      workspace.temporaryCognitoCredentials?.credentials.expiration,
    )
  );
}
