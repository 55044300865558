import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  ImageStyle,
  ListRenderItemInfo,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import AssetImage from '../../../../components/images/AssetImage';
import { H5 } from '../../../../components/typography';
import { CollectionsItem } from '../../../../models/data/collections.model';
import { Styles } from '../../../../models/styles.model';
import { colors, fontFamily } from '../../../../themes';
import { sizes } from '../../../../themes/sizes';

import HorizontalBase, { Props as BaseProps } from './horizontal-base';

interface Props extends Partial<BaseProps> {
  onPress?: (item: CollectionsItem) => void;
  selectedItem?: number | null;
}

const styles: Styles = StyleSheet.create({
  backgroundImage: {
    height: 148,
    left: 0,
    position: 'absolute',
    top: 0,
    width: 216,
  } as ImageStyle,
  banner: {
    backgroundColor: '#101010',
    marginBottom: 22,
    paddingHorizontal: sizes.md,
    paddingVertical: sizes.xs,
    width: 216 * 0.9,
    zIndex: 1,
  } as ViewStyle,
  bannerText: {
    color: colors.textWhite,
    fontFamily: fontFamily.bold,
    textAlign: 'right',
  } as TextStyle,
  flatList: {
    height: 148,
  } as ViewStyle,
  item: {
    alignItems: 'flex-end',
    height: 148,
    justifyContent: 'flex-end',
    marginLeft: 10,
    width: 216,
  } as ViewStyle,
  touchable: {
    height: 148,
    width: 216,
  } as ViewStyle,
});

export const HorizontalLowerThirdScroll: FunctionComponent<Props> =
  function HorizontalLowerThirdScroll(
    props: PropsWithChildren<Props>,
  ): JSX.Element {
    return (
      <HorizontalBase
        contentInset={{
          bottom: 0,
          left: 100,
          right: 10,
          top: 0,
        }}
        contentOffset={{ x: -100, y: 0 }}
        {...props}
        style={[styles.flatList, props.style]}
        data={props.data}
        renderItem={(
          datum: ListRenderItemInfo<CollectionsItem>,
        ): JSX.Element => {
          return (
            <TouchableOpacity
              style={styles.item}
              onPress={(): void => {
                if (props.onPress) {
                  props.onPress(datum.item);
                }
              }}
            >
              {!datum.item.hideTitles && (
                <View style={styles.banner}>
                  <H5 style={styles.bannerText}>{datum.item.text}</H5>
                </View>
              )}

              {datum.item.image && (
                <AssetImage
                  assetId={datum.item.image}
                  style={styles.backgroundImage}
                  resizeMode={'cover'}
                />
              )}
            </TouchableOpacity>
          );
        }}
      />
    );
  };

export default HorizontalLowerThirdScroll;
