/* eslint-disable @typescript-eslint/no-empty-interface */
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';

import { IconButton } from '../../../components/buttons/Icon';
import ButtonRounded from '../../../components/buttons/Rounded';
import Icon from '../../../components/icons/Icon';
import {
  useChromeActions,
  useChromeContext,
} from '../../../context/workspace/chrome.context';
import {
  useFeaturesActions,
  useFeaturesContext,
} from '../../../context/workspace/features.context';
import { useWorkspace } from '../../../context/workspace/workspace.context';
import { Styles } from '../../../models/styles.model';
import { colors, sizes } from '../../../themes';

interface Props {}

const styles: Styles = StyleSheet.create({
  button: {
    paddingHorizontal: sizes.md,
  } as ViewStyle,
  buttonIcon: {
    marginLeft: sizes.sm,
  } as FontAwesomeIconStyle,
  buttonText: {
    color: colors.white,
    fontSize: sizes.md,
  } as TextStyle,
  closeButton: {
    color: colors.textDark,
    marginLeft: sizes.sm,
  } as ViewStyle,
  container: {
    flexDirection: 'row',
  } as ViewStyle,
  marginLeft: {
    marginLeft: sizes.sm,
  } as ViewStyle,
});

export const CollectionViewHeaderSharePlus: FunctionComponent<Props> =
  function CollectionViewHeaderSharePlus(): JSX.Element {
    const [workspace] = useWorkspace();
    const [chromeContext] = useChromeContext();
    const {
      handleClearBundleSuccess,
      handleSharePlusSendCurrentBundleRequest,
      hasItems,
      renderItemCount,
    } = useFeaturesActions();
    const [isLoading, setIsLoading] = useState(false);

    const {
      handleSharePlusTap,
      toggleSharePlusSelect,
      showMainChromeDrawer,
      sharePlus,
    } = useChromeActions();

    if (chromeContext.sharePlusSelect) {
      return (
        <>
          <ButtonRounded
            accessibilityLabel={'GoTo Share+'}
            backgroundColor={colors.darkGray}
            titleColor={colors.textWhite}
            onPress={(): void => {
              handleSharePlusTap();
              showMainChromeDrawer();
              sharePlus.handleSharePlusCurrentTap();
            }}
            style={styles.button}
          >
            <Text style={styles.buttonText}>GoTo</Text>
            <Icon
              icon="paper-plane"
              size={20}
              style={[styles.buttonIcon, { color: colors.white }]}
            />
          </ButtonRounded>

          <ButtonRounded
            accessibilityLabel={'Send Share+ Bundle'}
            backgroundColor={
              hasItems() ? workspace.styles?.primaryColor : colors.light
            }
            isDisabled={!hasItems()}
            titleColor={colors.textWhite}
            onPress={async (): Promise<void> => {
              if (hasItems()) {
                setIsLoading(true);
                await handleSharePlusSendCurrentBundleRequest(workspace);
                handleClearBundleSuccess();
                setIsLoading(false);
              }
            }}
            style={[styles.button, styles.marginLeft]}
          >
            <Text style={styles.buttonText}>
              Send {!isLoading && hasItems() && `(${renderItemCount()})`}
            </Text>
            {isLoading && (
              <ActivityIndicator
                size="small"
                color={colors.white}
                style={styles.activityIndicator}
              />
            )}
          </ButtonRounded>

          <IconButton
            icon={'times'}
            size={42}
            style={styles.closeButton}
            onPress={(): void => {
              toggleSharePlusSelect();
            }}
          />
        </>
      );
    }

    return <></>;
  };

export default CollectionViewHeaderSharePlus;
