import { ComponentTargetTypes } from '../enums/component.enum';
import { ComponentConfig } from '../models/data';
import {
  CollectionItemChild,
  CollectionsItem,
} from '../models/data/collections.model';
import { ComponentTarget } from '../models/data/component.model';
import { WorkspaceContextProps } from '../models/workspace.model';

import { getAssetById } from '../accessors/asset.accessor';
import {
  getCollectionById,
  getCollectionChildrenById,
} from '../accessors/collection.accessor';
import { getContentFileById } from '../accessors/content-files.accessor';

import { handlebarMustache } from './text.helper';

export async function getTarget(
  id: number,
  type: ComponentTargetTypes,
): Promise<ComponentTarget> {
  let target: ComponentTarget = null;

  if (typeof id !== 'number') {
    return target;
  }

  switch (type) {
    case ComponentTargetTypes.COLLECTION:
      target = await getCollectionById({ id });
      break;
    case ComponentTargetTypes.CONTENT_ITEM:
    case ComponentTargetTypes.LINK:
      target = await getContentFileById({ id }) as ComponentTarget;
      break;
    case ComponentTargetTypes.FILE:
      target = await getAssetById({ id });
      break;
    default:
      break;
  }

  if (target) {
    if (!target?.type) {
      target.type = type;
    }

    target = {
      ...target,
      parentType: type,
    };
  }

  return target;
}

export async function getComponentTarget(
  id: number,
  type: ComponentTargetTypes,
): Promise<ComponentTarget> {
  let target: ComponentTarget = null;

  if (typeof id !== 'number') {
    return target;
  }

  switch (type) {
    case ComponentTargetTypes.COLLECTION:
      target = await getCollectionById({ id });
      break;
    case ComponentTargetTypes.CONTENT_ITEM:
    case ComponentTargetTypes.LINK:
    case ComponentTargetTypes.FILE:
      target = await getContentFileById({ id }) as ComponentTarget;
      break;
    default:
      break;
  }

  if (target) {
    if (!target?.type) {
      target.type = type;
    }

    target = {
      ...target,
      parentType: type,
    };
  }

  return target;
}

export async function generateComponentConfig<T>({
  workspace,
  config,
}: {
  workspace: Partial<WorkspaceContextProps>;
  config: ComponentConfig<T>;
}): Promise<any> {
  if (config.target && typeof (config.target as any).id === 'number') {
    config.target = (config.target as any).id;
  }

  let target: any = await getComponentTarget(+config.target, config.targetType);

  if (config.targetType === ComponentTargetTypes.COLLECTION) {
    target = {
      ...target,
      children: await getCollectionChildrenById({ id: +config.target }),
    };
  }

  if (Array.isArray(config.itemProps)) {
    config = {
      ...config,
      itemProps: config.itemProps.reduce((acc: any, current: any) => {
        if (current.value) {
          current.value = handlebarMustache(current.value, { target });
        }

        acc[current.key] = current.value;
        return acc;
      }, {}),
    };
  }

  if (Array.isArray(config.props)) {
    config = {
      ...config,
      props: config.props.reduce((acc: any, current: any) => {
        if (current.value) {
          current.value = handlebarMustache(current.value, { target });
        }

        acc[current.key] = current.value;
        return acc;
      }, {}),
    };
  }

  return {
    ...config,
    target: target,
  };
}

export function resolveAllChildren({ children }: { children: any }): any {
  if (!Array.isArray(children)) {
    return Promise.resolve([]);
  }

  return Promise.all(
    children.map((child: CollectionItemChild) => {
      if (child) {
        return getComponentTarget(child.itemId, child.type);
      }

      return null;
    }),
  ).then((mappedChildren: Array<ComponentTarget>) =>
    mappedChildren.filter((mappedChild: ComponentTarget) => mappedChild),
  );
}

export async function resolveAllChildrenById({
  id,
  componentConfig,
  props,
}: {
  id: number;
  componentConfig: any;
  props?: Record<string, any>;
}): Promise<Record<string, any>> {
  const collectionChildren: Array<CollectionItemChild> | null =
    await getCollectionChildrenById({ id });

  return resolveAllChildren({
    children: collectionChildren,
  })
    .then((resolvedChildren: Array<CollectionsItem>) => {
      return mapChildrenWithConfig<CollectionsItem>({
        children: resolvedChildren,
        componentConfig,
        props: { ...props, id: '{{item.id}}', key: '{{item.id}}' },
      });
    })
    .then((resolvedChildren: Array<CollectionsItem>) => {
      return resolvedChildren;
    });
}

export function mapChildrenWithConfig<T>({
  children,
  componentConfig,
  props,
}: {
  children: Array<any>;
  componentConfig: ComponentConfig<T>;
  props?: Record<string, any>;
}): Array<Record<string, any>> {
  /*
   * The Object template is the object we are going to eventually output. This is usually based on the
   * itemProps object.
   */

  const objTemplate: Record<string, any> = {
    ...props,
    ...componentConfig.itemProps,
  };

  return children.map((child: any) => {
    // Clone the objectTemplate so that we don't overwrite the original.
    let cloneTemplate: Record<string, any> = { ...objTemplate };

    // Loop over the object to get all the keys out.
    for (const key in cloneTemplate) {
      if (Object.prototype.hasOwnProperty.call(cloneTemplate, key)) {
        // Immutably update the cloneTemplate object.
        cloneTemplate = {
          ...cloneTemplate,
          [key]: convertStringToBoolean(
            // Use our handlebarMustache parser to replace the handlebar template with the actual value.
            handlebarMustache(
              /*
               * Check to see if cloneTemplate[key].value exists, because if it does we got the template item from
               * the manifest. If not we are just passing in a key/value pair.
               */

              cloneTemplate[key].value !== undefined
                ? cloneTemplate[key].value
                : cloneTemplate[key],

              // Build the lookup object handlebarMustache uses to populate the value with.
              { ...componentConfig, item: child },
            ),
          ),
        };
      }
    }

    return cloneTemplate;
  });
}

export function convertStringToBoolean(suspect: unknown): unknown | boolean {
  switch (suspect) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return suspect;
  }
}
