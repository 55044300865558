import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import merge from 'lodash/merge';

import { isPhone, isTablet } from './device.helper';

type NamedStyles<T> = { [P in keyof T]: ViewStyle | TextStyle | ImageStyle };

export function createDynamicStyleSheet<
  T extends NamedStyles<T> | NamedStyles<any>,
>(sharedStyles: T, deviceStyles: { phone?: T; tablet?: T } = {}): T {
  let styles: T | null = {} as T;
  if (isPhone && deviceStyles.phone) {
    styles = deviceStyles.phone;
  } else if (isTablet && deviceStyles.tablet) {
    styles = deviceStyles.tablet;
  }

  return StyleSheet.create<T>(merge(sharedStyles, styles));
}
