import React from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import Icon from '../../../components/icons/Icon';
import { useChromeActions } from '../../../context/workspace/chrome.context';
import { Styles } from '../../../models/styles.model';
import { sizes } from '../../../themes/sizes';

import OptionsMenu from '../options/OptionsMenu';

type OnPress = (id: string) => void;

interface Props {
  iconColor?: string;
  selectedColor?: string;
  shouldDisableMarginLeft?: boolean;
  onPress?: OnPress;
}

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: sizes.md,
  } as ViewStyle,
  icon: {
    marginHorizontal: sizes.sm,
  } as ViewStyle,
});

const iconSize: number = 35;

export const SimpleIconsMenu: React.FunctionComponent<Props> =
  function SimpleIconsMenu(props: React.PropsWithChildren<Props>): JSX.Element {
    const { handleHelpTap, handleSettingsTap, isInternalMenuItemSelected } =
      useChromeActions();

    const { shouldDisableMarginLeft, selectedColor, iconColor } = props;

    return (
      <View
        style={[
          styles.container,
          shouldDisableMarginLeft ? styles.noMarginLeft : styles.marginLeft,
        ]}
      >
        <TouchableOpacity
          onPress={(): void => {
            handleHelpTap();
          }}
        >
          <Icon
            icon="question-circle"
            size={iconSize}
            style={[
              styles.icon,
              {
                color: isInternalMenuItemSelected('help')
                  ? selectedColor
                  : iconColor,
              },
            ]}
          />
        </TouchableOpacity>
        <OptionsMenu
          isTop={true}
          trigger={
            <Icon
              icon="cog"
              size={iconSize}
              style={[
                styles.icon,
                {
                  color: isInternalMenuItemSelected('settings')
                    ? selectedColor
                    : iconColor,
                },
              ]}
            />
          }
          options={[
            {
              icon: 'sign-out',
              onSelect: (): void => {
                handleSettingsTap();
              },
              title: 'Log out',
            },
          ]}
        />
        {/* <TouchableOpacity
          onPress={(): void => {
            handleNotificationsTap();
          }}
        >
          <Icon
            icon="bell"
            size={iconSize}
            style={[
              styles.icon,
              {
                color: isInternalMenuItemSelected('notifications')
                  ? selectedColor
                  : iconColor,
              },
            ]}
          />
        </TouchableOpacity> */}
      </View>
    );
  };

export default SimpleIconsMenu;
