import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { useChromeContext } from '../../../context/workspace/chrome.context';
import { Styles } from '../../../models/styles.model';
import { sizes } from '../../../themes/sizes';

import DrawerMenuItem, { Props as DrawerMenuItemProps } from './DrawerMenuItem';

interface Props {
  items: Array<DrawerMenuItemProps>;
  iconColor?: string;
  selectedColor?: string;
  shouldDisableMarginLeft?: boolean;
}

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    margin: 0,
    marginTop: sizes.xs,
  } as ViewStyle,
  marginLeft: {
    marginLeft: sizes.md,
  } as ViewStyle,
  noMarginLeft: {
    marginLeft: 0,
  } as ViewStyle,
});

export const DrawerMenuDynamic: React.FunctionComponent<Props> =
  function DrawerMenu(props: React.PropsWithChildren<Props>): JSX.Element {
    const [chromeContext] = useChromeContext();

    const { shouldDisableMarginLeft, items, selectedColor, iconColor } = props;

    return (
      <View
        style={[
          styles.container,
          shouldDisableMarginLeft ? styles.noMarginLeft : styles.marginLeft,
        ]}
      >
        {items &&
          items.map((itemProps: DrawerMenuItemProps, index: number) => {
            return (
              <DrawerMenuItem
                {...{
                  ...itemProps,
                  iconColor: iconColor,
                  isSelected:
                    chromeContext.selectedDynamicMenuItem ===
                    itemProps?.navigationItem?.id,
                  selectedColor: selectedColor,
                }}
                key={index}
              />
            );
          })}
      </View>
    );
  };

export default DrawerMenuDynamic;
