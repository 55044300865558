/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dimensions, GestureResponderEvent, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { defaultLayoutConfig } from '../config/defaultViewLayoutConfig';
import { useChromeContext } from '../context/workspace/chrome.context';
import { useFeaturesActions } from '../context/workspace/features.context';
import { useWorkspace } from '../context/workspace/workspace.context';
import { CollectionItemChildType } from '../enums/collections.enum';
import { ComponentTargetTypes } from '../enums/component.enum';
import {
  generateComponentConfig,
  resolveAllChildrenById,
} from '../helpers/component.helper';
import { getViewByViewId } from '../helpers/workspace-navigation.helper';
import { ColumnListItem } from '../models/column-list.model';
import {
  ComponentConfig,
  LayoutConfiguration,
  ViewsManifestItem,
} from '../models/data';
import { CollectionsItem } from '../models/data/collections.model';
import { TemplateNavigationProp } from '../templates/default/models/template.model';
import { sizes } from '../themes';

import { getCollectionById } from '../accessors/collection.accessor';

import { useComponentNavigation } from './component-navigation.hook';

export function useColumnList(collectionId?: number): any {
  const [workspace, setWorkspace] = useWorkspace();
  const [chromeContext] = useChromeContext();
  const { width } = Dimensions.get('window');

  const columnWidth: number = width * 0.28 + sizes.xs;
  const navigation: TemplateNavigationProp = useNavigation();
  const scrollViewRef: React.RefObject<ScrollView> = useRef<ScrollView>(null);

  const { addSharePlusItem, isSharePlusItemSelected, removeSharePlusItem } =
    useFeaturesActions();

  const [collection, setCollection] = useState<CollectionsItem | null>();
  const [columnData, setColumnData] =
    useState<Array<Record<string, Array<any>>>>();
  const [collectionStack, setCollectionStack] = useState<Array<string> | null>(
    [],
  );
  const [componentConfig, setComponentConfig] =
    useState<LayoutConfiguration<any>>();
  const [cardConfig, setCardConfig] =
    useState<ComponentConfig<CollectionsItem> | null>();

  const setNavigationItemId: (id: number, type: ComponentTargetTypes) => void =
    useComponentNavigation();

  const scrollViewWidth: number = useMemo(() => {
    return columnWidth * (columnData?.length || 0);
  }, [columnData]);

  useEffect(() => {
    return (): void => {
      setWorkspace({
        highlightSelectedItem: null,
      });
    };
  }, []);

  useEffect(() => {
    setCollection(null);
    setColumnData([]);
    setCollectionStack([]);
    setCardConfig(null);

    if (collectionId) {
      getCollectionById({ id: collectionId }).then(
        (collectionDatum: CollectionsItem | null) => {
          if (collectionDatum) {
            setCollection(collectionDatum);
            setCollectionStack([collectionDatum?.name]);
          }

          if (collectionDatum?.viewId) {
            const view: ViewsManifestItem<any> | null = getViewByViewId(
              collectionDatum.viewId,
              { workspace: workspace },
            );

            if (view) {
              setComponentConfig(view.layoutConfiguration);
            }
          } else {
            setComponentConfig(defaultLayoutConfig);
          }
        },
      );
    }
  }, [collectionId]);

  useEffect(() => {
    if (componentConfig && collection?.id) {
      if (componentConfig?.components?.reduce) {
        // TODO Remove once Jared converts JSON array to object.
        componentConfig.components = componentConfig.components.reduce(
          (acc: any, current: any) => {
            acc[current.key] = current;
            return acc;
          },
          {},
        );
      }

      generateComponentConfig({
        config: (componentConfig?.components as any).card,
        workspace,
      }).then((config: ComponentConfig<CollectionsItem>) => {
        setCardConfig(config);
      });
    }
  }, [collection, componentConfig]);

  useEffect(() => {
    if (cardConfig && collection?.id) {
      resolveAllChildrenById({
        componentConfig: cardConfig,
        id: collection.id,
        props: {
          parentType: '{{item.parentType}}',
          targetId: '{{item.target}}',
          thumbnailAssetId: '{{item.thumbnailAssetId}}',
          title: '{{item.name}}',
          type: '{{item.type}}',
          viewId: '{{item.viewId}}',
        },
      }).then((resolvedChildren: Record<string, any>) => {
        setColumnData([resolvedChildren]);
      });
    }
  }, [cardConfig]);

  function handleRowTap(
    event: GestureResponderEvent,
    itemData: ColumnListItem | undefined,
    index: number,
  ): void {
    if (!itemData) {
      return;
    }

    if (itemData.parentType !== CollectionItemChildType.COLLECTION) {
      if (chromeContext.sharePlusSelect) {
        if (isSharePlusItemSelected(itemData)) {
          removeSharePlusItem(itemData);
        } else {
          addSharePlusItem(itemData);
        }
        return;
      }

      if (itemData.viewId === null) {
        setNavigationItemId(itemData.id, itemData.type);
        return;
      }
    }

    if (itemData.viewId) {
      setNavigationItemId(itemData.id, itemData.type);
      return;
    }

    setCollectionStack((prevState: any) => {
      return [...prevState.slice(0, index + 1), itemData?.title];
    });

    if (componentConfig && itemData?.id) {
      resolveAllChildrenById({
        componentConfig: cardConfig,
        id: itemData?.id,
        props: {
          parentType: '{{item.parentType}}',
          targetId: '{{item.target}}',
          thumbnailAssetId: '{{item.thumbnailAssetId}}',
          title: '{{item.name}}',
          type: '{{item.type}}',
          viewId: '{{item.viewId}}',
        },
      }).then((resolvedChildren: Record<string, any>) => {
        setColumnData((prevState: any) => {
          return [...prevState.slice(0, index + 1), resolvedChildren];
        });
      });
    }
  }

  return {
    collection,
    collectionStack,
    columnData,
    handleRowTap,
    navigation,
    scrollViewRef,
    scrollViewWidth,
  };
}
