import { OAuthPayload, OAuthPayloadRaw } from 'models/auth.model';

import { getTimestampInFuture } from './date.helper';

export function mapCredentials(rawCredentials: OAuthPayloadRaw): OAuthPayload {
  return {
    accessToken: rawCredentials.access_token,
    expiresAt: getTimestampInFuture(rawCredentials.expires_in),
    expiresIn: rawCredentials.expires_in,
    idToken: rawCredentials.id_token,
    refreshToken: rawCredentials.refresh_token,
    tokenType: rawCredentials.token_type,
  };
}
