/* eslint-disable max-lines-per-function */

import React, { useEffect, useRef } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';

import WorkspaceProvider, {
  useWorkspace,
} from '../context/workspace/workspace.context';
// TODO: Create dummy web version of this
// import WorkspaceSyncProvider from 'context/workspace/workspace-sync.context';
import { isUserLoggedInAndHasTempCredentials } from '../helpers/workspace-navigation.helper';
import { DomainStackParamList } from '../models/domain.model';
import { WorkspaceStackParamList } from '../models/workspace.model';
import ChooseLoginScreen from '../screens/workspace/login/ChooseLogin';
import GenericLoginScreen from '../screens/workspace/login/GenericLogin';
import { oauthIdpCallback } from '../services/auth.service';
// TODO: Create dummy web version of this
// import SyncScreen from '../screens/workspace/sync/Sync';
import WorkspaceRootScreen from '../screens/workspace/WorkspaceRoot';
// TODO: Create dummy web version of this
// import { shouldShowSyncScreen } from '../sync/helpers/sync.helper';

import {
  DomainNavigationEnum,
  WorkspaceNavigationEnum,
} from '../enums/navigation.enum';

const Stack: any = createStackNavigator<WorkspaceStackParamList>();

export const navigationRef: React.RefObject<any> = React.createRef();

const WorkspaceNavigation: React.FunctionComponent<Record<string, any>> =
  () => {
    const [workspace, setWorkspace] = useWorkspace();

    const didRender: React.MutableRefObject<boolean> = useRef(false);

    const navigation: StackNavigationProp<
      DomainStackParamList,
      DomainNavigationEnum.WorkspaceNavigator
    > = useNavigation();

    useEffect(() => {
      if (workspace.initialized && !workspace.assets) {
        console.log('Workspace was corrupted, go back to app code screen.');
        navigation.reset({
          index: 0,
          routes: [{ name: DomainNavigationEnum.AppCodeScreen }],
        });
        return;
      }
    }, []);

    useEffect(() => {
      if (workspace.initialized && workspace.assets) {
        oauthIdpCallback([workspace, setWorkspace]);
      }

    }, []);

    return (
      <Stack.Navigator
        screenOptions={{ gestureEnabled: false, headerShown: false }}
      >
        {isUserLoggedInAndHasTempCredentials(workspace) ? (
          <>
            {/* TODO: use a dummy sync screen for PWA */}
            {/* {shouldShowSyncScreen(workspace) ? (
              <>
                <Stack.Screen
                  component={SyncScreen}
                  name={WorkspaceNavigationEnum.SyncScreen}
                />
              </>
            ) : ( */}
              <>
                <Stack.Screen
                  component={WorkspaceRootScreen}
                  name={WorkspaceNavigationEnum.WorkspaceRootScreen}
                />
              </>
            {/* )} */}
          </>
        ) : (
          <>
            <Stack.Screen
              component={ChooseLoginScreen}
              options={{
                gestureEnabled: false,
              }}
              name={WorkspaceNavigationEnum.ChooseLoginScreen}
            />

            <Stack.Screen
              component={GenericLoginScreen}
              options={{
                gestureEnabled: false,
              }}
              name={WorkspaceNavigationEnum.GenericLoginScreen}
            />
          </>
        )}
      </Stack.Navigator>
    );
  };

const WorkspaceNavigator: React.FunctionComponent<Record<string, any>> = () => {
  const route: RouteProp<
    DomainStackParamList,
    DomainNavigationEnum.WorkspaceNavigator
  > = useRoute();

  const { applicationId } = route.params;

  return (
    <WorkspaceProvider
      applicationId={applicationId}
      initialValue={route.params}
    >
      {/* TODO: Use a dummy WorkspaceSyncProvider for the web */}
      {/* <WorkspaceSyncProvider applicationId={applicationId}> */}
        <WorkspaceNavigation />
      {/* </WorkspaceSyncProvider> */}
    </WorkspaceProvider>
  );
};

export function navigate(name: string, params: unknown): void {
  navigationRef.current?.navigate(name, params);
}

export default WorkspaceNavigator;
