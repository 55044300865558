import { Platform, UIManager } from 'react-native';

import { ContextI } from '../models/context.model';
import { FeaturesContextProps } from '../models/features.model';

import { sharePlusActions } from './features/share-plus.actions';

type FeaturesContextI = ContextI<FeaturesContextProps>;

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export function featuresActions(context: FeaturesContextI): any {
  return {
    ...sharePlusActions(context),
  };
}
