import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { Styles } from 'models/styles.model';

type Props = ViewProps;

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
});

export const Row: React.FunctionComponent<Props> = function Row(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  return (
    <View
      style={[
        styles.container,
        (props?.children as Array<any>)?.length > 1
          ? styles.justifyContentSpaceBetween
          : styles.justifyContentCenter,
        props.style,
      ]}
    >
      {props.children}
    </View>
  );
};
