import { WorkspaceContextI } from '../context/workspace/workspace.context';
import { ApiRequestMethods } from '../models/api.model';
import { DestructuredResponse } from '../models/promise.model';
import {
  TemporaryCognitoCredentials,
  WorkspaceContextProps,
} from '../models/workspace.model';

import { ClientApiUrls } from '../enums/client-api.enum';
import { extractAuthDetails } from '../helpers/util.helper';
import { fetchFromClientApi } from '../services/api.service';

export async function fetchTemporaryCredentials(
  workspaceContext: Partial<WorkspaceContextProps>,
): Promise<TemporaryCognitoCredentials | null> {
  return await fetchFromClientApi<TemporaryCognitoCredentials>({
    auth: extractAuthDetails(workspaceContext),
    method: ApiRequestMethods.GET,
    url: ClientApiUrls.CognitoIdentityCredentials,
  }).then(
    ([resp, error]: DestructuredResponse<TemporaryCognitoCredentials>) => {
      // Response.expiresAt = new Date(response.credentials.expiration);
      if (error) {
        throw new Error(error);
      }

      return resp;
    },
  );
}
