import React from 'react';
import { ImageProps, KeyboardAvoidingView, Platform, View } from 'react-native';

import BackgroundImage from '../../components/images/BackgroundImage';
import { createDynamicStyleSheet } from '../../helpers/styles.helper';
import { Styles } from '../../models/styles.model';
import { sizes } from '../../themes/sizes';

interface Props {
  backgroundUri?: string;
  backgroundSource?: ImageProps;
}

const styles: Styles = createDynamicStyleSheet({
  container: {
    alignItems: 'center',
    margin: sizes.md,
  },
  screen: {
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
});

export const FullScreenContainer: React.FunctionComponent<Props> =
  function FullScreenContainer(
    props: React.PropsWithChildren<Props>,
  ): JSX.Element {
    return (
      <>
        <BackgroundImage
          uri={props.backgroundUri}
          source={props.backgroundSource}
        />
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.screen}
        >
          <View style={styles.container}>{props.children}</View>
        </KeyboardAvoidingView>
      </>
    );
  };

export default FullScreenContainer;
