import React from 'react';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { IconName, Transform } from '@fortawesome/fontawesome-svg-core';

import {
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome';

import {
  SizeProp
} from '@fortawesome/fontawesome-svg-core';


import { Styles } from '../../models/styles.model';
import { Colors, colors } from '../../themes';

import { FontAwesomeLibraries } from '../../enums/font-awesome.enum';

/**
 * Font Awesome
 * https://github.com/FortAwesome/react-native-fontawesome
 *
 * Example:
 * import { faCoffee } from '@fortawesome/free-solid-svg-icons'
 * <Icon icon={faCoffee} />
 *
 */

// TODO: Instead of this could we use the power-transform feature to get more exact icon sizing, though it's all in units of em not px
function sizeToSizeProp(size:number | undefined):SizeProp {

  if(size === undefined) {
    return '1x';
  }

  switch(size) {
    case 16:
      return '1x';
      break;

    case 28:
    case 32:
    case 35:
      return '2x';
      break;

    case 50:
      return '3x';
      break;

    case 64:
      return '4x';
      break;

    default:
      console.log(`Unhandled size (${size}) for icon using 1x`);
      return '1x';
      break;
  }
}

interface Props {
  color?: string;
  library?: FontAwesomeLibraries;
  icon?: IconName;
  iconString?: string;
  size?: number;
  style?: StyleProp<ViewStyle>;
  transform?: string | Transform | undefined;
}

const styles: Styles = StyleSheet.create({
  icon: {},
});

export const Icon: React.FunctionComponent<Props> = function Icon(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  const { color, library, icon, iconString, size, style, transform } = props;

  return (
    // TODO: Fix the font awesome icon styling
    <FontAwesomeIcon
      // style={[styles.icon, { color: color ? color : colors.white }, style]}
      icon={[
        library ? library : FontAwesomeLibraries.FAL, 
        iconString ? (iconString as any) : icon,
      ]}
      // Fontawesome does size differently for the react vs react-native version
      size={sizeToSizeProp(size)}
      transform={transform}
    />
  );
};

export default Icon;
