/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import Icon from '../../../components/icons/Icon';
import { useChromeContext } from '../../../context/workspace/chrome.context';
import { useFeaturesActions } from '../../../context/workspace/features.context';
import { ComponentTargetTypes } from '../../../enums/component.enum';
import { FontAwesomeLibraries } from '../../../enums/font-awesome.enum';
import { Styles } from '../../../models/styles.model';
import { ColumnListItem } from '../../../models/column-list.model';
import { colors, sizes } from '../../../themes';

interface Props {
  data: ColumnListItem | undefined;
}

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  } as ViewStyle,
  itemIcon: {
    flexDirection: 'row',
    marginRight: sizes.md,
  } as ViewStyle,
});

export const ColumnListItemMenu: FunctionComponent<Props> =
  function ColumnListItemMenu(props: PropsWithChildren<Props>): JSX.Element {
    const [chromeContext] = useChromeContext();
    const { isSharePlusItemSelected } = useFeaturesActions();

    const { data } = props;

    return data?.type !== ComponentTargetTypes.COLLECTION ? (
      <View style={styles.container}>
        {chromeContext.sharePlusSelect && (
          <Icon
            icon={'paper-plane'}
            size={30}
            color={colors.white}
            style={styles.itemIcon}
            library={
              isSharePlusItemSelected(data)
                ? FontAwesomeLibraries.FAS
                : FontAwesomeLibraries.FAL
            }
          />
        )}
      </View>
    ) : (
      <></>
    );
  };

export default ColumnListItemMenu;
