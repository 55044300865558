/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  Dimensions,
  FlatList,
  GestureResponderEvent,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';

import { RenderKeyHelper } from '../../../../../helpers/render-key.helper';
import { Styles } from '../../../../../models/styles.model';
import { sizes } from '../../../../../themes/sizes';

import { ColumnListItem as ColumnListItemI } from '../../../../../models/column-list.model';

import ColumnListEmpty from './ColumnListEmpty';
import ColumnListItem from './ColumnListItem';

interface Props {
  data?: Array<ColumnListItemI>;
  onPressItem?: (
    event: GestureResponderEvent,
    itemData?: ColumnListItemI,
  ) => void | undefined;
}

const renderKeyHelper: RenderKeyHelper = new RenderKeyHelper('Cv1ColumnList');

const { width } = Dimensions.get('window');

const styles: Styles = StyleSheet.create({
  column: {
    marginRight: sizes.xs,
    width: width * 0.28,
  } as ViewStyle,
  columnList: {
    flexGrow: 0,
  } as ViewStyle,
  columnListContent: {
    backgroundColor: 'rgba(77, 77, 77, 0.95)',
    borderBottomLeftRadius: sizes.sm,
    borderBottomRightRadius: sizes.sm,
    justifyContent: 'flex-start',
    marginRight: sizes.xs,
    paddingBottom: sizes.md,
    width: '100%',
  } as ViewStyle,
  listFooter: {
    height: 200,
  } as ViewStyle,
});

export const ColumnList: FunctionComponent<Props> = function ColumnList(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { data, onPressItem } = props;

  /*
   * Hold:
   *
   * const windowHeight: number = Dimensions.get('window').height;
   * const [shouldBounce, setShouldBounce] = useState(false);
   * const scrollPositionRef: MutableRefObject<number> = useRef(0);
   *
   * function onScroll(event: NativeSyntheticEvent<NativeScrollEvent>): void {
   *   const scrollPosition: number =
   *     event &&
   *     event.nativeEvent &&
   *     event.nativeEvent.contentOffset &&
   *     event.nativeEvent.contentOffset.y;
   *   let shouldBounceNew: boolean;
   *
   *   // console.log(scrollPosition);
   *   // console.log(windowHeight);
   *
   *   // console.log(`${scrollPositionRef.current} : ${scrollPosition}`);
   *
   *   if (scrollPosition > windowHeight / 6) {
   *     shouldBounceNew = true;
   *   } else {
   *     shouldBounceNew = false;
   *   }
   *
   *   console.log(shouldBounceNew);
   *
   *   scrollPositionRef.current = scrollPosition;
   *
   *   if (shouldBounceNew === shouldBounce) {
   *     return;
   *   }
   *
   *   setShouldBounce(shouldBounceNew);
   * }
   */

  return (
    <View style={styles.column}>
      <FlatList
        // Hold: bounces={shouldBounce}
        contentContainerStyle={styles.columnListContent}
        contentInset={{
          bottom: 160,
          left: 0,
          right: 0,
          top: 0,
        }}
        data={data}
        keyExtractor={(item: ColumnListItemI, index: number): string =>
          `${item.id}-${index}`
        }
        // OnScroll={onScroll}
        ListEmptyComponent={<ColumnListEmpty />}
        renderItem={({ item }: { item: ColumnListItemI }): JSX.Element => (
          <ColumnListItem
            data={item}
            touchableProps={{
              onPress: (event: GestureResponderEvent): void => {
                if (typeof onPressItem === 'function') {
                  onPressItem(event, item);
                }
              },
            }}
          />
        )}
        showsVerticalScrollIndicator={false}
        style={styles.columnList}
      />
    </View>
  );
};

export default ColumnList;
