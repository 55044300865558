import React, { useMemo, useEffect, useState } from 'react';
import { ImageProps, View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';

import StorySlabLogo from '../../assets/logos/storyslab-logo-text-h.svg';
import ButtonRounded from '../../components/buttons/Rounded';
import CardContainer from '../../components/cards/CardContainer';
import HeaderFooterCard from '../../components/cards/HeaderFooterCard';
import { LabeledTextInput } from '../../components/forms/LabeledTextInput';
import { Row } from '../../components/grid';
import LogoHeader from '../../components/headers/LogoHeader';
import { BackgroundImage } from '../../components/images/BackgroundImage';
import { ErrorText } from '../../components/typography';
import { useDomain } from '../../context/domain/domain.context';
import { getBackgroundImage } from '../../helpers/background-images.helper';
import { createDynamicStyleSheet } from '../../helpers/styles.helper';
import { DomainStackParamList } from '../../models/domain.model';
import { Styles } from '../../models/styles.model';
import { fetchPublicDetails } from '../../services/bootstrap.service';
import { getPublicUrlFromS3Key } from '../../services/s3.service';
import { colors, sizes } from '../../themes';
import { getAppCodeFromSubDomain, getAppStateFromUrlParameters } from '../../helpers/url.helper'

import { Errors } from '../../enums/errors.enum';
import { DomainNavigationEnum } from '../../enums/navigation.enum';
import { mapPublicDetailsToWorkspace } from '../../maps/public-details.maps';

interface Props
  extends StackScreenProps<
    DomainStackParamList,
    DomainNavigationEnum.LoadingScreen
  > {
  handleError: (error: string) => void;
}

const styles: Styles = createDynamicStyleSheet(
  {
    backgroundImage: {
      flex: 1,
      height: '100%',
      position: 'absolute',
      resizeMode: 'cover',
      width: '100%',
    },
    buttonRow: {
      justifyContent: 'flex-end',
      marginTop: sizes.md,
    },
    container: {
      alignItems: 'center',
      margin: sizes.md,
    },
    inputRow: {
      marginTop: '20%',
    },
    logoImage: {
      aspectRatio: 580 / 117,
      width: '50%',
    },
  },
  {
    phone: {
      container: {
        marginTop: '5%',
      },
    },
    tablet: {
      container: {
        marginTop: '10%',
      },
    },
  },
);

const defaultValue: string = '';

export const AppCodeScreen: React.FunctionComponent<Props> =
  function AppCodeScreen(props: Props): JSX.Element {
    const [, setDomainContext] = useDomain();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [appCode, setAppCode] = useState<string | null>(defaultValue);
    const backgroundSource: ImageProps = useMemo(
      () => getBackgroundImage(),
      [],
    );

    function handleFetchPublicDetails(appCode: string | null): void {
      if (appCode) {
        setIsLoading(true);
        fetchPublicDetails(appCode.toLowerCase())
          .then(mapPublicDetailsToWorkspace) // <-- Please make any mapping changes here!
          .then((response: any) => {
            if (response.applicationId) {
              setDomainContext({
                applicationId: response.applicationId,
                tenantId: response.tenantId,
              });

              props.navigation.replace(
                DomainNavigationEnum.WorkspaceNavigator,
                {
                  ...response, // <-- See note above...
                  appCode: appCode,
                },
              );
            } else {
              throw response;
            }
          })
          .catch((err: Error) => {
            console.log(err);
            setError(Errors.APP_CODE_DOES_NOT_EXIST);
          })
          .finally(() => setIsLoading(false));
      }
    }

    // Check to see if we have our appCode set as part of the URI if we are web
    useEffect(() => {
      const stateParam = getAppStateFromUrlParameters();

      if(stateParam?.subdomain) {
        setAppCode(stateParam.subdomain);
        handleFetchPublicDetails(stateParam.subdomain);
      }
    }, []);

    return (
      <>
        <BackgroundImage source={backgroundSource} />
        <CardContainer>
          <HeaderFooterCard
            header={
              <LogoHeader logo={<View style={styles.logoImage}><StorySlabLogo></StorySlabLogo></View>} />
            }
          >
            <Row style={styles.inputRow}>
              <LabeledTextInput
                label={'Enter App Code'}
                onChangeText={(text: string): void => setAppCode(text)}
                value={appCode || ''}
              />
            </Row>
            <Row>
              <ErrorText error={error} />
            </Row>
            <Row style={styles.buttonRow}>
              <ButtonRounded
                accessibilityLabel="Continue to the login page"
                isLoading={isLoading}
                onPress={(): void => {
                  handleFetchPublicDetails(appCode);
                }}
                title="Continue"
                titleColor={colors.buttonTextLight}
                backgroundColor={'#95D1C2'}
              />
            </Row>
          </HeaderFooterCard>
        </CardContainer>
        <View style={styles.container}>
          {/* <AppCodeCard
          defaultValue={}
          error={error}
          handleOnSubmit={(value: string | null): void =>
            handleFetchPublicDetails(value)
          }
          isLoading={isLoading}
        /> */}
        </View>
      </>
    );
  };

export default AppCodeScreen;
