import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  Dimensions,
  GestureResponderEvent,
  ImageStyle,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { RouteProp } from '@react-navigation/native';

import AssetImage from '../../../../components/images/AssetImage';
import { Styles } from '../../../../models/styles.model';
import { colors, generics, sizes } from '../../../../themes';

import CollectionViewHeader from '../../../../components/headers/CollectionViewHeader';
import { useColumnList } from '../../../../hooks/column-list.hook';
import { ColumnListItem } from '../../../../models/column-list.model';
import ColumnList from '../../components/lists/cv1/ColumnList';
import { TemplateNavigationEnum } from '../../enums/navigation.enum';
import { TemplateStackParamList } from '../../models/template.model';

interface Props {
  route: RouteProp<TemplateStackParamList, TemplateNavigationEnum>;
}

export const Cv1Layout: FunctionComponent<Props> = function LayoutCv1(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { route } = props;
  const { width } = Dimensions.get('window');

  const {
    collection,
    collectionStack,
    columnData,
    handleRowTap,
    scrollViewRef,
  } = useColumnList(route?.params?.id);

  return (
    <View style={styles.container}>
      <View style={styles.backgroundArea}>
        {collection?.backgroundAssetId && (
          <AssetImage
            assetId={collection?.backgroundAssetId}
            style={styles.backgroundImage}
            resizeMode={'cover'}
          />
        )}
      </View>

      <CollectionViewHeader
        collectionStack={collectionStack}
        showMenuItems={true}
      />

      <ScrollView
        contentContainerStyle={styles.contentContainer}
        decelerationRate={0}
        horizontal={true}
        snapToAlignment={'start'}
        snapToInterval={width * 0.86} //Your element width
        style={styles.horizontalScroll}
        onContentSizeChange={(): void => {
          if (scrollViewRef.current) {
            scrollViewRef.current.scrollToEnd({ animated: true });
          }
        }}
        ref={scrollViewRef}
      >
        {columnData?.map((columnList: any, index: number) => {
          return (
            <ColumnList
              data={columnList}
              key={index}
              onPressItem={(
                event: GestureResponderEvent,
                itemData: ColumnListItem | undefined,
              ): void => {
                handleRowTap(event, itemData, index);
              }}
            />
          );
        })}
      </ScrollView>
    </View>
  );
};

const styles: Styles = StyleSheet.create({
  backgroundArea: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: generics.layoutHeader,
  } as ViewStyle,
  backgroundImage: {
    height: '100%',
    position: 'absolute',
    resizeMode: 'cover',
    width: '100%',
  } as ViewStyle,
  container: {
    backgroundColor: colors.background,
    flex: 1,
    flexDirection: 'column',
  } as ViewStyle,
  contentContainer: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  } as ViewStyle,
  heroImage: {
    bottom: sizes.sm * 2 + 150,
    height: 500,
    position: 'absolute',
    right: sizes.sm,
    width: 500,
  } as ViewStyle,
  horizontalScroll: {
    flex: 1,
  } as ViewStyle,
  logo: {
    bottom: sizes.sm,
    height: 180,
    position: 'absolute',
    right: sizes.sm,
    width: 500,
  } as ImageStyle,
});

export default Cv1Layout;
