import React from 'react';

import TemplateNavigation from './navigation/Template';

export const TemplateRootScreen: React.FunctionComponent<
  Record<string, unknown>
> = function TemplateRootScreen(): JSX.Element {
  return <TemplateNavigation />;
};

export default TemplateRootScreen;