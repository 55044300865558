/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';

import BadgeIcon from '../../components/icons/BadgeIcon';
import OptionsMenu from '../../components/menus/options/OptionsMenu';
import { useChromeActions } from '../../context/workspace/chrome.context';
import {
  useFeaturesActions,
  useFeaturesContext,
} from '../../context/workspace/features.context';
import { useWorkspace } from '../../context/workspace/workspace.context';
import { FontAwesomeLibraries } from '../../enums/font-awesome.enum';
import { Styles } from '../../models/styles.model';
import { colors, sizes } from '../../themes';

import IconButton from './Icon';

interface Props {
  params: any;
}

const iconSize: number = 28;

export const SharePlusHeaderButton: FunctionComponent<Props> =
  function SharePlusHeaderButton(props: PropsWithChildren<Props>): JSX.Element {
    const [workspace] = useWorkspace();
    const [featuresContext] = useFeaturesContext();

    const { isSharePlusItemSelected, removeSharePlusItem, addSharePlusItem } =
      useFeaturesActions();

    const { handleSharePlusTap, showMainChromeDrawer, sharePlus } =
      useChromeActions();

    const { params } = props;

    if (isSharePlusItemSelected(params)) {
      return (
        <OptionsMenu
          trigger={
            <BadgeIcon
              color={workspace.styles?.primaryColor}
              icon="paper-plane"
              size={iconSize}
              count={featuresContext.sharePlus?.bundleItemsCount}
              library={FontAwesomeLibraries.FAS}
            />
          }
          optionProps={{
            iconColor: colors.gray,
            iconSize: iconSize,
            textStyle: [styles.baseText, styles.buttonTitle],
          }}
          options={[
            {
              icon: 'trash-alt',
              onSelect: (): void => {
                removeSharePlusItem(params);
              },
              title: 'Remove from Share Bundle',
            },
            {
              icon: 'paper-plane',
              onSelect: (): void => {
                handleSharePlusTap();
                showMainChromeDrawer();
                sharePlus.handleSharePlusCurrentTap();
              },
              title: 'Go To share Panel',
            },
          ]}
        />
      );
    } else {
      return (
        <TouchableOpacity
          onPress={(): void => {
            addSharePlusItem(params);
          }}
        >
          <BadgeIcon
            color={colors.gray}
            icon="paper-plane"
            count={featuresContext.sharePlus?.bundleItemsCount}
            size={iconSize}
            library={FontAwesomeLibraries.FAL}
          />
        </TouchableOpacity>
      );
    }
  };

const styles: Styles = StyleSheet.create({
  baseText: { color: colors.gray } as TextStyle,
  buttonTitle: {
    marginLeft: sizes.sm,
  } as TextStyle,
  container: {
    flexDirection: 'row',
  } as ViewStyle,
  rightIcon: {
    marginLeft: 20,
    marginRight: 20,
  } as FontAwesomeIconStyle,
});

export default SharePlusHeaderButton;
