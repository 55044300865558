import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity} from 'react-native';
import Image from '../../components/images/Image'
import { SvgUri } from 'react-native-svg';

import { Styles } from '../../models/styles.model';
import { fontFamily, sizes } from '../../themes';

interface Props {
  iconUrl?: string;
  iconKey?: string;
  isFirst?: boolean;
  primaryColor: string;
  onPress?: () => void;
  title: string;
}

const styles: Styles = StyleSheet.create({
  buttonText: {
    color: 'white',
    fontFamily: fontFamily.bold,
    fontSize: sizes.sm18,
    padding: sizes.xs,
  },
  container: {
    borderRadius: 6,
    borderWidth: 1,
    flexDirection: 'row',
  },
  fastImageIcon: {
    height: 40,
    width: 40,
  },
  icon: {
    padding: sizes.sm,
  },
  textContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

function renderIconBasedOnUrl(url: string): JSX.Element {
  if (url.indexOf('.svg') !== -1) {
    return (
      <SvgUri
        width={40}
        height={40}
        uri={url}
        preserveAspectRatio="xMinYMin slice"
      />
    );
  } else {
    return (
      <Image
        style={styles.fastImageIcon}
        resizeMode='center'
        source={{
          uri: url,
        }}
      />
    );
  }
}

export const SplitIconButton: React.FunctionComponent<Props> =
  function SplitIconButton(props: React.PropsWithChildren<Props>): JSX.Element {
    return (
      <TouchableOpacity
        style={[
          styles.container,
          { borderColor: props.primaryColor },
          !props.isFirst ? { marginTop: sizes.md } : {},
        ]}
        onPress={props.onPress}
      >
        {props.iconUrl && (
          <View style={styles.icon}>{renderIconBasedOnUrl(props.iconUrl)}</View>
        )}
        <View
          style={[
            styles.textContainer,
            { backgroundColor: props.primaryColor },
          ]}
        >
          <Text style={styles.buttonText}>{props.title}</Text>
        </View>
      </TouchableOpacity>
    );
  };

export default SplitIconButton;
