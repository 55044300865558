import React, { useEffect, useState } from 'react';
import {
  Platform,
  StyleSheet,
  TextStyle,
  UIManager,
  View,
  ViewStyle,
  TouchableOpacity
} from 'react-native';
// TODO: Make progress bar work
// import ProgressBar from 'react-native-progress/Bar';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';
import { format } from 'date-fns';

import Icon from '../../components/icons/Icon';
import { H5, H6 } from '../../components/typography';
import { useChromeContext } from '../../context/workspace/chrome.context';
import { useWorkspace } from '../../context/workspace/workspace.context';
// TODO: Fix progress bar
// import { progressAsFraction, progressAsString } from '../../helpers/progress.helper';
import { Styles } from '../../models/styles.model';

// TODO: For the web we could create a sync progress that's just a dummy
// import { useSyncProgress } from '../../sync/states/sync.state';
import { colors, fontFamily } from '../../themes/index';
import { sizes } from '../../themes/sizes';

import { WorkspaceSyncState } from '../../enums/sync.enum';

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

interface Props {
  applicationId: number;
  displayName: string;
  onPressLogo?: () => void;
}

const styles: Styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.background,
    borderRadius: sizes.xs,
    elevation: 6,
    flexDirection: 'row',
    height: 68,
    justifyContent: 'space-between',
    left: -sizes.xs,
    marginTop: 24,
    padding: sizes.sm,
    paddingLeft: sizes.xs + sizes.sm,
    paddingRight: sizes.xxs,
    shadowColor: '#000',
    shadowOffset: {
      height: 3,
      width: 0,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    width: '118%',
  } as ViewStyle,
  header: {
    color: colors.textDark,
    fontFamily: fontFamily.bold,
  } as TextStyle,
  headerContainer: {
    marginLeft: sizes.md,
  } as ViewStyle,
  hidden: {
    opacity: 0,
  } as ViewStyle,
  leftColumn: {
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  menuIcon: {
    color: colors.textLight,
  } as FontAwesomeIconStyle,
  progressBarWrapper: {
    bottom: 0,
    height: 6,
    left: 0,
    opacity: 1,
    position: 'absolute',
    right: 0,
  } as ViewStyle,
});

export const WorkspaceIndicator: React.FunctionComponent<Props> =
  function WorkspaceIndicator(
    props: React.PropsWithChildren<Props>,
  ): JSX.Element {
    const { applicationId, displayName } = props;
    // const [progressState] = useSyncProgress(applicationId);
    const [chromeContext] = useChromeContext();
    const [workspace] = useWorkspace();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      setIsLoading(
        !!(
          workspace.syncState &&
          workspace.syncState > WorkspaceSyncState.NONE &&
          workspace.syncState < WorkspaceSyncState.COMPLETE
        ),
      );
    }, [workspace.syncState]);

    return (
      <View style={styles.container}>
        <View
          style={[styles.progressBarWrapper, !isLoading ? styles.hidden : null]}
        >
          {/* TODO: Make progress bar work */}
          {/* {progressState && (
            <ProgressBar
              style={styles.progressBar}
              borderWidth={0}
              color={workspace?.styles?.primaryColor}
              unfilledColor={workspace?.styles?.primaryColorLighter}
              progress={progressAsFraction(progressState as any)}
              width={null}
            />
          )} */}
        </View>

        <View style={styles.leftColumn}>
          <View style={styles.headerContainer}>
            <H5 style={styles.header}>{displayName}</H5>
            <H6>
              {/* {isLoading
                ? progressAsString(progressState, true)
                : workspace.lastSyncTimestamp
                ? `last updated ${format(
                    workspace.lastSyncTimestamp,
                    'MM/dd/yyyy h:mmaaa',
                  )}`
                : ''} */}
            </H6>
          </View>
        </View>

        <TouchableOpacity
          onPress={(): void => {
            // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            if (props.onPressLogo) {
              props.onPressLogo();
            }
          }}
        >
          <Icon
            icon={
              chromeContext.isMainChromeDrawerExpanded
                ? 'angle-left'
                : 'angle-right'
            }
            size={50}
            style={styles.menuIcon}
            transform="right-2"
          />
        </TouchableOpacity>
      </View>
    );
  };

export default WorkspaceIndicator;
