// This component is meant to handle the difference between the native and web version of loading an Image

import React, { useState } from 'react';
import { Image as ReactImage, ImageProps, StyleSheet } from 'react-native';

export const Image: React.FunctionComponent<ImageProps> = function Image(
  props: React.PropsWithChildren<ImageProps>,
): JSX.Element {
  return (
    <ReactImage {...props} />
  );
};

export default Image;
