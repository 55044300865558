import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';
import { useNavigation } from '@react-navigation/core';

import Icon from '../../components/icons/Icon';
import { H2 } from '../../components/typography';
import {
  useChromeActions,
  useChromeContext,
} from '../../context/workspace/chrome.context';
import { useWorkspace } from '../../context/workspace/workspace.context';
import { Styles } from '../../models/styles.model';
import { generics, sizes } from '../../themes/sizes';

import { TemplateNavigationProp } from '../../templates/default/models/template.model';
import { colors } from '../../themes';

import CollectionViewHeaderRightColumn from './CollectionViewHeaderRightColumn';
import PageHeader from './PageHeader';

interface Props {
  collectionStack: Array<string> | null;
  showMenuItems?: boolean;
}

function buildTitle(
  collectionStack: Array<string> | undefined,
  shouldUseEllipsis?: boolean,
): string {
  if (collectionStack && Array.isArray(collectionStack)) {
    if (shouldUseEllipsis) {
      return `${collectionStack[0]} ... ${
        collectionStack[collectionStack.length - 1]
      }`;
    } else {
      return collectionStack.join(' > ');
    }
  }

  return '';
}

export const CollectionViewHeader: FunctionComponent<Props> =
  function CollectionViewHeader(props: PropsWithChildren<Props>): JSX.Element {
    const navigation: TemplateNavigationProp = useNavigation();
    const [workspace] = useWorkspace();
    const [title, setTitle] = useState('');

    // Hold const [shouldUseEllipsis, setShouldUseEllipsis] = useState(false);
    const [shouldUseEllipsis] = useState(false);
    // Hold: const breadcrumbsWrapperWidth: React.MutableRefObject<number> = useRef(0);

    const { toggleSharePlusSelect } = useChromeActions();

    useEffect(() => {
      if (props.collectionStack) {
        setTitle(buildTitle(props.collectionStack, shouldUseEllipsis));
      }
    }, [props.collectionStack, shouldUseEllipsis]);

    return (
      <>
        <PageHeader
          leftColumn={
            <>
              <TouchableOpacity
                onPress={(): void => {
                  navigation.goBack();
                  // Navigation.navigate({name: });
                }}
              >
                <Icon
                  icon="chevron-left"
                  size={32}
                  style={[
                    styles.homeIcon,
                    { color: workspace.styles?.primaryColor },
                  ]}
                />
              </TouchableOpacity>
            </>
          }
          centerColumn={
            <View style={styles.breadcrumbsWrapper}>
              <H2
                style={[styles.breadcrumbs]}
                numberOfLines={1}
                ellipsizeMode={'tail'}
                /*
                 * Hold for later when we build custom ellipsis.
                 * onTextLayout={(
                 *   e: NativeSyntheticEvent<TextLayoutEventData>,
                 * ): void => {
                 *   setShouldUseEllipsis(!!e.nativeEvent?.lines[1]);
                 * }}
                 */
              >
                {title}
              </H2>
            </View>
          }
          rightColumn={
            <>{props.showMenuItems && <CollectionViewHeaderRightColumn />}</>
          }
        />
      </>
    );
  };

const styles: Styles = StyleSheet.create({
  breadcrumbsWrapper: {
    alignItems: 'flex-start',
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: sizes.md,
  } as TextStyle,
  container: {
    alignItems: 'center',
    backgroundColor: colors.background,
    elevation: 12,
    flexDirection: 'row',
    height: generics.layoutHeader,
    justifyContent: 'space-between',
    left: -12,
    marginHorizontal: sizes.sm,
    paddingHorizontal: sizes.sm,
    shadowColor: '#000',
    shadowOffset: {
      height: 6,
      width: 0,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,
    width: '100%',
  } as ViewStyle,
  dropdownMenu: {
    right: 20,
    top: 55,
  } as ViewStyle,
  homeIcon: {} as FontAwesomeIconStyle,
});

export default CollectionViewHeader;
