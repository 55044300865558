import { WorkspaceContextProps } from 'models/workspace.model';
import { ContentFilesItem } from 'models/data';

export async function getContentFileById({
    id,
  }: {
    id: number;
  }): Promise<ContentFilesItem | null> {

    let contentFilesItemByIdJSON = window.sessionStorage.getItem('contentFilesItemById')

    if(contentFilesItemByIdJSON) {

      let contentFilesItemById = JSON.parse(contentFilesItemByIdJSON);

      return contentFilesItemById[id] || null;
    }

    return null;
  }