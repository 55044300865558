
import URI from 'urijs';
import { env } from './env';

export function getUrlParameter(name: string): string {
  const uri: URI = new URI();
  return uri.search(true)[name] || null;
}

export function getAuthUrl(
  applicationId: number,
  provider: string,
  subdomain: string,
  tenantId: number,
): string {
  const uri: URI = new URI(
    `${env.REACT_APP_API_GATEWAY_AUTH}/authorize`,
  );

  const currentUri: URI = new URI();

  let queryParams: {
    identity_provider: string;
    tenant_id: number;
    redirect_uri: string;
    state: string;
  } = {
    identity_provider: provider,
    tenant_id: tenantId,
    redirect_uri: currentUri.query({}).toString(),
    state: JSON.stringify({ applicationId:applicationId, provider:provider, subdomain:subdomain, tenantId:tenantId })
  };

  return uri.addQuery(queryParams).toString();
}

export function replaceRedirectUrl(url: string, redirectUrl: string): string {
  return url.replace('{{redirect_url}}', encodeURIComponent(redirectUrl));
}

export function getAppCodeFromSubDomain(): string | null {
  const uri: URI = new URI();
  const subDomainSplit = uri.subdomain().split('.');

  if(subDomainSplit.length > 1) {
    return subDomainSplit[0];
  }

  return null;
}

export function getAppStateFromUrlParameters(): any {

  const stateJSON = getUrlParameter('state');
  let state = null;

  if(stateJSON) {
    try {
      state = JSON.parse(stateJSON);
    }
    // If we get a JSON parse error
    catch(e) {
      console.log(e);
      return null;
    }
  }

  return state;
}

export function clearUrlParameters() : void {
  window.history.replaceState({}, document.title, "/");
}