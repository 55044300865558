import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAppStateFromUrlParameters } from '../../helpers/url.helper'

interface DomainContextProps {
  initialized?: boolean;
  applicationId: number | null;
  tenantId: number | null;
}

export type DomainContextI = [
  Partial<DomainContextProps>,
  (domain: DomainContextProps) => void,
];

const defaultObject: DomainContextProps = {
  applicationId: null,
  tenantId: null,
};

const DomainContext: React.Context<DomainContextI> = createContext([] as any);

export function useDomain(): DomainContextI {
  const context: DomainContextI = useContext(DomainContext);

  if (!context) {
    throw new Error('useDomain must be used within a Global Provider');
  }
  return context;
}

function DomainProvider({
  children,
}: PropsWithChildren<React.ReactNode>): JSX.Element | null {
  const [domainContext, setDomainContext] =
    useState<DomainContextProps>(defaultObject);
  const value: DomainContextI = useMemo(
    () => [
      domainContext,
      (state: DomainContextProps): void => {
        setDomainContext({
          ...domainContext,
          ...state,
        });
      },
    ],
    [domainContext],
  );

  useEffect(() => {

    const state = getAppStateFromUrlParameters();

    if(state) {
      setDomainContext({
        initialized: true,
        applicationId: state.applicationId || null,
        tenantId: state.tenantId || null
      });
    }

  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const domainContextAsString: string | null = await AsyncStorage.getItem(
          '@Domain',
        );

        let initialDomainContext: DomainContextProps = {
          ...defaultObject,
          initialized: true,
        };

        if (domainContextAsString) {
          initialDomainContext = {
            ...initialDomainContext,
            ...JSON.parse(domainContextAsString),
          };
        }

        setDomainContext(initialDomainContext);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        await AsyncStorage.setItem('@Domain', JSON.stringify(domainContext));
      } catch (err) {
        console.log(err);
      }
    })();
  }, [domainContext]);

  return domainContext.initialized ? (
    <DomainContext.Provider value={value}>{children}</DomainContext.Provider>
  ) : null;
}

export default DomainProvider;
