import { AssetsItem } from 'models/data';

export async function getAssetById({
    id,
  }: {
    id: number;
  }): Promise<AssetsItem | null> {

    let assetsByIdJSON = window.sessionStorage.getItem('assetsById');

    if(assetsByIdJSON) {

      let assetsById = JSON.parse(assetsByIdJSON);

      return assetsById[id] || null;
    }

    return null;
  }