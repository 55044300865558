import { palette } from './palette';

type Palette = typeof palette;

export interface Colors {
  background: Palette['white'];
  backgroundGray: Palette['lightestGray'];
  buttonTextLight: Palette['white'];
  dropShadowBaseColor: Palette['black'];
  gray: Palette['gray'];
  line: Palette['lighterGrey'];
  primary: Palette['orange'];
  text: Palette['textGrey'];
  textDark: Palette['darkGray'];
  textLight: Palette['lighterGrey'];
  textWhite: Palette['white'];
  white: Palette['white'];
  darkGray: Palette['darkGray'];

  secondary: Palette['secondary'];
  success: Palette['success'];
  info: Palette['info'];
  danger: Palette['danger'];
  light: Palette['light'];
  dark: Palette['dark'];
  default: Palette['default'];
  darker: Palette['darker'];
}

export const colors: Colors = {
  background: palette.white,
  backgroundGray: palette.lightestGray,
  buttonTextLight: palette.white,
  danger: palette.danger,
  dark: palette.dark,
  darker: palette.darker,
  // eslint-disable-next-line sort-keys
  darkGray: palette.darkGray,
  default: palette.default,
  dropShadowBaseColor: palette.black,
  gray: palette.gray,
  info: palette.info,
  light: palette.light,
  line: palette.lighterGrey,
  primary: palette.orange,
  secondary: palette.secondary,
  success: palette.success,
  text: palette.textGrey,
  textDark: palette.darkGray,
  textLight: palette.lighterGrey,
  textWhite: palette.white,
  white: palette.white,
};
