export enum ApiRequestMethods {
  DELETE = 'delete',
  GET = 'get',
  POST = 'post',
  PUT = 'put',
}

export interface FetchHeaders {
  [key: string]: string;
}

export interface AuthPayload {
  applicationId: number;
  idToken: string;
  accessToken: string;
  userDeviceId: number;
  tenantId: number;
}

export interface ApiRequestParams {
  auth: AuthPayload;
  body?: any;
  headers?: FetchHeaders;
  method: ApiRequestMethods;
  url: string;
  includeMeta?: boolean;
}
