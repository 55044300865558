/* eslint-disable max-lines-per-function */
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  FlatList,
  LayoutAnimation,
  ListRenderItemInfo,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  UIManager,
  View,
  ViewStyle,
} from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';

import { Col, Row } from '../../components/grid';
import Icon from '../../components/icons/Icon';
import SearchTableItem from '../../components/tables/SearchTableItem';
import { H3, H6 } from '../../components/typography';
import { ComponentTargetTypes } from '../../enums/component.enum';
import { Styles } from '../../models/styles.model';
import { colors, fontFamily, sizes } from '../../themes';

import { universalSearch } from '../../accessors/search.accessor';
import { useComponentNavigation } from '../../hooks/component-navigation.hook';

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

interface Props {
  onRequestClose?: () => void;
}

export const SearchModal: FunctionComponent<Props> = function SearchModal(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { onRequestClose } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [shouldShowResults, setShouldShowResults] = useState(false);
  const [queryResults, setQueryResults] = useState([]);
  const [incrementSearch, setIncrementSearch] = useState(0);
  const navigation: NavigationProp<any> = useNavigation();
  const setNavigationItemId: (
    id: number,
    type: ComponentTargetTypes,
    useModal?: boolean,
  ) => void = useComponentNavigation();

  function wrappedOnRequestClose(): void {
    navigation.goBack();

    if (typeof onRequestClose === 'function') {
      onRequestClose();
    }
  }

  async function handleSearch(query: string): Promise<void> {
    if (query.length === 0) {
      return;
    }

    const [results, searchError] = await universalSearch(query);

    if (searchError) {
      console.log(searchError);
      return;
    }

    setQueryResults(results);
  }

  function handleItemPress(item: any): void {
    if (item?.id) {
      if (!item?.type) {
        item.type = 'collection';
      }
      setNavigationItemId(item.id, item.type as any, true);
    }
  }

  useEffect(() => {
    handleSearch(searchQuery);
  }, []);

  useEffect(() => {
    handleSearch(searchQuery);
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setShouldShowResults(true);
  }, [incrementSearch]);

  useEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setShouldShowResults(false);
  }, [searchQuery]);

  return (
    <>
      <Pressable style={styles.modalOverlay} onPress={wrappedOnRequestClose} />
      <View style={styles.searchBox}>
        <Icon icon={'search'} size={sizes.lg} />
        <TextInput
          numberOfLines={1}
          placeholder={'Search...'}
          placeholderTextColor={colors.light}
          onChangeText={(text: string): void => setSearchQuery(text)}
          value={searchQuery}
          style={styles.textInput}
          editable
          onEndEditing={(): void => {
            setIncrementSearch((prevValue: number) => {
              return prevValue + 1;
            });
          }}
        />
      </View>
      {shouldShowResults ? (
        <View style={styles.searchResultsBox}>
          {queryResults.length > 0 ? (
            <>
              <Row style={styles.searchResultsBoxHeader}>
                <Col>
                  <H3>
                    {queryResults.length} Results for {searchQuery}
                  </H3>
                </Col>
              </Row>

              <Row style={styles.searchResultsTableHeader}>
                <Col style={styles.searchResultsTableHeaderColumnLeft}>
                  <H6>Subject</H6>
                </Col>
                <Col style={styles.searchResultsTableHeaderColumnRight}>
                  <H6>Added on</H6>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}

          <View style={styles.tableWrapper}>
            <FlatList
              data={queryResults}
              ListEmptyComponent={
                <View style={styles.emptyContainer}>
                  <Text style={styles.emptyText}>
                    Sorry, it looks like we couldn't find "{searchQuery}"
                  </Text>
                </View>
              }
              contentInset={{
                bottom: 20,
                left: 0,
                right: 0,
                top: 0,
              }}
              // OnDragEnd={({ data }) => setData(data)}
              keyExtractor={(item: any): string => String(item?.id)}
              renderItem={(info: ListRenderItemInfo<any>): JSX.Element => {
                return (
                  <SearchTableItem
                    {...info}
                    handleOnPress={(item: any): void => {
                      handleItemPress(item);
                    }}
                  />
                );
              }}
              style={styles.table}
            />
          </View>
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

const styles: Styles = StyleSheet.create({
  emptyContainer: {
    marginTop: sizes.md,
    paddingVertical: '5%',
  } as ViewStyle,
  emptyText: {
    fontSize: 15,
    textAlign: 'center',
  } as TextStyle,
  modalOverlay: {
    backgroundColor: 'rgba(255,255,255, 0.8)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
  searchBox: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    elevation: 5,
    flexDirection: 'row',
    margin: 20,
    marginTop: sizes.lg,
    padding: sizes.md,
    shadowColor: '#000',
    shadowOffset: {
      height: 2,
      width: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    width: '80%',
  } as ViewStyle,
  searchResultsBox: {
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    elevation: 5,
    flex: 1,
    flexDirection: 'column',
    margin: 20,
    marginBottom: -20,
    marginTop: sizes.tny,
    padding: sizes.md,
    shadowColor: '#000',
    shadowOffset: {
      height: 2,
      width: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    width: '80%',
  } as ViewStyle,
  searchResultsBoxHeader: {} as ViewStyle,
  searchResultsTableHeader: {
    marginBottom: sizes.xs,
    marginTop: sizes.md,
  } as ViewStyle,
  searchResultsTableHeaderColumnLeft: {
    marginLeft: sizes.md,
  } as ViewStyle,
  searchResultsTableHeaderColumnRight: {
    marginLeft: '55%',
  } as ViewStyle,
  table: {} as ViewStyle,
  tableWrapper: {
    flex: 1,
    height: '100%',
  } as ViewStyle,
  textInput: {
    fontFamily: fontFamily.light,
    fontSize: sizes.lg,
    marginLeft: sizes.md,
    padding: sizes.xs,
    width: '100%',
  } as TextStyle,
  textInputWrapper: {
    // PaddingVertical: sizes.sm,
  } as ViewStyle,
});

export default SearchModal;
