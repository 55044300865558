/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { Styles } from '../../models/styles.model';
import { colors, generics, sizes } from '../../themes';

interface Props {
  leftColumn?: ReactNode;
  centerColumn?: ReactNode;
  rightColumn?: ReactNode;
}

const styles: Styles = StyleSheet.create({
  centerColumn: { flex: 1 } as ViewStyle,
  container: {
    alignItems: 'center',
    backgroundColor: colors.background,
    elevation: 12,
    flexDirection: 'row',
    height: generics.layoutHeader,
    justifyContent: 'space-between',
    left: -12,
    marginHorizontal: sizes.sm,
    paddingHorizontal: sizes.sm,
    shadowColor: '#000',
    shadowOffset: {
      height: 6,
      width: 0,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,
    width: '100%',
  } as ViewStyle,
  leftColumn: { flexDirection: 'row' } as ViewStyle,
  rightColumn: { flexDirection: 'row' } as ViewStyle,
});

export const PageHeader: FunctionComponent<Props> = function PageHeader(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { leftColumn, centerColumn, rightColumn } = props;

  return (
    <View style={styles.container}>
      <View style={styles.leftColumn}>{leftColumn}</View>
      <View style={styles.centerColumn}>{centerColumn}</View>
      <View style={styles.rightColumn}>{rightColumn}</View>
    </View>
  );
};

export default PageHeader;
