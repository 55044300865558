import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import { Styles } from 'models/styles.model';

type Props = ViewProps;

const styles: Styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export const Col: React.FunctionComponent<Props> = function Col(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  return <View style={[styles.container, props.style]}>{props.children}</View>;
};

export default Col;
