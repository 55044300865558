import React from 'react';
import { StyleSheet, Text } from 'react-native';

import { Styles } from '../../models/styles.model';
import { sizes } from '../../themes';

interface Props {
  error: string | null;
}

const styles: Styles = StyleSheet.create({
  error: {
    color: 'red',
    fontSize: sizes.sm14,
    marginTop: sizes.sm14,
  },
});

export const ErrorText: React.FunctionComponent<Props> = function ErrorText(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  return props.error ? <Text style={styles.error}>{props.error}</Text> : <></>;
};

export default ErrorText;
