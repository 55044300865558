/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, ViewStyle } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import WorkspaceErrorAlert from '../../../components/alerts/WorkspaceErrorAlert';
import StorySlabDot from '../../../components/buttons/StorySlabDot';
import HeaderFooterCard from '../../../components/cards/HeaderFooterCard';
import FullScreenCardContainer from '../../../components/containers/FullScreenCardContainer';
import { Col, Row } from '../../../components/grid';
import LogoHeader from '../../../components/headers/LogoHeader';
import RemoteLogo from '../../../components/images/RemoteLogo';
import GenericLoginButton from '../../../components/login/GenericLoginButton';
import ProviderLoginButton from '../../../components/login/ProviderLoginButton';
import { useDomain } from '../../../context/domain/domain.context';
import {
  useWorkspace,
  WorkspaceContextI,
} from '../../../context/workspace/workspace.context';
import { AuthState } from '../../../enums/auth.enum';
import { RenderKeyHelper } from '../../../helpers/render-key.helper';
import { createDynamicStyleSheet } from '../../../helpers/styles.helper';
import { Styles } from '../../../models/styles.model';
import {
  IdentityProvider,
  WorkspaceNavigationProp,
} from '../../../models/workspace.model';
import { oauthIdpLogin } from '../../../services/auth.service';
import { sizes } from '../../../themes';

import { WorkspaceNavigationEnum } from '../../../enums/navigation.enum';

const renderKeyHelper: RenderKeyHelper = new RenderKeyHelper('AppCodeScreen');
const storyslabIdpName: string = 'StorySlabGSuite';

export const ChooseLoginScreen: React.FunctionComponent<null> =
  function ChooseLoginScreen(): JSX.Element {
    const workspaceContext: WorkspaceContextI = useWorkspace();
    const [workspace, setWorkspace] = workspaceContext;
    const navigation: WorkspaceNavigationProp = useNavigation();
    const [isLoading, setIsLoading] = useState(false);
    const [domain] = useDomain();

    const { identityProviders, settings } = workspace;

    useEffect(() => {
      /*
       * https://app.shortcut.com/storyslab/story/4053/login-flow-seems-to-skip-initial-sync-page-for-subsequent-logins
       * Always show sync screen on login flow.
       */
      /*
       * SetWorkspace({
       *   shouldShowSyncScreen: true,
       * });
       */
    }, []);

    async function handleLogin(provider: IdentityProvider): Promise<void> {
      if (workspace.applicationId && domain.tenantId) {
        setIsLoading(true);
        const [, err] = await oauthIdpLogin({
          applicationId: workspace.applicationId,
          provider,
          tenantId: domain.tenantId,
          workspaceContext,
        });

        if (!err) {
          // WorkspaceRouter({ navigation, workspace });

          setWorkspace({
            authState: AuthState.SYNC_REQUIRED,
          });
        } else {
          console.log(err);
        }

        setIsLoading(false);
      }
    }

    return (
      <>
        <FullScreenCardContainer
          backgroundUri={workspace?.assets?.backgroundUrl}
        >
          <HeaderFooterCard
            header={<LogoHeader title={'Login'} />}
            footer={
              workspace?.assets?.logoUrl ? (
                <RemoteLogo url={workspace.assets.logoUrl} />
              ) : null
            }
          >
            <Row>
              <Col style={styles.buttonColumn}>
                <GenericLoginButton
                  preferences={settings?.genericLogin}
                  onPress={(): void => {
                    navigation.navigate(
                      WorkspaceNavigationEnum.GenericLoginScreen,
                    );
                  }}
                />

                {identityProviders &&
                  identityProviders.map(
                    (provider: IdentityProvider): JSX.Element => (
                      <ProviderLoginButton
                        key={renderKeyHelper.generate(
                          provider,
                          'SplitIconButton',
                        )}
                        provider={provider}
                        onPress={(): void => {
                          handleLogin(provider);
                        }}
                      />
                    ),
                  )}
                {isLoading && (
                  <ActivityIndicator
                    size="large"
                    color="#333333"
                    style={styles.activityIndicator}
                  />
                )}
              </Col>
            </Row>
            {workspace.errorState && (
              <Row>
                <WorkspaceErrorAlert />
              </Row>
            )}
            <View style={styles.spacer} />
          </HeaderFooterCard>
        </FullScreenCardContainer>
        <StorySlabDot
          onLongPress={(): void => {
            handleLogin({
              cognitoName: storyslabIdpName,
            } as any);
          }}
        />
      </>
    );
  };

const styles: Styles = createDynamicStyleSheet(
  {
    activityIndicator: {
      marginTop: 20,
    },
    buttonColumn: {
      paddingHorizontal: sizes.lg,
      paddingTop: sizes.xxl,
    },
    spacer: { height: sizes.xxl } as ViewStyle,
  },
  {
    phone: {
      buttonColumn: {
        paddingHorizontal: 0,
        paddingTop: sizes.xl,
      },
      container: {
        marginTop: '10%',
      },
      spacer: { height: sizes.xl } as ViewStyle,
    },
    tablet: {
      container: {
        marginTop: '10%',
      },
    },
  },
);

export default ChooseLoginScreen;
