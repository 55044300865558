/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, useEffect, useMemo } from 'react';
import {
  LayoutAnimation,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  UIManager,
  View,
  ViewStyle,
} from 'react-native';

import { useChromeContext } from '../../context/workspace/chrome.context';
import { useFeaturesContext } from '../../context/workspace/features.context';
import { useWorkspace } from '../../context/workspace/workspace.context';
import { Styles } from '../../models/styles.model';
import { colors } from '../../themes';

import { ChromeInternalMenuTypes } from '../../enums/navigation.enum';

// TODO: Port shareplus to web
// import SharePlusCurrentInProgressDrawer from './share-plus/CurrentInProgress';
// import SharePlusSavedDraftsDrawer from './share-plus/SharePlusSavedDrafts';
// import SharePlusSuccess from './share-plus/SharePlusSuccess';
// import SharePlusSuccessOffline from './share-plus/SharePlusSuccessOffline';

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

const styles: Styles = StyleSheet.create({
  scrollView: {} as ViewStyle,
  subMenuPanel: {
    backgroundColor: colors.backgroundGray,
    height: '100%',
    position: 'absolute',
    width: '65%',
    zIndex: 9,
  } as ViewStyle,
  subMenuPanelCollapsed: {
    left: '-65%',
  } as ViewStyle,
  subMenuPanelCollapsedReady: {
    left: '-40%',
  } as ViewStyle,

  subMenuPanelExpanded: {
    left: '25%',

    shadowColor: '#000',
    shadowOffset: {
      height: 4,
      width: 3,
    },
    shadowOpacity: 0.37,
    shadowRadius: 4.65,
  } as ViewStyle,
});

export const ChromeMenuSubPanel: FunctionComponent<Record<string, unknown>> =
  function ChromeMenuSubPanel(): JSX.Element {
    const [workspace] = useWorkspace();
    const [chromeContext] = useChromeContext();
    const [featuresContext] = useFeaturesContext();

    const { isSubMenuPanelExpanded, isMainChromeDrawerExpanded } =
      chromeContext;

    useEffect(() => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }, [isSubMenuPanelExpanded]);

    function getSubPanelStyle(): ViewStyle {
      if (isMainChromeDrawerExpanded && isSubMenuPanelExpanded) {
        return styles.subMenuPanelExpanded;
      }

      if (isMainChromeDrawerExpanded && !isSubMenuPanelExpanded) {
        return styles.subMenuPanelCollapsedReady;
      }

      if (!isMainChromeDrawerExpanded && !isSubMenuPanelExpanded) {
        return styles.subMenuPanelCollapsed;
      }

      return {};
    }

    // TODO: Port shareplus
    // const sharePlusCurrent: JSX.Element = useMemo(() => {
    //   if (featuresContext.sharePlusSuccessPayload?.id) {
    //     if (workspace.isInternetReachable) {
    //       return <SharePlusSuccess />;
    //     } else {
    //       return <SharePlusSuccessOffline />;
    //     }
    //   } else {
    //     return <SharePlusCurrentInProgressDrawer />;
    //   }
    // }, [workspace.isInternetReachable, featuresContext.sharePlusSuccessPayload]);

    function renderSelectedPanel(): JSX.Element {
      switch (chromeContext.selectedInternalMenuItem) {
        // TODO: Port shareplus
        // case ChromeInternalMenuTypes.Help:
        //   return (
        //     <ScrollView style={styles.scrollView}>
        //       <Text style={{}}>{JSON.stringify(workspace, null, 4)}</Text>
        //     </ScrollView>
        //   );
        // case ChromeInternalMenuTypes.SharePlusCurrent:
        //   return sharePlusCurrent;

        // case ChromeInternalMenuTypes.SharePlusSaved:
        //   return <SharePlusSavedDraftsDrawer />;
        default:
          return <></>;
      }
    }

    return (
      <View style={[styles.subMenuPanel, getSubPanelStyle()]}>
        {renderSelectedPanel()}
      </View>
    );
  };

export default ChromeMenuSubPanel;
