/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import {
  LayoutAnimation,
  Platform,
  ScrollView,
  StyleSheet,
  UIManager,
  View,
  ViewStyle,
} from 'react-native';

import { useChromeContext } from '../../../context/workspace/chrome.context';
import { useWorkspace } from '../../../context/workspace/workspace.context';
import { Styles } from '../../../models/styles.model';
import { sizes } from '../../../themes/sizes';

import ColumnMenuSharePlus from './column-menus/ColumnMenuSharePlus';
import DrawerMenuDivider from './DrawerMenuDivider';
import DrawerMenuDynamic from './DrawerMenuDynamic';
import StorySlabMenu from './StorySlabMenu';

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

interface Props {
  dynamicMenuItems: any;
}

const styles: Styles = StyleSheet.create({
  mainMenu: {
    marginTop: sizes.md,
  } as ViewStyle,
  menuColumn: {
    flex: 1,
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  } as ViewStyle,
  toTheCenter: {
    left: 0,
  } as ViewStyle,
  toTheLeft: {
    left: '-100%',
  } as ViewStyle,
  toTheRight: {
    left: '100%',
  } as ViewStyle,
});

export const DynamicColumnMenu: FunctionComponent<Props> =
  function DynamicColumnMenu(props: PropsWithChildren<Props>): JSX.Element {
    const [workspace] = useWorkspace();
    const [chromeContext] = useChromeContext();

    const primaryColor: string = workspace?.styles?.primaryColor || '';

    function getClassForMenuPosition(index: number): ViewStyle {
      // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      if (
        typeof chromeContext.selectedMenuColumn === 'undefined' ||
        chromeContext.selectedMenuColumn === null ||
        index < chromeContext.selectedMenuColumn
      ) {
        return styles.toTheLeft;
      }

      if (index > chromeContext.selectedMenuColumn) {
        return styles.toTheRight;
      }

      return styles.toTheCenter;
    }

    return (
      <>
        <View
          style={[
            styles.menuColumn,
            styles.mainMenu,
            getClassForMenuPosition(0),
          ]}
        >
          <ScrollView contentContainerStyle={styles.scrollViewContentContainer}>
            <DrawerMenuDynamic
              iconColor={primaryColor}
              selectedColor={primaryColor}
              items={chromeContext.dynamicMenuItems}
            />

            <DrawerMenuDivider />

            <StorySlabMenu />
          </ScrollView>
        </View>

        <View style={[styles.menuColumn, getClassForMenuPosition(1)]}>
          <ScrollView contentContainerStyle={styles.scrollViewContentContainer}>
            <ColumnMenuSharePlus />
          </ScrollView>
        </View>
      </>
    );
  };

export default DynamicColumnMenu;
