export enum ComponentTargetTypes {
  COLLECTION = 'collection',
  CONTENT_ITEM = 'contentItem',
  FILE = 'file',
  LINK = 'link',
}

export enum ComponentOptionTypes {
  ASSET = 'asset',
}

export enum ComponentOptionKeys {
  DEFAULT_LOGO_ASSET_ID = 'defaultLogoAssetId',
}

export enum ComponentViewTypes {
  COLLECTION = 'collection',
}
