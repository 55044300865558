import React from 'react';
import { View } from 'react-native';

import { createDynamicStyleSheet } from '../../helpers/styles.helper';
import { Styles } from 'models/styles.model';

interface Props {
  width?: number;
}

const styles: Styles = createDynamicStyleSheet(
  {
    container: {
      backgroundColor: 'rgba(255, 255, 255,0.9)',
      borderRadius: 2,
      elevation: 10,
      padding: 36,
      shadowColor: '#000',
      shadowOffset: {
        height: 5,
        width: 0,
      },
      shadowOpacity: 0.34,
      shadowRadius: 6.27,
      width: '45%',
    },
  },
  {
    phone: {
      container: {
        width: '100%',
      },
    },
  },
);

export const Card: React.FunctionComponent<Props> = function Card(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  return <View style={styles.container}>{props.children}</View>;
};

export default Card;
