/* eslint-disable @typescript-eslint/no-empty-interface */

import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { ImageProps, ImageSourcePropType } from 'react-native';
import Image from '../../components/images/Image'
import { useWorkspace } from '../../context/workspace/workspace.context';
import {
  getAssetById,
} from '../../accessors/asset.accessor';

import { fetchSignedUrl } from '../../services/s3.service'
import { AssetsItem } from '../../models/data';
import { env } from '../../helpers/env'

interface Props extends Partial<ImageProps> {
  assetId: number;
}

export const AssetImage: FunctionComponent<Props> = function AssetImage(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const [workspace] = useWorkspace();
  const { assetId } = props;
  const [imageSource, setImageSource] = useState<ImageSourcePropType>();

  // We fetch the signed URL and then use that directly as the image source
  useEffect(() => {

    async function fetchAndSetImageSource() {
      const currentAsset = await getAssetById({id: assetId});

      if(!currentAsset) {
        console.log(`Unable to find asset ID ${assetId}`);
        return;
      }

      let results = await fetchSignedUrl({
        bucket: env.REACT_APP_STORYSLAB_BUCKET_TENANT_ASSETS,
        key: currentAsset.s3Key,
        s3ClientConfig: workspace.temporaryCognitoCredentials || null,
      });
      setImageSource({
        uri:results
      });
    }

    fetchAndSetImageSource();

  }, [assetId]);

  return imageSource ? (
    <Image
      resizeMode={props.resizeMode || 'cover'}
      style={props.style}
      source={imageSource}
    />
  ) : (
    <></>
  );
};

export default AssetImage;
