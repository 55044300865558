import React, { useState } from 'react';
import Image from '../../components/images/Image';

interface Props {
  height?: number;
  url: string;
}
export const RemoteLogo: React.FunctionComponent<Props> = function RemoteLogo(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  const { height, url } = props;
  const [logoMeta, setLogoMeta] = useState<{ width: number; height: number }>();

  return (
    <Image
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        { height: height ? height : 60 },
        logoMeta ? { aspectRatio: logoMeta.width / logoMeta.height } : {},
      ]}
      resizeMode='contain'
      source={{
        uri: url,
      }}
      onLoad={(e: any): void => {
        setLogoMeta(e.nativeEvent);
      }}
    />
  );
};

export default RemoteLogo;
