import React, { useEffect, useRef } from 'react';
import { Animated, Easing, ViewProps } from 'react-native';

import StorySlabLogo from '../../assets/logos/storyslab-color-logo.svg';

interface Props {
  style?: ViewProps;
  isLoading?: boolean;
}

const Loading: React.FunctionComponent<Props> = function Loading(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  const { isLoading, style, children } = props;

  const spinValue: Animated.Value = useRef(new Animated.Value(0)).current;

  const animation: Animated.CompositeAnimation = Animated.loop(
    Animated.timing(spinValue, {
      duration: 5000,
      easing: Easing.linear,
      toValue: 1,
      useNativeDriver: true,
    }),
  );

  useEffect(() => {
    if (isLoading) {
      animation.start();
    } else {
      animation.reset();
    }
  }, [isLoading]);

  const spin: any = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  return (
    <Animated.View style={[style, { transform: [{ rotate: spin }] }]}>
      {children ? children : <StorySlabLogo width={'100%'} height={'100%'} />}
    </Animated.View>
  );
};

export default React.memo<React.PropsWithChildren<Props>>(Loading);
