/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef, useState } from 'react';
import { StackScreenProps } from '@react-navigation/stack';

import WorkspaceErrorAlert from '../../../components/alerts/WorkspaceErrorAlert';
import ButtonRounded from '../../../components/buttons/Rounded';
import TextButton from '../../../components/buttons/Text';
import HeaderFooterCard from '../../../components/cards/HeaderFooterCard';
import FullScreenCardContainer from '../../../components/containers/FullScreenCardContainer';
import { LabeledTextInput } from '../../../components/forms/LabeledTextInput';
import { Col, Row } from '../../../components/grid';
import LogoHeader from '../../../components/headers/LogoHeader';
import RemoteLogo from '../../../components/images/RemoteLogo';
import { useDomain } from '../../../context/domain/domain.context';
import {
  useWorkspace,
  useWorkspaceActions,
} from '../../../context/workspace/workspace.context';
import { AuthState } from '../../../enums/auth.enum';
import { WorkspaceSyncState } from '../../../enums/sync.enum';
import { mapCredentials } from '../../../helpers/auth.helper';
import { createDynamicStyleSheet } from '../../../helpers/styles.helper';
import { OAuthPayloadRaw } from '../../../models/auth.model';
import { Styles } from '../../../models/styles.model';
import {
  WorkspaceContextProps,
  WorkspaceStackParamList,
} from '../../../models/workspace.model';
import { genericLogin } from '../../../services/auth.service';
import { colors, sizes } from '../../../themes';

import { WorkspaceNavigationEnum } from '../../../enums/navigation.enum';

interface Props
  extends StackScreenProps<
    WorkspaceStackParamList,
    WorkspaceNavigationEnum.GenericLoginScreen
  > {
  handleError: (error: string) => void;
}

export const GenericLoginScreen: React.FunctionComponent<Props> =
  function GenericLoginScreen(props: Props): JSX.Element {
    const [workspace, setWorkspace] = useWorkspace();
    const { clearErrorState, setErrorState } = useWorkspaceActions();
    const [domain] = useDomain();

    const [username, setUsername] = useState(''); // TODO: josh-test1@storyslab.com
    const [password, setPassword] = useState(''); // TODO: Test1234!
    const [isLoading, setIsLoading] = useState(false);
    const didLoginSucceed: React.MutableRefObject<boolean> = useRef(false);

    useEffect(() => {
      setIsLoading(false);
      clearErrorState();
      return (): void => {
        setIsLoading(false);
      };
    }, []);

    async function handleLogin(): Promise<void> {
      if (workspace.appCode && domain.tenantId) {
        setIsLoading(true);
        clearErrorState();

        const [result, err] = await genericLogin({
          applicationId: String(domain.applicationId),
          password: password,
          tenantId: domain.tenantId,
          username: username,
        });

        if (!err) {
          didLoginSucceed.current = true;

          const jwt: OAuthPayloadRaw = result.data;
          const user: any = result.meta.user;

          setWorkspace((prevState: Partial<WorkspaceContextProps>) => {
            return {
              ...prevState,
              authState: AuthState.SYNC_REQUIRED,
              credentials: mapCredentials(jwt),
              syncState: WorkspaceSyncState.NONE,
            };
          });
        } else {
          console.log(err);

          setErrorState(err);
          setIsLoading(false);
        }
      } else {
        throw new Error('workspace.appCode or domain.tenantId is missing.');
      }
    }

    return (
      <FullScreenCardContainer backgroundUri={workspace?.assets?.backgroundUrl}>
        <HeaderFooterCard
          header={<LogoHeader title={'Login'} />}
          footer={
            workspace?.assets?.logoUrl ? (
              <RemoteLogo url={workspace.assets.logoUrl} />
            ) : null
          }
        >
          <>
            <Row style={styles.inputRow}>
              <LabeledTextInput
                label={'Username'}
                onChangeText={(text: string): void => setUsername(text)}
                value={username}
              />
            </Row>
            <Row>
              <LabeledTextInput
                label={'Password'}
                isPassword={true}
                onChangeText={(text: string): void => setPassword(text)}
                value={password}
              />
            </Row>
            {workspace.errorState && (
              <Row>
                <WorkspaceErrorAlert />
              </Row>
            )}
            <Row style={styles.buttonRow}>
              <Col>{/* <TextButton>Forgot Password</TextButton> */}</Col>
              <Col>
                <ButtonRounded
                  accessibilityLabel="Continue to the login page"
                  isLoading={!workspace.errorState && isLoading}
                  onPress={(): void => {
                    handleLogin();
                  }}
                  title="Continue"
                  titleColor={colors.buttonTextLight}
                  backgroundColor={workspace?.styles?.primaryColor}
                />
              </Col>
            </Row>
          </>
        </HeaderFooterCard>
      </FullScreenCardContainer>
    );
  };

const styles: Styles = createDynamicStyleSheet({
  backgroundImage: {
    height: '100%',
    position: 'absolute',
    resizeMode: 'cover',
    width: '100%',
  },
  brandLogo: {
    height: 60,
  },
  buttonRow: {
    paddingVertical: sizes.lg,
  },
  headerRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputRow: {
    marginVertical: sizes.md,
  },
});

export default GenericLoginScreen;
