export enum ClientApiQueryParams {
  ManifestType = '{{manifestType}}',
}

export enum ClientApiUrls {
  AnalyticsEvents = '/analytics',
  CognitoIdentityCredentials = '/cognito-identity-credentials',
  FetchManifest = `/fetch-manifest`,
  SharePlus = '/share',
}
