import { getContentItemTargetType } from '@storyslab/storyslab.common.helpers/lib/contentItem';
import { ContentItemTargetType } from '@storyslab/storyslab.common.models';
import { ContentItem } from '@storyslab/storyslab.common.models';

export function getDefaultIconForItem(item: ContentItem): string {
  const typeOfItem: ContentItemTargetType = getContentItemTargetType(item);
  switch (typeOfItem) {
    case ContentItemTargetType.IMAGE:
      return 'file-image';
    case ContentItemTargetType.VIDEO:
      return 'file-video';
    case ContentItemTargetType.PDF:
      return 'file-pdf';
    case ContentItemTargetType.DOCUMENT:
      return 'file-alt';
    case ContentItemTargetType.SHORTCUT:
      return 'external-link';
    case ContentItemTargetType.LINK:
      return 'link';
    case ContentItemTargetType.ARCHIVE:
      return 'file-archive';
    default:
      return 'file';
    // TODO: Return special icon for public file on web if we can't figure out file type?
    // eslint-disable-next-line multiline-comment-style
    // If (typeOfItem === ContentItemTargetType.UNKNOWN && item.target?.syncParams){
    //   Return 'universal-access';
    // }
    // Else {
    //   Return 'file';
    // }
  }
}
