import React from 'react';
import {
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';

import { NavigationItem } from '@storyslab/storyslab.common.models';

import Icon from '../../../components/icons/Icon';
import MenuToolTip from '../../../components/tooltips/MenuTooltip';
import { ListItem3 } from '../../../components/typography/ListItems';
import { Styles } from '../../../models/styles.model';
import { colors } from '../../../themes/index';
import { sizes } from '../../../themes/sizes';

const iconSize: number = 32;

export interface Props {
  icon?: IconName;
  iconColor?: string;
  selectedColor?: string;
  isSelected?: boolean;
  marginLeft?: ViewStyle;
  navigationItem?: NavigationItem;
  onPress?: () => void;
  style?: ViewStyle;
  textStyle?: TextStyle;
  title: string;
  toolTip?: JSX.Element;
}

const styles: Styles = StyleSheet.create({
  center: {
    flex: 1,
    marginVertical: sizes.xs,
    textAlign: 'center',
  } as TextStyle,
  icon: {} as FontAwesomeIconStyle,
  iconWrapper: {
    borderRadius: 34 + sizes.sm / 2,
    marginRight: sizes.sm,
    padding: sizes.xs,
  } as ViewStyle,
  menuItem: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: sizes.xs / 2,
    paddingVertical: sizes.xs / 2,
  } as ViewStyle,
  selectionIndicator: {
    bottom: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: sizes.xs,
  } as ViewStyle,
});

export const DrawerMenuItem: React.FunctionComponent<Props> =
  function DrawerMenuItem(props: React.PropsWithChildren<Props>): JSX.Element {
    const {
      icon,
      iconColor,
      isSelected,
      marginLeft,
      onPress,
      selectedColor,
      style,
      textStyle,
      title,
      toolTip,
    } = props;

    function renderMenuItem(): JSX.Element {
      return (
        <View style={[styles.menuItem, marginLeft, style]}>
          {isSelected && toolTip && <MenuToolTip>{toolTip}</MenuToolTip>}
          {icon && (
            <View
              style={[
                styles.iconWrapper,
                isSelected ? { backgroundColor: selectedColor } : null,
              ]}
            >
              <Icon
                icon={icon}
                style={[
                  icon,
                  styles.style,
                  isSelected
                    ? { color: colors.textWhite }
                    : { color: iconColor },
                ]}
                size={iconSize}
              />
            </View>
          )}

          <ListItem3 style={[!icon ? styles.center : null, textStyle]}>
            {title}
          </ListItem3>
          {isSelected && (
            <View
              style={[
                styles.selectionIndicator,
                { backgroundColor: selectedColor },
              ]}
            />
          )}
        </View>
      );
    }

    return (
      <TouchableOpacity onPress={onPress}>{renderMenuItem()}</TouchableOpacity>
    );
  };

export default DrawerMenuItem;
