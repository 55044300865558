/* eslint-disable @typescript-eslint/no-empty-interface */
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ContextI } from '../../models/context.model';
import { FeaturesContextProps } from '../../models/features.model';

import { featuresActions } from '../../actions/features.actions';

interface Props {
  // ApplicationId: number | undefined;
}

type ContextProps = FeaturesContextProps;
export type FeaturesContextI = ContextI<ContextProps>;

const Context: React.Context<FeaturesContextI> = createContext([] as any);

export function useFeaturesContext(): FeaturesContextI {
  const context: FeaturesContextI = useContext(Context);

  if (!context) {
    throw new Error('useFeaturesContext must be used within a Global Provider');
  }
  return context;
}

export function useFeaturesActions(): any {
  const context: FeaturesContextI = useContext(Context);

  if (!context) {
    throw new Error('useFeaturesContext must be used within a Global Provider');
  }

  return featuresActions(context);
}

const FeaturesContextProvider: React.FunctionComponent<Props> = ({
  children,
}: PropsWithChildren<Props>) => {
  const [featuresContext, setFeaturesContext] = useState<Partial<ContextProps>>(
    {},
  );

  const value: FeaturesContextI = useMemo<FeaturesContextI>(
    () => [
      featuresContext,
      (
        state:
          | Partial<ContextProps>
          | ((prevState: Partial<ContextProps>) => Partial<ContextProps>),
      ): void => {
        if (typeof state === 'function') {
          setFeaturesContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              ...state(prevState),
            };
          });
        } else {
          setFeaturesContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              ...state,
            };
          });
        }
      },
    ],
    [featuresContext],
  );

  // TODO: Port sharepoint
  // useEffect(() => {
  //   featuresActions([
  //     featuresContext,
  //     setFeaturesContext,
  //   ]).refreshContextWithCurrentBundle();
  // }, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default FeaturesContextProvider;
export type FeaturesContextFeaturesContextI = FeaturesContextI;
