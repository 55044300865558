import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { Styles } from '../../../models/styles.model';
import { sizes } from '../../../themes/sizes';

interface Props {
  disableTopMargin?: boolean;
}

const styles: Styles = StyleSheet.create({
  divider: {
    alignSelf: 'stretch',
    backgroundColor: '#f2f3f2',
    height: 1,
    marginHorizontal: sizes.md,
  } as ViewStyle,
});

export const DrawerMenuDivider: React.FunctionComponent<Props> =
  function DrawerMenuDivider(
    props: React.PropsWithChildren<Props>,
  ): JSX.Element {
    return (
      <View
        style={[
          styles.divider,
          !props.disableTopMargin ? { marginTop: sizes.sm14 } : null,
        ]}
      >
        {props.children}
      </View>
    );
  };

export default DrawerMenuDivider;
