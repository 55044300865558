/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View, ViewStyle } from 'react-native';

import { Styles } from '../../models/styles.model';
import { colors } from '../../themes/index';
import { sizes } from '../../themes/sizes';

interface Props {
  style?: ViewStyle | Array<ViewStyle>;
}

const styles: Styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.background,
    borderRadius: sizes.sm,
    bottom: -sizes.sm,
    elevation: 10,
    flexDirection: 'column',
    minWidth: 250,
    paddingVertical: sizes.sm,
    position: 'absolute',
    right: '-50%',
    shadowColor: '#000',
    shadowOffset: {
      height: 5,
      width: 0,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
  } as ViewStyle,
});

export const MenuToolTip: React.FunctionComponent<Props> = function MenuToolTip(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  const [viewWidth, setViewWidth] = useState(0);

  return (
    <View
      style={[styles.container, { right: -viewWidth - sizes.sm }]}
      onLayout={(event: LayoutChangeEvent): void => {
        const { width } = event.nativeEvent.layout;
        setViewWidth(width);
      }}
    >
      {props.children}
    </View>
  );
};

export default MenuToolTip;
