import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  ImageStyle,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import AssetImage from '../../../../components/images/AssetImage';
import { H3p5 } from '../../../../components/typography';
import { CollectionsItem } from '../../../../models/data/collections.model';
import { Styles } from '../../../../models/styles.model';
import { colors, fontFamily } from '../../../../themes';

import HorizontalBase, { Props as BaseProps } from './horizontal-base';

interface Props extends Partial<BaseProps> {
  onPress?: (item: CollectionsItem) => void;
}

const defaultHeight: number = 274;
const defaultWidth: number = defaultHeight / 0.88;

const styles: Styles = StyleSheet.create({
  backgroundImage: {
    height: '80%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: defaultWidth,
  } as ImageStyle,
  banner: {
    /*
     * PaddingHorizontal: sizes.md,
     * paddingVertical: sizes.xs,
     * width: 216 * 0.9,
     */
    alignItems: 'center',
    backgroundColor: '#101010',
    borderRadius: 5,
    height: 62,
    justifyContent: 'center',
    marginBottom: 6,
    paddingHorizontal: 14,
    paddingTop: 10,
    width: defaultWidth,
  } as ViewStyle,
  bannerText: {
    color: colors.textWhite,
    fontFamily: fontFamily.extraBold,
    textAlign: 'center',
    textTransform: 'uppercase',
  } as TextStyle,
  flatList: {
    height: defaultHeight,
  } as ViewStyle,
  item: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'flex-end',
    // MarginLeft: 28,
    marginLeft: -5,
    width: defaultWidth,
  } as ViewStyle,
});

export const HorizontalTitleStripeScroll: FunctionComponent<Props> =
  // eslint-disable-next-line max-lines-per-function
  function HorizontalTitleStripeScroll(
    props: PropsWithChildren<Props>,
  ): JSX.Element {
    return (
      <HorizontalBase
        contentInset={{
          bottom: 0,
          left: 100,
          right: 10,
          top: 0,
        }}
        contentOffset={{ x: -100, y: 0 }}
        {...props}
        style={[styles.flatList, props.style]}
        data={props.data}
        renderItem={(datum: any): JSX.Element => {
          return (
            <TouchableOpacity
              style={styles.item}
              onPress={(): void => {
                if (props.onPress) {
                  props.onPress(datum.item);
                }
              }}
            >
              <View style={styles.banner}>
                {!datum.item.hideTitles && (
                  <H3p5 style={styles.bannerText} numberOfLines={1}>
                    {datum.item.text}
                  </H3p5>
                )}
              </View>

              {datum.item.image && (
                <AssetImage
                  assetId={datum.item.image}
                  style={styles.backgroundImage}
                  resizeMode={'contain'}
                />
              )}
            </TouchableOpacity>
          );
        }}
      />
    );
  };

export default HorizontalTitleStripeScroll;
