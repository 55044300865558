import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { Styles } from '../../../models/styles.model';
import { sizes } from '../../../themes/sizes';

import DrawerMenuItem, { Props as DrawerMenuItemProps } from './DrawerMenuItem';

interface Props {
  items: Array<DrawerMenuItemProps>;
  iconColor?: string;
  selectedColor?: string;
  shouldDisableMarginLeft?: boolean;
}

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    margin: 0,
    marginTop: sizes.xs,
  } as ViewStyle,
  marginLeft: {
    marginLeft: sizes.md,
  } as ViewStyle,
  noMarginLeft: {
    marginLeft: 0,
  } as ViewStyle,
});

export const DrawerMenuInternal: React.FunctionComponent<Props> =
  function DrawerMenu(props: React.PropsWithChildren<Props>): JSX.Element {
    const { shouldDisableMarginLeft, items, selectedColor, iconColor } = props;

    return (
      <View style={[styles.container]}>
        {items &&
          items.map((itemProps: DrawerMenuItemProps, index: number) => (
            <DrawerMenuItem
              {...{
                ...itemProps,
                iconColor: itemProps.iconColor || iconColor,
                marginLeft: shouldDisableMarginLeft
                  ? styles.noMarginLeft
                  : styles.marginLeft,
                selectedColor: selectedColor,
              }}
              key={index}
            />
          ))}
      </View>
    );
  };

export default DrawerMenuInternal;
