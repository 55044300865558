import { cloneDeep } from 'lodash';

import { ViewsManifestItem } from '../models/data';
import { WorkspaceContextProps } from '../models/workspace.model';

import { TemplateNavigationEnum } from '../templates/default/enums/navigation.enum';

import { isDateExpiredGmt } from './date.helper';
import { Logger } from './logger.helper';

const logger: Logger = new Logger('workspace-navigation.helper.ts');

export function areCredentialsExpired(
  workspace: Partial<WorkspaceContextProps>,
): boolean {
  return !!((workspace?.credentials?.expiresAt || 0) < Date.now());
}

export function isUserLoggedIn(
  workspace: Partial<WorkspaceContextProps>,
): boolean {
  return (
    !!workspace?.credentials &&
    !!workspace?.entitlements &&
    !areCredentialsExpired(workspace)
  );
}

export function isUserLoggedInAndHasTempCredentials(
  workspace: Partial<WorkspaceContextProps>,
): boolean {
  return (
    isUserLoggedIn(workspace) &&
    !!workspace.temporaryCognitoCredentials &&
    !isDateExpiredGmt(
      workspace.temporaryCognitoCredentials?.credentials.expiration,
    )
  );
}

export function isUserNotLoggedIn(
  workspace: Partial<WorkspaceContextProps>,
): boolean {
  return (
    !!workspace?.credentials || !!workspace?.entitlements

    // Hold: || !areCredentialsExpired(workspace)
  );
}

export function getLayoutKeyByViewId(
  viewId: number,
  {
    workspace,
  }: {
    workspace: Partial<WorkspaceContextProps>;
  },
): TemplateNavigationEnum | null {
  if (viewId && workspace.views && workspace.views[viewId]) {
    const view: any = workspace.views[viewId];
    if (
      view &&
      view.layoutKey &&
      Object.values(TemplateNavigationEnum).includes(view.layoutKey)
    ) {
      return view.layoutKey;
    }
  }
  return null;
}

export function getViewByViewId(
  id: number,
  {
    workspace,
  }: {
    workspace: Partial<WorkspaceContextProps>;
  },
): ViewsManifestItem<any> | null {
  if (id && workspace.views && workspace.views[id]) {
    return cloneDeep(workspace.views[id]);
  }
  return null;
}
