/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  AlertType,
  LayoutAnimation,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  UIManager,
  View,
  ViewStyle,
} from 'react-native';

import { Styles } from '../../models/styles.model';
import { sizes } from '../../themes/sizes';
import { AlertTypes } from '../../enums/alerts.enum';
import { colors } from '../../themes/';

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

interface Props {
  type: AlertTypes;
  message: string;
}

const styles: Styles = StyleSheet.create({
  container: {
    marginTop: sizes.lg,
    flexDirection: 'row',
    width: '100%',
    padding: sizes.md,
    borderRadius: sizes.sm,
  } as ViewStyle,
  text: { fontWeight: 'bold' } as TextStyle,
});

function getBackgroundColorBasedOnType(type: AlertTypes): ViewStyle {
  switch (type) {
    case AlertTypes.ERROR:
      return { backgroundColor: colors.danger };

    default:
      return { backgroundColor: colors.default };
  }
}

function getTextColorBasedOnType(type: AlertTypes): TextStyle {
  switch (type) {
    case AlertTypes.ERROR:
      return { color: colors.white };
    default:
      return { color: colors.white };
  }
}

export const Alert: FunctionComponent<Props> = function Alert(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { message, type } = props;

  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

  return (
    <View style={[styles.container, getBackgroundColorBasedOnType(type)]}>
      <Text style={[styles.text, getTextColorBasedOnType(type)]}>
        {message}
      </Text>
    </View>
  );
};

export default Alert;
