/* eslint-disable max-lines-per-function */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { DestructuredResponse, PromiseResponse } from 'models/promise.model';

import { env } from '../helpers/env';
import { generateHeaders } from '../helpers/util.helper';
import {
  ApiRequestMethods,
  ApiRequestParams,
  FetchHeaders,
} from '../models/api.model';

const shouldEnableLog: boolean = false;

if (shouldEnableLog) {
  axios.interceptors.request.use((request: AxiosRequestConfig) => {
    console.log('Starting Request', JSON.stringify(request, null, 2));

    return request;
  });

  axios.interceptors.response.use((response: AxiosResponse) => {
    console.log('Response:', JSON.stringify(response, null, 2));

    return response;
  });
}

function isSuccessfulResponse(status: number): boolean {
  return status >= 200 && status <= 299;
}

export async function fetchFromClientApi<T>(
  params: ApiRequestParams,
): PromiseResponse<T> {
  let headers: FetchHeaders = {};

  if (params.headers) {
    headers = params.headers;
  }

  headers = {
    ...headers,
    ...generateHeaders(params.auth),
    'Content-Type': 'application/json',
  };

  if (shouldEnableLog) {
    console.log(
      `${env.REACT_APP_API_GATEWAY_CLIENT}${
        params.url.indexOf('/') === 0 ? params.url : `/${params.url}`
      }`,
      {
        headers: headers,
        params: params.body ? params.body : null,
      },
    );
  }

  if (params.method === ApiRequestMethods.GET) {
    return await axios
      .get(
        `${env.REACT_APP_API_GATEWAY_CLIENT}${
          params.url.indexOf('/') === 0 ? params.url : `/${params.url}`
        }`,
        {
          headers: headers,
          params: params.body ? params.body : null,
        },
      )
      .then((response: AxiosResponse): DestructuredResponse<T> => {
        if (isSuccessfulResponse(response.status)) {
          return [response.data?.data, null];
        } else {
          return [null, response];
        }
      })
      .catch((error: any) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a status code
           * that falls out of the range of 2xx
           */
          console.log(error.response);
        } else if (error.request) {
          /*
           * The request was made but no response was received
           * `error.request` is an instance of XMLHttpRequest in the browser and an instance of
           * http.ClientRequest in node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }

        return [null, error.response || error];
      });
  }

  if (params.method === ApiRequestMethods.POST) {
    return await axios
      .post(
        `${env.REACT_APP_API_GATEWAY_CLIENT}${
          params.url.indexOf('/') === 0 ? params.url : `/${params.url}`
        }`,
        params.body,
        {
          headers: headers,
        },
      )
      .then((response: AxiosResponse): DestructuredResponse<T> => {
        if (isSuccessfulResponse(response.status)) {
          return [response.data?.data, null];
        } else {
          return [null, response];
        }
      })
      .catch((error: any) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a status code
           * that falls out of the range of 2xx
           */
          console.log(error.response);
        } else if (error.request) {
          /*
           * The request was made but no response was received
           * `error.request` is an instance of XMLHttpRequest in the browser and an instance of
           * http.ClientRequest in node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }

        return [null, error.response || error];
      });
  }

  return Promise.resolve([null, 'Missing ApiRequestMethods']);

  /*
   * Return await fetch(
   *   `${env.REACT_APP_API_GATEWAY_CLIENT}${
   *     params.url.indexOf('/') === 0 ? params.url : `/${params.url}`
   *   }`,
   *   {
   *     body: params.body ? JSON.stringify(params.body) : null,
   *     headers: headers,
   *     method: params.method,
   *   },
   * )
   *   .then((response: Response) => {
   *     console.log(response);
   *     return response;
   *   })
   *   .then((response: Response) => response.json())
   *   .then((result: RestResponse<any>) => {
   *     if (result.error) {
   *       throw result.error;
   *     }
   */

  /*
   *     If (params.includeMeta) {
   *       return result;
   *     }
   */

  /*
   *     Return result.data;
   *   });
   */
}
