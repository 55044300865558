/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';
import { NavigationProp, useNavigation } from '@react-navigation/core';

import IconButton from '../../components/buttons/Icon';
import SharePlusHeaderButton from '../../components/buttons/SharePlusHeaderButton';
import { H3p5 } from '../../components/typography';
import { useWorkspace } from '../../context/workspace/workspace.context';
import { Styles } from '../../models/styles.model';

import PageHeader from './PageHeader';

interface Props {
  params: any;
}

const iconSize: number = 28;

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  } as ViewStyle,
  leftIcon: {
    marginRight: 20,
  } as FontAwesomeIconStyle,
  rightIcon: {
    marginLeft: 20,
  } as FontAwesomeIconStyle,
});

export const ContentViewHeader: FunctionComponent<Props> =
  function ContentViewHeader(props: PropsWithChildren<Props>): JSX.Element {
    const navigation: NavigationProp<any> = useNavigation();
    const [workspace] = useWorkspace();

    const { params } = props;

    return (
      <PageHeader
        leftColumn={
          <>
            <IconButton
              icon="chevron-left"
              size={iconSize}
              style={[
                styles.leftIcon,
                { color: workspace.styles?.primaryColor },
              ]}
              onPress={(): void => {
                navigation.goBack();
              }}
            />
          </>
        }
        centerColumn={<H3p5 style={styles.breadcrumbs}>{params?.name}</H3p5>}
        rightColumn={
          <>
            <SharePlusHeaderButton params={params} />

            {/* <IconButton
              icon="heart"
              size={iconSize}
              style={[
                styles.rightIcon,
                { color: workspace.styles?.primaryColor },
              ]}
            />
            <IconButton
              icon="book-open"
              size={iconSize}
              style={[
                styles.rightIcon,
                { color: workspace.styles?.primaryColor },
              ]}
            /> */}
            {/* <IconButton
              icon="ellipsis-v"
              size={iconSize}
              style={[
                styles.rightIcon,
                { color: workspace.styles?.primaryColor },
              ]}
            /> */}
          </>
        }
      />
    );
  };

export default ContentViewHeader;
