/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';

import { FontAwesomeLibraries } from '../../enums/font-awesome.enum';
import { Styles } from '../../models/styles.model';
import { Colors, colors } from '../../themes';

import Icon from './Icon';

const iconSize: number = 28;

interface Props {
  color?: string;
  count?: number;
  icon: IconName;
  library?: FontAwesomeLibraries;
  size?: number;
  style?: FontAwesomeIconStyle;
}

export const BadgeIcon: FunctionComponent<Props> = function BadgeIcon(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { color, count, icon, library, size = iconSize, style } = props;

  const badgeSize: number = useMemo(() => Math.ceil(size * 0.71), [size]);

  return (
    <View style={styles.container}>
      <Icon
        color={color || colors.gray}
        icon={icon}
        size={size || iconSize}
        style={[style]}
        library={library || FontAwesomeLibraries.FAL}
      />
      {count ? (
        <View
          style={[
            styles.badge,
            {
              borderRadius: badgeSize / 2,
              height: badgeSize,
              left: -(size * 0.3),
              top: size * 0.6,
              width: badgeSize,
            },
          ]}
        >
          <Text style={styles.badgeNumber}>{String(count)}</Text>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

const styles: Styles = StyleSheet.create({
  badge: {
    alignItems: 'center',
    backgroundColor: colors.darkGray,
    borderRadius: 10,
    flexDirection: 'row',
    height: 20,
    justifyContent: 'center',
    width: 20,
  } as ViewStyle,
  badgeNumber: {
    color: colors.white,
    flexDirection: 'row',
  } as TextStyle,
  container: {
    flexDirection: 'row',
  } as ViewStyle,
});

export default BadgeIcon;
