/* eslint-disable @typescript-eslint/no-empty-interface */
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';

import { ChromeContextProps } from '../../models/chrome.model';
import { ContextI } from '../../models/context.model';

import { chromeActions } from '../../actions/chrome.actions';

import { useWorkspaceActions } from './workspace.context';

interface Props {
  // ApplicationId: number | undefined;
}

type ContextProps = ChromeContextProps;
export type ChromeContextI = ContextI<ContextProps>;

const defaultState: ChromeContextProps = {
  isChromeTabVisible: true,
  isMainChromeDrawerExpanded: false,
  selectedMenuColumn: 0,
};

const Context: React.Context<ChromeContextI> = createContext([] as any);

export function useChromeContext(): ChromeContextI {
  const context: ChromeContextI = useContext(Context);

  if (!context) {
    throw new Error('useChromeContext must be used within a Global Provider');
  }
  return context;
}

export function useChromeActions(): any {
  const context: ChromeContextI = useContext(Context);
  const navigation: NavigationProp<any> = useNavigation();

  if (!context) {
    throw new Error('useWorkspace must be used within a Global Provider');
  }

  return chromeActions(context, useWorkspaceActions(), navigation);
}

const ChromeContextProvider: React.FunctionComponent<Props> = ({
  children,
}: PropsWithChildren<Props>) => {
  const [chromeContext, setChromeContext] =
    useState<Partial<ContextProps>>(defaultState);

  const value: ChromeContextI = useMemo<ChromeContextI>(
    () => [
      chromeContext,
      (
        state:
          | Partial<ContextProps>
          | ((prevState: Partial<ContextProps>) => Partial<ContextProps>),
      ): void => {
        if (typeof state === 'function') {
          setChromeContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              ...state(prevState),
            };
          });
        } else {
          setChromeContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              ...state,
            };
          });
        }
      },
    ],
    [chromeContext],
  );

  useEffect(() => {
    return (): void => {
      console.log('Cleanup Chrome Context');
      setChromeContext(defaultState);
    };
  }, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ChromeContextProvider;
export type ChromeContextChromeContextI = ChromeContextI;
