import React, { useEffect } from 'react';
import { Platform, StyleSheet, UIManager, View, ViewStyle } from 'react-native';

import WorkspaceIndicator from '../../components/chrome/WorkspaceIndicator';
import DynamicColumnMenu from '../../components/menus/chrome/DynamicColumnMenu';
import SimpleIconsMenu from '../../components/menus/chrome/SimpleIconsMenu';
import TouchableOverlay from '../../components/overlay/TouchableOverlay';
import {
  useChromeActions,
  useChromeContext,
} from '../../context/workspace/chrome.context';
import { useWorkspace } from '../../context/workspace/workspace.context';
import { Styles } from '../../models/styles.model';
import { colors, sizes } from '../../themes/index';

import ChromeMenuSubPanel from './ChromeMenuSubPanel';

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

const styles: Styles = StyleSheet.create({
  bottomRow: {
    paddingBottom: sizes.sm,
  } as ViewStyle,
  collapsed: {
    left: '-25%',
  },
  container: {
    backgroundColor: colors.background,
    /*
     * BorderRightColor: '#333',
     * borderRightWidth: 1,
     */
    elevation: 6,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    position: 'absolute',

    shadowColor: '#000',
    shadowOffset: {
      height: 4,
      width: 3,
    },
    shadowOpacity: 0.37,
    shadowRadius: 4.65,

    width: '25%',
    zIndex: 10,
  } as ViewStyle,

  expanded: {
    left: 0,
  } as ViewStyle,
  mainMenu: {
    marginTop: sizes.md,
  } as ViewStyle,
  menuColumn: {
    flex: 1,
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  } as ViewStyle,
  scrollViewContentContainer: {
    flex: 1,
    paddingBottom: sizes.md,
    paddingTop: sizes.xs,
  } as ViewStyle,
  topRow: {
    flex: 1,
    overflow: 'hidden',
  } as ViewStyle,
});

export const MenuDrawer: React.FunctionComponent<Record<string, unknown>> =
  function MenuDrawer(): JSX.Element {
    const [workspace] = useWorkspace();
    const [chromeContext] = useChromeContext();
    const { setDynamicMenuItems, showMainChromeDrawer, hideMainChromeDrawer } =
      useChromeActions();

    function initDynamicMenuItems(): void {
      if (workspace.navigationItems) {
        setDynamicMenuItems(workspace.navigationItems);
      } else {
        console.log('There are no nav items');
      }
    }

    useEffect(() => {
      initDynamicMenuItems();
    }, []);

    useEffect(() => {
      initDynamicMenuItems();
    }, [workspace?.navigationItems]);

    return (
      <>
        {chromeContext.isMainChromeDrawerExpanded && (
          <TouchableOverlay
            onPress={
              chromeContext.isMainChromeDrawerExpanded
                ? hideMainChromeDrawer
                : showMainChromeDrawer
            }
            opacity={0.6}
            tintColor={'#ffffff'}
          />
        )}

        <View
          style={[
            styles.container,
            chromeContext.isMainChromeDrawerExpanded
              ? styles.expanded
              : styles.collapsed,
          ]}
        >
          <View style={styles.topRow}>
            <DynamicColumnMenu
              dynamicMenuItems={chromeContext.dynamicMenuItems}
            />
          </View>
          <View style={styles.bottomRow}>
            <WorkspaceIndicator
              applicationId={workspace.applicationId || -1}
              displayName={workspace.displayName || ''}
              onPressLogo={
                chromeContext.isMainChromeDrawerExpanded
                  ? hideMainChromeDrawer
                  : showMainChromeDrawer
              }
            />
            <SimpleIconsMenu
              iconColor={colors.textLight}
              selectedColor={workspace.styles?.primaryColor}
            />
          </View>
        </View>

        <ChromeMenuSubPanel />
      </>
    );
  };

export default MenuDrawer;
