/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { useWorkspace } from '../../context/workspace/workspace.context';
import { Styles } from '../../models/styles.model';

import { AlertTypes } from '../../enums/alerts.enum';

import Alert from './Alert';

interface Props {}

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  } as ViewStyle,
});

export const WorkspaceErrorAlert: FunctionComponent<Props> =
  function WorkspaceErrorAlert(props: PropsWithChildren<Props>): JSX.Element {
    const [workspace] = useWorkspace();

    return workspace.errorState?.message ? (
      <Alert type={AlertTypes.ERROR} message={workspace.errorState?.message} />
    ) : (
      <></>
    );
  };

export default WorkspaceErrorAlert;
