import { pSBC } from '../helpers/color.helper';
import { PublicDetails } from '../models/public-details.model';
import { WorkspaceContextProps } from '../models/workspace.model';
import { getPublicUrlFromS3Key } from '../services/s3.service';

export function mapPublicDetailsToWorkspace(
  publicDetails: PublicDetails,
): Partial<WorkspaceContextProps> {
  let tmpWorkspace: Partial<WorkspaceContextProps> = {
    applicationId: publicDetails?.applicationId,
    assets: {
      backgroundUrl: getPublicUrlFromS3Key(
        publicDetails?.appMeta?.authenticate?.style?.backgroundImage || '',
      ),
      logoUrl: getPublicUrlFromS3Key(
        publicDetails?.appMeta?.authenticate?.style?.squareLogo || '',
      ),
    },

    displayName: publicDetails?.displayName,
    identityProviders: publicDetails?.identityProviders,
    tenantId: publicDetails?.tenantId,
  };

  if (publicDetails?.appMeta?.authenticate?.basicLogin?.enabled) {
    tmpWorkspace = {
      ...tmpWorkspace,
      settings: {
        genericLogin: {
          displayName: publicDetails?.appMeta?.authenticate?.basicLogin?.prompt,
          iconKey: publicDetails?.appMeta?.authenticate?.basicLogin?.iconKey,
          iconUrl: getPublicUrlFromS3Key(
            publicDetails?.appMeta?.authenticate?.style?.squareLogo || '',
          ),
        },
      },
    };
  }

  if (publicDetails?.appMeta?.style?.app?.primaryColor) {
    const primaryColorLighter: any =
      pSBC(0.5, publicDetails?.appMeta?.style?.app?.primaryColor) || '';

    tmpWorkspace = {
      ...tmpWorkspace,
      styles: {
        primaryColor: publicDetails?.appMeta?.style?.app?.primaryColor,
        primaryColorLighter: primaryColorLighter,
        secondaryColor:
          publicDetails?.appMeta?.style?.app?.secondaryColor || '',
        theme: 'default',
      },
    };
  }

  return tmpWorkspace;
}
