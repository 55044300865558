/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */

import { ImageProps } from 'react-native';

const backgroundImages: Array<ImageProps> = [
  require('../assets/images/landing-page/backgrounds/ross-stone-jr9Li_iNaE0-unsplash.jpg'),
  require('../assets/images/landing-page/backgrounds/daniel-sessler-z2UNEkZHOhI-unsplash.jpg'),
  require('../assets/images/landing-page/backgrounds/filip-zrnzevic-QsWG0kjPQRY-unsplash.jpg'),
  require('../assets/images/landing-page/backgrounds/gabrielle-mustapich-IrETCrYmokI-unsplash.jpg'),
  require('../assets/images/landing-page/backgrounds/isaac-smith-AT77Q0Njnt0-unsplash.jpg'),
  require('../assets/images/landing-page/backgrounds/luca-bravo-zAjdgNXsMeg-unsplash.jpg'),
  require('../assets/images/landing-page/backgrounds/marian-kroell-1HwszNsuxXk-unsplash.jpg'),
  require('../assets/images/landing-page/backgrounds/mitch-mckee-Qhe9I6PhhZs-unsplash.jpg'),
];

export const getBackgroundImage: () => ImageProps = (): ImageProps =>
  backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
