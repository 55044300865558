import { get } from 'lodash';

export function handlebarMustache(
  template: string,
  data: Record<string, any>,
): any {
  if (typeof template !== 'string') {
    return template;
  }

  if (!data) {
    return template;
  }

  let returnValue: any;

  template = template.replace(/\{\{([^}]+)\}\}/g, (match: string): string => {
    match = match.slice(2, -2);

    const datum: any = get(data, match);

    if (typeof datum !== 'undefined') {
      returnValue = datum;
    } else {
      returnValue = '{{' + match + '}}';
    }

    return returnValue;
  });

  if (typeof returnValue === 'undefined') {
    returnValue = template;
  }

  return returnValue;
}
