import React from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import { Styles } from '../../models/styles.model';
import { colors, fontFamily, sizes } from '../../themes';

interface Props {
  style?: TextStyle | Array<TextStyle>;
}

const styles: Styles = StyleSheet.create({
  ListItem: {
    color: colors.text,
    fontFamily: fontFamily.semiBold,
  } as TextStyle,
  ListItem1: {
    fontSize: sizes.md,
  } as TextStyle,
  ListItem2: {
    fontSize: sizes.sm18,
  } as TextStyle,
  ListItem3: {
    fontSize: sizes.sm16,
  } as TextStyle,
  ListItem4: {
    fontSize: sizes.sm14,
  } as TextStyle,
  ListItem5: {
    fontSize: sizes.sm,
  } as TextStyle,
});

const ListItem: React.FunctionComponent<Props> = function ListItem(
  props: React.PropsWithChildren<Props>,
  styleKey: string,
): JSX.Element {
  return (
    <Text style={[styles.ListItem, styles[styleKey], props.style]}>
      {props.children}
    </Text>
  );
};

export const ListItem1: React.FunctionComponent<Props> = (props: Props) => {
  return ListItem(props, 'ListItem1');
};

export const ListItem2: React.FunctionComponent<Props> = (props: Props) => {
  return ListItem(props, 'ListItem2');
};

export const ListItem3: React.FunctionComponent<Props> = (props: Props) => {
  return ListItem(props, 'ListItem3');
};

export const ListItem4: React.FunctionComponent<Props> = (props: Props) => {
  return ListItem(props, 'ListItem4');
};

export const ListItem5: React.FunctionComponent<Props> = (props: Props) => {
  return ListItem(props, 'ListItem5');
};
