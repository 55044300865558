import { env } from '../helpers/env';

export async function fetchPublicDetails(subdomain: string): Promise<any> {
  const publicDetailsUrl: string | undefined = env.REACT_APP_PUBLIC_DETAILS_JSON;

  if (publicDetailsUrl) {
    return fetch(
      publicDetailsUrl
        .replace('{{subdomain}}', subdomain)
        .replace('{{environment}}', env.REACT_APP_STAGE),
    ).then((response: Response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    });
  }
}
