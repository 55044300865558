import React from 'react';
import { StyleSheet, Text, TextProps, TextStyle } from 'react-native';

import { Styles } from '../../models/styles.model';
import { colors, fontFamily, sizes } from '../../themes';

type Props = TextProps;

const styles: Styles = StyleSheet.create({
  header: {
    color: colors.text,
    fontFamily: fontFamily.semiBold,
  } as TextStyle,
  header1: {
    fontSize: sizes.xl,
  },
  header2: {
    fontSize: sizes.lg,
  },
  header3: {
    fontSize: sizes.md,
  },
  header3p5: {
    fontSize: sizes.mdPlus,
  },
  header4: {
    fontSize: sizes.sm18,
  },
  header5: {
    fontSize: sizes.sm14,
  },
  header6: {
    color: colors.textLight,
    fontSize: sizes.sm,
  },
});

const Header: React.FunctionComponent<Props> = function Header(
  props: React.PropsWithChildren<Props>,
  styleKey: string,
): JSX.Element {
  return (
    <Text
      {...props}
      style={[styles.header, styles[styleKey], props.style]}
      numberOfLines={2}
      ellipsizeMode={'tail'}
      adjustsFontSizeToFit={true}
      minimumFontScale={0.7}
    >
      {props.children}
    </Text>
  );
};

export const H1: React.FunctionComponent<Props> = (props: Props) => {
  return Header(props, 'header1');
};

export const H2: React.FunctionComponent<Props> = (props: Props) => {
  return Header(props, 'header2');
};

export const H3p5: React.FunctionComponent<Props> = (props: Props) => {
  return Header(props, 'header3p5');
};

export const H3: React.FunctionComponent<Props> = (props: Props) => {
  return Header(props, 'header3');
};

export const H4: React.FunctionComponent<Props> = (props: Props) => {
  return Header(props, 'header4');
};

export const H5: React.FunctionComponent<Props> = (props: Props) => {
  return Header(props, 'header5');
};

export const H6: React.FunctionComponent<Props> = (props: Props) => {
  return Header(props, 'header6');
};
