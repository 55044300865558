/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationProp, useNavigation } from '@react-navigation/core';
import { createStackNavigator } from '@react-navigation/stack';

import { NavigationItem } from '@storyslab/storyslab.common.models';

import ContentViewHeader from '../../../components/headers/ContentViewHeader';
import SearchModal from '../../../components/modals/SearchModal';
import { useWorkspace } from '../../../context/workspace/workspace.context';
import { getViewByViewId } from '../../../helpers/workspace-navigation.helper';
import { ViewsManifestItem } from '../../../models/data';
import ViewerLayout, {
  Props as ViewerLayoutProps,
} from '../../../screens/workspace/Viewer';

import { TemplateNavigationEnum } from '../enums/navigation.enum';
import CV1Layout from '../screens/layouts/Cv1-202010625';
import CV1Modal from '../screens/layouts/Cv1-modal';
import Pd2v20210625Layout from '../screens/layouts/Pd2-20210625';

const Tab: any = createBottomTabNavigator();

type TabNavigationState =
  | Readonly<{
      key: string;
      index: number;
      routeNames: Array<string>;
      history?: Array<unknown> | undefined;
      routes: any;
      type: string;
      stale: false;
    }>
  | undefined;

const Stack: any = createStackNavigator<any>();

export const TemplateNavigation: React.FunctionComponent<
  Record<string, unknown>
> = function TemplateNavigation(): JSX.Element {
  const [workspace] = useWorkspace();
  const navigation: NavigationProp<any> = useNavigation();
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!workspace.templateParams) {
      return;
    }

    const selectedNavigationItem: NavigationItem =
      workspace.templateParams.selectedNavigationItem;

    if (selectedNavigationItem.viewId) {
      const view: ViewsManifestItem<any> | null = getViewByViewId(
        selectedNavigationItem.viewId,
        { workspace },
      );

      navigation.reset({
        index: 0,
        routes: [
          {
            name: view?.layoutKey || '',
            params: {
              config: view?.layoutConfiguration,
            },
          },
        ],
      });

      /*
       * Navigation.navigate(view?.layoutKey || '', {
       *   config: view?.layoutConfiguration,
       * });
       */
    }
  }, [hasLoaded, workspace.templateParams?.selectedNavigationItem]);

  useEffect(() => {
    if (!hasLoaded) {
      setHasLoaded(true);
      return;
    }
  }, []);

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        component={CV1Layout}
        name={TemplateNavigationEnum.CV1V20210709}
      />
      <Stack.Screen
        component={Pd2v20210625Layout}
        name={TemplateNavigationEnum.PD2V20210625}
      />
      <Stack.Screen
        component={CV1Modal}
        options={{
          animationEnabled: false,
          cardStyle: { backgroundColor: 'transparent' },
          presentation: 'transparentModal',
        }}
        name={TemplateNavigationEnum.CV1MODAL}
      />
      <Stack.Screen name={TemplateNavigationEnum.VIEWIER}>
        {(props: ViewerLayoutProps): JSX.Element => (
          <ViewerLayout
            {...props}
            header={<ContentViewHeader params={props.route.params} />}
          />
        )}
      </Stack.Screen>

      <Stack.Screen
        name={TemplateNavigationEnum.VIEWIER_MODAL}
        options={{
          presentation: 'modal',
        }}
      >
        {(props: ViewerLayoutProps): JSX.Element => (
          <ViewerLayout
            {...props}
            header={<ContentViewHeader params={props.route.params} />}
          />
        )}
      </Stack.Screen>

      <Stack.Screen
        name={TemplateNavigationEnum.SEARCH}
        options={{
          presentation: 'transparentModal',
        }}
      >
        {(): JSX.Element => <SearchModal />}
      </Stack.Screen>
    </Stack.Navigator>
  );
};

export default TemplateNavigation;
