/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import { Styles } from '../../../../../models/styles.model';
import { colors, fontFamily, sizes } from '../../../../../themes';

interface Props {}

export const ColumnListEmpty: FunctionComponent<Props> =
  function ColumnListEmpty(props: PropsWithChildren<Props>): JSX.Element {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>No Content in this Collection</Text>
      </View>
    );
  };

const styles: Styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: sizes.xs,
    paddingHorizontal: sizes.xl,
    paddingTop: sizes.md,
  } as ViewStyle,
  text: {
    color: colors.white,
    fontFamily: fontFamily.bold,
    fontSize: sizes.sm18,
    textAlign: 'center',
  } as TextStyle,
});

export default ColumnListEmpty;
