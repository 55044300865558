/* eslint-disable @typescript-eslint/no-empty-interface */

import React from 'react';
import {
  FlatList,
  Insets,
  ListRenderItem,
  PointPropType,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import { Styles } from '../../../../models/styles.model';

export interface Props {
  contentContainerStyle?: ViewStyle;
  contentInset?: Insets;
  contentOffset?: PointPropType;
  data: any;
  flatListRef?: React.Ref<FlatList<any>> | undefined;
  renderItem: ListRenderItem<any>;
  shouldLeftIndent?: boolean;
  style?: ViewStyle | Array<ViewStyle>;
}

const styles: Styles = StyleSheet.create({
  flatList: {
    flexGrow: 0,
  } as ViewStyle,
  indent: {
    // MarginLeft: 100,
  } as ViewStyle,
  innerContainer: {} as ViewStyle,
});

export const HorizontalBase: React.FunctionComponent<Props> =
  function HorizontalBase(props: React.PropsWithChildren<Props>): JSX.Element {
    const {
      contentContainerStyle,
      contentInset,
      contentOffset,
      data,
      flatListRef,
      renderItem,
      shouldLeftIndent,
      style,
    } = props;

    return (
      <FlatList
        ref={flatListRef}
        contentContainerStyle={[
          styles.innerContainer,
          shouldLeftIndent ? styles.indent : {},
          contentContainerStyle,
        ]}
        contentInset={
          contentInset || {
            bottom: 0,
            left: 10,
            right: 10,
            top: 0,
          }
        }
        contentOffset={contentOffset || { x: 0, y: 0 }}
        data={data}
        decelerationRate={'fast'}
        horizontal={true}
        keyExtractor={(item: any): string => String(item.id)}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        /*
         * On the off chance we want to snap these:
         * Hold: snapToAlignment={'center'}
         * Hold: snapToInterval={1200}
         */
        style={[styles.flatList, style]}
      />
    );
  };

export default HorizontalBase;
