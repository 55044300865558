import React from 'react';

import SplitIconButton from '../../components/buttons/SplitIcon';
import { IdentityProvider } from '../../models/workspace.model';

interface Props {
  provider: IdentityProvider;
  onPress?: (provider?: IdentityProvider) => void;
}

export const ProviderLoginButton: React.FunctionComponent<Props> =
  function ProviderLoginButton(
    props: React.PropsWithChildren<Props>,
  ): JSX.Element {
    const { onPress, provider } = props;

    return (
      <SplitIconButton
        iconKey={provider.iconKey}
        iconUrl={provider.iconUrl}
        primaryColor={'#0d69b9'} // Coming soon.
        onPress={(): void => {
          if (typeof onPress === 'function') {
            onPress(provider);
          }
        }}
        title={provider.displayName || ''}
      />
    );
  };

export default ProviderLoginButton;
