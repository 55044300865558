import { useNavigation } from '@react-navigation/core';

import { ContentItem } from '@storyslab/storyslab.common.models';

import { useWorkspace } from '../context/workspace/workspace.context';
import { ComponentTargetTypes } from '../enums/component.enum';
import { getComponentTarget } from '../helpers/component.helper';
import { getViewByViewId } from '../helpers/workspace-navigation.helper';
import { ViewsManifestItem } from '../models/data';
import { CollectionsItem } from '../models/data/collections.model';
import { WorkspaceContextProps } from '../models/workspace.model';
import { TemplateNavigationEnum } from '../templates/default/enums/navigation.enum';

function getNavTarget(
  target: CollectionsItem | null,
  workspace: Partial<WorkspaceContextProps>,
): TemplateNavigationEnum | null {
  const view: ViewsManifestItem<any> | null = getViewByViewId(
    target?.viewId || 0,
    {
      workspace,
    },
  );

  if (view?.layoutKey) {
    return view.layoutKey;
  }

  return null;
}

export function useComponentNavigation(): (
  id: number,
  type: ComponentTargetTypes,
  useModal?: boolean,
) => void {
  const navigation: any = useNavigation();
  const [workspace, setWorkspace] = useWorkspace();

  return async (
    id: number,
    type: ComponentTargetTypes,
    useModal?: boolean,
  ): Promise<void> => {
    const componentTarget: CollectionsItem | ContentItem | null =
      await getComponentTarget(id, type);

    if (!componentTarget) {
      throw new Error(`Target is missing for id: ${id} of type: ${type}`);
    }

    let navTarget: TemplateNavigationEnum | null = null;

    switch (type) {
      case ComponentTargetTypes.COLLECTION:
        navTarget = getNavTarget(componentTarget as any, workspace);

        if (!navTarget) {
          navTarget = TemplateNavigationEnum.CV1MODAL;
          setWorkspace({
            highlightSelectedItem: componentTarget.id,
          });
        }

        break;
      case ComponentTargetTypes.LINK:
      case ComponentTargetTypes.CONTENT_ITEM:
      case ComponentTargetTypes.FILE:
        navTarget = useModal
          ? TemplateNavigationEnum.VIEWIER_MODAL
          : TemplateNavigationEnum.VIEWIER;

        break;
      default:
        throw new Error(`Invalid Type: ${type}`);
    }

    navigation.push(navTarget, componentTarget);
  };
}
