import React from 'react';
import { StyleSheet, Text, TextProps, ViewProps, TouchableOpacity } from 'react-native';

import { Styles } from '../../models/styles.model';
import { fontFamily, sizes } from '../../themes';

interface Props {
  onPress?: () => void;
  style?: TextProps;
  wrapperStyle?: ViewProps;
}

const styles: Styles = StyleSheet.create({
  text: {
    color: '#858585',
    fontFamily: fontFamily.regular,
    fontSize: sizes.sm,
    textAlignVertical: 'center',
  },
});

export const TextButton: React.FunctionComponent<Props> = function TextButton(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  return (
    <TouchableOpacity onPress={props.onPress} style={props.wrapperStyle}>
      <Text style={[styles.text, props.style]}>{props.children}</Text>
    </TouchableOpacity>
  );
};

export default TextButton;
