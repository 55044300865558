import React, { useMemo } from 'react';

import Chrome from '../../components/chrome/Chrome';
import SearchModal from '../../components/modals/SearchModal';
import { useWorkspace } from '../../context/workspace/workspace.context';

import { getTemplate } from '../../templates';

const WorkspaceRootScreen: React.FunctionComponent<
  Record<string, any>
> = () => {
  const [workspace] = useWorkspace();

  return useMemo(() => {
    if (workspace && workspace.templateName) {
      const TemplateRootScreen: React.FunctionComponent = getTemplate(
        workspace.templateName,
      );
      return (
        <>
          {/* <SearchModal
            isVisible={true}
            onRequestClose={(): void => {
              console.log('REQUEST CLOSE');
            }}
          /> */}
          <Chrome>
            <TemplateRootScreen />
          </Chrome>
        </>
      );
    } else {
      return <Chrome />;
    }
  }, [workspace.templateName]);
};

export default WorkspaceRootScreen;
