/* eslint-disable max-lines-per-function */

import React, {
  createRef,
  FunctionComponent,
  PropsWithChildren,
  useState,
} from 'react';
import {
  FlatList,
  LayoutAnimation,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Svg, { Path } from 'react-native-svg';

import TouchableOverlay from '../../../../components/overlay/TouchableOverlay';
import { H3 } from '../../../../components/typography';
import { useWorkspace } from '../../../../context/workspace/workspace.context';
import { ComponentTargetTypes } from '../../../../enums/component.enum';
import { ComponentConfig, ComponentNavigationOnPress } from '../../../../models/data';
import { Styles } from '../../../../models/styles.model';
import { colors, fontFamily } from '../../../../themes';
import { sizes } from '../../../../themes/sizes';

import ComponentConfigWrapper from '../../../../components/util/ComponentConfigWrapper';
import HorizontalBottomTitleScroll from '../scrolls/horizontal-bottom-title';

interface Item {
  hideTitles: boolean;
  id: number;
  image: number;
  key: number;
  text: string;
  textColor: string;
}

interface Props {
  config?: ComponentConfig<ComponentTargetTypes>;
  onPress?: ComponentNavigationOnPress;
  data?: Array<Partial<Item>> | undefined;
}

const menuToggleButtonHeight: number = 94;
const menuHeight: number = 220;

const styles: Styles = StyleSheet.create({
  bottomBorder: {
    bottom: 0,
    height: 10,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
  } as ViewStyle,
  container: {
    flexDirection: 'row',
    zIndex: 1,
  } as ViewStyle,
  menu: {
    height: menuHeight,
    left: 0,
    position: 'absolute',
    top: 10,
    width: '100%',
  } as ViewStyle,
  menuCollapsed: {
    top: -(menuHeight - 10),
  } as ViewStyle,
  menuExpanded: {
    top: 0,
  } as ViewStyle,
  menuToggleButton: {
    alignSelf: 'center',
    overflow: 'hidden',
    paddingBottom: 45,
    position: 'absolute',
    top: menuHeight,
    width: 220,
  } as ViewStyle,
  menuToggleButtonBackground: {
    bottom: -35,
    height: 104,
    overflow: 'hidden',
    position: 'absolute',
    // Top: -25,

    width: 220,
  } as ViewStyle,

  menuToggleButtonBackgroundSolid: {
    height: 94,
    justifyContent: 'flex-end',
    position: 'absolute',
    width: 220,
  } as ViewStyle,

  menuToggleButtonBackgroundTint: {
    height: 94,
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 8,
    width: 220,
  } as ViewStyle,

  menuToggleButtonInner: {
    backgroundColor: '#191919',
    flex: 1,
    paddingVertical: 5,

    width: 220,
  } as ViewStyle,
  menuToggleButtonTitle: {
    color: colors.textWhite,
    fontFamily: fontFamily.bold,
    marginTop: sizes.xs,
    paddingHorizontal: sizes.sm,
    textAlign: 'center',
    width: 220,
    zIndex: 0,
  } as TextStyle,
  menuToggleCollapsed: {
    top: 6,
  } as ViewStyle,
  menuToggleExpanded: {
    top: 6,
  } as ViewStyle,
  scrollView: {
    marginTop: 30,
  } as ViewStyle,
});

export const TopDrawer: FunctionComponent<Props> = function TopDrawer(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { config } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [workspace] = useWorkspace();

  const scrollerRef: React.RefObject<FlatList> = createRef();

  function toggleMenu(): void {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setIsExpanded(!isExpanded);
  }

  return props.config ? (
    <>
      {isExpanded && (
        <TouchableOverlay
          onPress={(): void => toggleMenu()}
          opacity={0.6}
          tintColor={'#000000'}
        />
      )}
      <View style={styles.container}>
        <View
          style={[
            styles.menu,
            isExpanded ? styles.menuExpanded : styles.menuCollapsed,
            config?.props.backgroundColor
              ? { backgroundColor: config?.props.backgroundColor }
              : {},
          ]}
        >
          <HorizontalBottomTitleScroll
            config={props.config}
            data={props.data}
            style={styles.scrollView}
            flatListRef={scrollerRef}
            onPress={props.onPress}
          />

          {/* <ComponentConfigWrapper config={props.config}>
            <HorizontalBottomTitleScroll
              style={styles.scrollView}
              flatListRef={scrollerRef}
              onPress={props.onPress}
            />
          </ComponentConfigWrapper> */}

          <View
            style={[
              styles.bottomBorder,
              { backgroundColor: workspace.styles?.primaryColor },
            ]}
          />

          <TouchableOpacity
            activeOpacity={0.8}
            style={styles.menuToggleButton}
            onPress={(): void => toggleMenu()}
          >
            <View style={styles.menuToggleButtonInner}>
              <View style={styles.menuToggleButtonBackground}>
                <Svg
                  style={[styles.menuToggleButtonBackgroundTint]}
                  width="220"
                  height="94"
                  fill={workspace.styles?.primaryColor}
                  viewBox="0 0 376.06 161.31"
                >
                  <Path
                    d="M376.06,0v107.47c0,7.12-5.83,12.95-12.95,12.95l-167.69,39.75c-6.49,1.54-13.26,1.52-19.74-0.06L12.95,120.42
    C5.83,120.42,0,114.59,0,107.47V0H376.06z"
                  />
                </Svg>
                <Svg
                  style={styles.menuToggleButtonBackgroundSolid}
                  width="220"
                  height="94"
                  fill="#1A1A1A"
                  viewBox="0 0 376.06 161.31"
                >
                  <Path
                    d="M376.06,0v107.47c0,7.12-5.83,12.95-12.95,12.95l-167.69,39.75c-6.49,1.54-13.26,1.52-19.74-0.06L12.95,120.42
    C5.83,120.42,0,114.59,0,107.47V0H376.06z"
                  />
                </Svg>
              </View>

              {config?.props.title && (
                <H3 style={styles.menuToggleButtonTitle}>
                  {config.props.title}
                </H3>
              )}
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </>
  ) : (
    <></>
  );
};

export default TopDrawer;
