import React from 'react';
import { SafeAreaView, StatusBar, StyleSheet, ViewStyle, View, Text } from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import { Styles } from './models/styles.model';

import DomainProvider from './context/domain/domain.context';
import DomainNavigation from './navigation/Domain';

library.add(fal);
library.add(fas);

const styles: Styles = StyleSheet.create({
  safeArea: { backgroundColor: '#000000', flex: 1 } as ViewStyle,
});

const App: React.FunctionComponent<null> = () => {

  return (
    <SafeAreaView style={styles.safeArea}>
      {/* https://pspdfkit.com/guides/ios/faq/view-controller-based-status-bar-appearance/ */}
      <StatusBar barStyle={'light-content'} />
      <DomainProvider>
        <MenuProvider>
          <DomainNavigation />
        </MenuProvider>
      </DomainProvider>
    </SafeAreaView>
  );
};

export default App;
