/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import { useChromeActions } from '../../../context/workspace/chrome.context';
import { Styles } from '../../../models/styles.model';
import { colors } from '../../../themes';

import { ChromeInternalMenuTypes } from '../../../enums/navigation.enum';

import DrawerMenuInternal from './DrawerMenuInternal';

interface Props {}

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  } as ViewStyle,
});

export const StorySlabMenu: FunctionComponent<Props> = function StorySlabMenu(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { isInternalMenuItemSelected } = useChromeActions();

  const {
    handleMyFilesTap,
    handleSearchTap,
    handleSharePlusTap,
    handleVideoTap,
  } = useChromeActions();

  return (
    <DrawerMenuInternal
      iconColor={colors.textDark}
      selectedColor={colors.textDark}
      items={[
        // {
        //   icon: 'file-user',
        //   isSelected: isInternalMenuItemSelected(
        //     ChromeInternalMenuTypes.MyFiles,
        //   ),
        //   onPress: (): void => handleMyFilesTap(),
        //   title: 'My Files',
        // },
        {
          icon: 'search',
          isSelected: isInternalMenuItemSelected(
            ChromeInternalMenuTypes.Search,
          ),
          onPress: (): void => handleSearchTap(),
          title: 'Search',
        },
        {
          icon: 'share-square',
          isSelected: isInternalMenuItemSelected(
            ChromeInternalMenuTypes.SharePlus,
          ),
          onPress: (): void => handleSharePlusTap(),
          title: 'Share+',
        },
        // {
        //   icon: 'video',
        //   isSelected: isInternalMenuItemSelected(ChromeInternalMenuTypes.Video),
        //   onPress: (): void => handleVideoTap(),
        //   title: 'Video',
        // },
      ]}
    />
  );
};

export default StorySlabMenu;
