import { ContentTypes } from '../models/content.model';

export function selectViewerType(uri: string): ContentTypes {
  const fileExtension: string | null = uri.split('.').pop() || null;

  switch (fileExtension?.toLowerCase()) {
    case 'pdf':
      return ContentTypes.PDF;

    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'tiff':
    case 'tif':
      return ContentTypes.IMAGE;

    case 'mp4':
      return ContentTypes.VIDEO;

    default:
      return ContentTypes.MISC;
      break;
  }
}
