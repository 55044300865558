/* eslint-disable @typescript-eslint/no-empty-interface */

import React from 'react';
import { StyleSheet, View } from 'react-native';

import MenuDrawer from '../../components/drawers/ChromeMenu';
import ChromeContextProvider from '../../context/workspace/chrome.context';
import FeaturesContextProvider from '../../context/workspace/features.context';
import { Styles } from '../../models/styles.model';

interface Props {}

const styles: Styles = StyleSheet.create({
  container: {
    backgroundColor: '#3c3c3c',
    flex: 1,
    flexDirection: 'row',
  },
});

export const Chrome: React.FunctionComponent<Props> = function Chrome(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  return (
    <ChromeContextProvider>
      <FeaturesContextProvider>
        <View style={styles.container}>
          {props.children}
          <MenuDrawer />
        </View>
      </FeaturesContextProvider>
    </ChromeContextProvider>
  );
};

export default Chrome;
