import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';

import Icon from '../../components/icons/Icon';
import { FontAwesomeLibraries } from '../../enums/font-awesome.enum';
import { Styles } from '../../models/styles.model';
import { colors, sizes } from '../../themes';

interface Props {
  accessibilityLabel: string;
  backgroundColor?: string;
  backgroundColorActive?: string;
  backgroundColorDisabled?: string;
  icon?: IconName;
  iconLibrary?: FontAwesomeLibraries;
  iconSize?: number;
  iconStyle?: FontAwesomeIconStyle;
  isDisabled?: boolean;
  isLoading?: boolean;
  onPress?: (params?: unknown) => void;
  style?: ViewStyle | Array<ViewStyle>;
  title?: string;
  titleColor?: string;
  titleColorActive?: string;
  titleColorDisabled?: string;
  textStyle?: TextStyle;
}

const styles: Styles = StyleSheet.create({
  activityIndicator: {
    marginRight: sizes.sm,
  } as ViewStyle,
  button: {
    alignItems: 'center',
    borderRadius: sizes.lg,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: sizes.xl,
    paddingVertical: sizes.xs,
  } as ViewStyle,
  buttonWithIcon: {
    alignItems: 'center',
    borderRadius: sizes.lg,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: sizes.mdPlus,
    paddingVertical: sizes.xs,
  } as ViewStyle,
  icon: {
    color: colors.textLight,
    marginRight: sizes.xs,
  } as FontAwesomeIconStyle,
  title: {
    color: colors.textLight,
    textAlign: 'center',
  } as TextStyle,
});

const ButtonRounded: React.FunctionComponent<Props> = function Row(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  const {
    isDisabled,
    icon,
    iconStyle,
    iconSize,
    iconLibrary,
    isLoading,
    onPress,
    textStyle,
    title,
    titleColor,
  } = props;
  return (
    <TouchableOpacity
      disabled={isDisabled}
      onPress={onPress}
      style={[
        icon ? styles.buttonWithIcon : styles.button,
        { backgroundColor: props.backgroundColor },
        props.style,
      ]}
    >
      {isLoading && (
        <ActivityIndicator
          size="small"
          color="#ffffff"
          style={styles.activityIndicator}
        />
      )}
      {icon && (
        <Icon
          color={titleColor}
          icon={icon}
          size={iconSize}
          style={[styles.icon, iconStyle]}
          {...(iconLibrary ? { library: iconLibrary } : {})}
        />
      )}
      {title ? (
        <Text
          style={[styles.title, textStyle, { color: props.titleColor }]}
          accessibilityLabel={props.accessibilityLabel}
        >
          {title}
        </Text>
      ) : (
        props.children
      )}
    </TouchableOpacity>
  );
};

export default ButtonRounded;
