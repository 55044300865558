import React from 'react';
import {
  ListRenderItemInfo,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';
import { format } from 'date-fns';

import Icon from '../../components/icons/Icon';
import { getDefaultIconForItem } from '../../helpers/icon.helper';
import { ComponentTarget } from '../../models/data/component.model';
import { Styles } from '../../models/styles.model';
import { colors } from '../../themes';
import { sizes } from '../../themes/sizes';

interface CustomListRenderItemInfo extends ListRenderItemInfo<ComponentTarget> {
  handleOnPress: (item: any) => void;
}

type CustomListRenderItem = (
  info: CustomListRenderItemInfo,
) => React.ReactElement;

export const SearchTableItem: CustomListRenderItem = function SearchTableItem(
  props: CustomListRenderItemInfo,
): JSX.Element {
  const { item, index, handleOnPress } = props;

  return (
    <TouchableOpacity
      onPress={(): void => {
        if (handleOnPress) {
          handleOnPress(item);
        }
      }}
    >
      <View
        style={[
          styles.row,
          index && index % 2 ? styles.rowEven : styles.rowOdd,
        ]}
      >
        <View style={styles.columnLeft}>
          <Icon
            color={colors.darkGray}
            icon={getDefaultIconForItem(item as any) as IconName}
            size={24}
            style={styles.baseIcon}
          />
          <Text style={[styles.baseText, styles.name]}>{item?.name}</Text>
        </View>
        <View style={styles.columnRight}>
          <Text style={[styles.baseText, styles.date]}>
            {format(new Date(item?.updated || ''), 'MMM d, yyyy h:ma')}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles: Styles = StyleSheet.create({
  baseIcon: { color: colors.gray } as FontAwesomeIconStyle,
  baseText: { color: colors.gray } as TextStyle,
  columnLeft: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  } as ViewStyle,
  columnRight: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '20%',
  } as ViewStyle,
  date: {
    fontSize: sizes.sm,
    fontWeight: 'bold',
    marginRight: sizes.sm,
    textAlign: 'left',
  } as TextStyle,
  ellipsisTouch: {
    alignSelf: 'flex-end',
  } as ViewStyle,
  grip: {
    marginHorizontal: sizes.sm,
  } as FontAwesomeIconStyle,
  index: {
    color: colors.darkGray,
    fontWeight: 'bold',
  } as TextStyle,

  name: {
    fontSize: sizes.sm,
    fontWeight: 'bold',
    marginLeft: sizes.sm,
  } as TextStyle,

  row: {
    alignItems: 'center',
    borderRadius: sizes.sm,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: sizes.md,
    width: '100%',
  } as ViewStyle,
  rowEven: {} as ViewStyle,
  rowOdd: { backgroundColor: colors.backgroundGray } as ViewStyle,
  typeIcon: {} as FontAwesomeIconStyle,
});

export default SearchTableItem;
