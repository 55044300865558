import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useWorkspace } from '../../context/workspace/workspace.context';
import { ComponentTargetTypes } from '../../enums/component.enum';
import {
  generateComponentConfig,
  mapChildrenWithConfig,
  resolveAllChildren,
} from '../../helpers/component.helper';
import { ComponentConfig } from '../../models/data';
import {
  CollectionItemChild,
  CollectionsItem,
} from '../../models/data/collections.model';

interface Props {
  config: ComponentConfig<ComponentTargetTypes>;
}

function shouldShowComponent(
  componentConfig: ComponentConfig<CollectionsItem> | undefined,
  componentChildData: Array<CollectionsItem> | undefined,
): boolean {
  if (componentConfig) {
    if (!componentConfig.visibility) {
      return false;
    }

    if (componentConfig.children) {
      if (componentChildData) {
        return true;
      }
    } else {
      return true;
    }
  }

  return false;
}

export const ComponentConfigWrapper: FunctionComponent<Props> =
  function ComponentConfigWrapper(
    props: PropsWithChildren<Props>,
  ): JSX.Element {
    const [componentConfig, setComponentConfig] =
      useState<ComponentConfig<CollectionsItem>>();
    const [componentChildData, setComponentChildData] =
      useState<Array<CollectionsItem>>();
    const [workspace] = useWorkspace();

    useEffect(() => {
      if (props.config) {
        generateComponentConfig({ config: props.config, workspace }).then(
          (config: ComponentConfig<CollectionsItem>) => {
            setComponentConfig(config);
          },
        );
      }
    }, [props.config]);

    useEffect(() => {
      if (componentConfig?.target.children) {
        const children: Array<CollectionItemChild> =
          componentConfig.target.children;

        resolveAllChildren({
          children,
        })
          .then((resolvedChildren: Array<CollectionsItem>) => {
            return mapChildrenWithConfig<CollectionsItem>({
              children: resolvedChildren,
              componentConfig,
              props: {
                id: '{{item.id}}',
                key: '{{item.id}}_key',
                type: '{{item.type}}',
              },
            });
          })
          .then((resolvedChildren: Array<CollectionsItem>) => {
            setComponentChildData(resolvedChildren);
          });
      }
    }, [componentConfig?.target.children]);

    return useMemo(() => {
      return shouldShowComponent(componentConfig, componentChildData) ? (
        <>
          {React.Children.map(props.children, (child: ReactNode) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                // ...props,
                config: componentConfig,
                ...(componentConfig?.target.children && {
                  data: componentChildData,
                }),
              });
            }
            return child;
          })}
        </>
      ) : (
        <></>
      );
    }, [props.children, componentConfig, componentChildData]);
  };

export default ComponentConfigWrapper;
