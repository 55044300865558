export enum WorkspaceSyncState {
  NONE = 0, // Sync has never occurred - Force Sync Window
  META = 1, // Sync Meta data
  CLEANUP = 2, // Sync Meta data
  PENDING = 3,
  PRIORITY_ASSETS = 4, // Syncing assets defined as priority
  ASSETS = 5, // Syncing other assets
  SHARE_PLUS = 6, // Syncing Share Plus
  ANALYTICS = 7, // Syncing Analytics
  COMPLETE = 90, // Sync Complete
  FAILED = 100, // Failure
}

export const WorkspaceSyncStateLookup: {
  [key in WorkspaceSyncState]: keyof typeof WorkspaceSyncState;
} = {
  0: 'NONE',
  1: 'META',
  100: 'FAILED',
  2: 'CLEANUP',
  3: 'PENDING',
  4: 'PRIORITY_ASSETS',
  5: 'ASSETS',
  6: 'SHARE_PLUS',
  7: 'ANALYTICS',
  90: 'COMPLETE',
};

export enum SyncJobTypes {
  DOWNLOAD = 'download',
  PROCESS = 'process',
}

export enum SyncJobStatus {
  CACHED = 'cached',
  COMPLETE = 'complete',
  DOWNLOADED = 'downloaded',
  DOWNLOADING = 'downloading',
  PROCESSED = 'processed',
  PROCESSING = 'processing',
}
