/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { LayoutAnimation, Platform, UIManager } from 'react-native';
import { NavigationProp } from '@react-navigation/native';

import { NavigationItem } from '@storyslab/storyslab.common.models';

import { ChromeContextProps } from '../models/chrome.model';
import { ContextI } from '../models/context.model';
import { TemplateNavigationEnum } from '../templates/default/enums/navigation.enum';

import { ChromeInternalMenuTypes } from '../enums/navigation.enum';

type ChromeContextI = ContextI<ChromeContextProps>;

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export function chromeActions(
  context: ChromeContextI,
  workspaceActions: {
    logout: () => void;
    setSelectedNavigationItem: (params: NavigationItem | null) => void;
  },
  navigation?: NavigationProp<any>,
): any {
  const [chromeContext, setChromeContext] = context;
  const { logout, setSelectedNavigationItem } = workspaceActions;

  function handleSelectDynamicMenuItem({
    navigationItem,
  }: {
    navigationItem: NavigationItem | null;
  }): void {
    if (!navigationItem) {
      setChromeContext(() => {
        return {
          selectedDynamicMenuItem: null,
        };
      });

      setSelectedNavigationItem(null);
      return;
    }

    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      if (prevValue.selectedDynamicMenuItem !== navigationItem.id) {
        setSelectedNavigationItem(navigationItem);

        return {
          selectedDynamicMenuItem: navigationItem.id || null,
        };
      }

      return prevValue;
    });
  }

  function handleSelectInternalMenuItem(
    params: {
      id: ChromeInternalMenuTypes | null;
    } | null,
  ): void {
    if (!params) {
      hideSubPanelMenu();
      setChromeContext(() => {
        return {
          selectedInternalMenuItem: null,
        };
      });

      return;
    }

    const { id } = params;

    if (id === ChromeInternalMenuTypes.Search) {
      hideMainChromeDrawer();
      navigation?.navigate(TemplateNavigationEnum.SEARCH);
      return;
    }

    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      if (prevValue.selectedInternalMenuItem !== id) {
        return {
          selectedInternalMenuItem: id,
        };
      }

      return prevValue;
    });
  }

  function toggleSharePlusSelect(): void {
    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      return {
        ...prevValue,
        sharePlusSelect: !prevValue.sharePlusSelect,
      };
    });
  }

  function handleMyFilesTap(): void {
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.MyFiles,
    });
  }

  function handleSearchTap(): void {
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.Search,
    });
  }

  function handleSharePlusTap(): void {
    setSelectedMenuColumn(1);
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.SharePlus,
    });
  }

  function handleSharePlusCurrentTap(): void {
    showSubPanelMenu();
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.SharePlusCurrent,
    });
  }

  function handleSharePlusSavedTap(): void {
    showSubPanelMenu();
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.SharePlusSaved,
    });
  }
  function handleSharePlusSharedTap(): void {
    showSubPanelMenu();
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.SharePlusShared,
    });
  }
  function handleSharePlusWaitingTap(): void {
    showSubPanelMenu();
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.SharePlusPending,
    });
  }
  function handleVideoTap(): void {
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.Video,
    });
  }

  function handleHelpTap(): void {
    showSubPanelMenu();
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.Help,
    });
  }

  function handleSettingsTap(): void {
    logout();
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.Settings,
    });
  }

  function handleNotificationsTap(): void {
    handleSelectInternalMenuItem({
      id: ChromeInternalMenuTypes.Notifications,
    });
  }

  function handleBackToRootMenuColumn(): void {
    setSelectedMenuColumn(0);
    handleSelectInternalMenuItem(null);
  }

  function isInternalMenuItemSelected(item: string): boolean {
    return item === chromeContext.selectedInternalMenuItem;
  }

  function showSubPanelMenu(): void {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      return {
        ...prevValue,
        isSubMenuPanelExpanded: true,
      };
    });
  }

  function toggleSubPanelMenu(): void {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      return {
        ...prevValue,
        isSubMenuPanelExpanded: !prevValue.isSubMenuPanelExpanded,
      };
    });
  }

  function hideSubPanelMenu(): void {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      return {
        ...prevValue,
        isSubMenuPanelExpanded: false,
      };
    });
  }

  function resetMenu(): void {
    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      return {
        ...prevValue,
        isMainChromeDrawerExpanded: false,
        selectedDynamicMenuItem: null,
        selectedInternalMenuItem: null,
      };
    });
  }

  function setSelectedMenuColumn(index: number): void {
    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      return {
        ...prevValue,
        selectedMenuColumn: index,
      };
    });
  }

  function showMainChromeDrawer(): void {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

    setChromeContext({
      isMainChromeDrawerExpanded: true,
    });
  }

  function hideMainChromeDrawer(): void {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

    setChromeContext({
      isMainChromeDrawerExpanded: false,
      isSubMenuPanelExpanded: false,
      selectedInternalMenuItem: null,
    });
  }

  function toggleMainChromeDrawer(): void {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

    setChromeContext({
      isMainChromeDrawerExpanded: true,
    });

    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      if (prevValue.isMainChromeDrawerExpanded) {
        return {
          isMainChromeDrawerExpanded: false,
          isSubMenuPanelExpanded: false,
        };
      } else {
        return { isMainChromeDrawerExpanded: true };
      }
    });
  }

  function toggleCollectionViewHeaderMenu(): void {
    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      return {
        isCollectionViewHeaderMenuVisible:
          !prevValue.isCollectionViewHeaderMenuVisible,
      };
    });
  }

  function showCollectionViewHeaderMenu(): void {
    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      return {
        ...prevValue,
        isCollectionViewHeaderMenu: true,
      };
    });
  }

  function hideCollectionViewHeaderMenu(): void {
    setChromeContext((prevValue: Partial<ChromeContextProps>) => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      return {
        ...prevValue,
        isCollectionViewHeaderMenu: false,
      };
    });
  }

  function setDynamicMenuItems(navigationItems: Array<NavigationItem>): void {
    if (navigationItems) {
      setChromeContext((prevValue: Partial<ChromeContextProps>) => {
        return {
          dynamicMenuItems: Object.values(navigationItems)
            .sort((a: NavigationItem, b: NavigationItem) => {
              if (a.navOrder && b.navOrder) {
                return a.navOrder - b.navOrder;
              }

              return -1;
            })
            .map((navigationItem: NavigationItem, index: number) => {
              // No menu item selected .. select first one
              if (!prevValue.selectedDynamicMenuItem && index === 0) {
                console.log('No menu item selected .. select first one');
                handleSelectDynamicMenuItem({
                  navigationItem: navigationItem,
                });
              }

              return {
                icon: navigationItem.icon,
                navigationItem: navigationItem,
                onPress: (): void => {
                  handleSelectDynamicMenuItem({
                    navigationItem: navigationItem,
                  });
                },

                title: navigationItem.name,
              };
            }),
        };
      });
    } else {
      console.log('There are no nav items');
    }
  }

  function showChromeTab(): void {
    setChromeContext({ isChromeTabVisible: true });
  }

  function hideChromeTab(): void {
    setChromeContext({ isChromeTabVisible: false });
  }

  return {
    handleBackToRootMenuColumn,
    handleHelpTap,
    handleMyFilesTap,
    handleNotificationsTap,
    handleSearchTap,
    handleSettingsTap,
    handleSharePlusTap,
    handleVideoTap,
    hideChromeTab,
    hideCollectionViewHeaderMenu,
    hideMainChromeDrawer,
    hideSubPanelMenu,
    isInternalMenuItemSelected,
    resetMenu,
    setDynamicMenuItems,
    setSelectedMenuColumn,
    sharePlus: {
      handleSharePlusCurrentTap,
      handleSharePlusSavedTap,
      handleSharePlusSharedTap,
      handleSharePlusWaitingTap,
    },
    showChromeTab,
    showCollectionViewHeaderMenu,
    showMainChromeDrawer,
    toggleCollectionViewHeaderMenu,
    toggleMainChromeDrawer,
    toggleSharePlusSelect,
  };
}
