/* eslint-disable sort-keys */
export const sizes = {
  tny: 2,
  xxs: 4,
  xs: 8,
  sm: 12,
  sm14: 14,
  sm16: 16,
  sm18: 18,
  md: 20,
  mdPlus: 26,
  lg: 32,
  xl: 52,
  xxl: 84,
};

export const generics = {
  layoutHeader: 62,
};
