import React from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import { Styles } from '../../models/styles.model';
import { colors, fontFamily, sizes } from '../../themes';

interface Props {
  style?: TextStyle;
}

const styles: Styles = StyleSheet.create({
  paragraph: {
    color: colors.text,
    fontFamily: fontFamily.regular,
    fontSize: sizes.sm14,
  },
});

export const Paragraph: React.FunctionComponent<Props> = function Paragraph(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  return <Text style={[styles.paragraph, props.style]}>{props.children}</Text>;
};
