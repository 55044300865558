export const defaultLayoutConfig: any = {
  components: [
    {
      description:
        'Company logo to float above background in bottom right of the collection view',
      key: 'logo',
      name: 'Collection View Logo',
      previewS3key:
        'global/view-layout-defs/collection/cv1/assets/cv1-logo.svg',
      props: [
        {
          description:
            'What logo image should be used in the bottom right of the collection view?',
          key: 'defaultLogoAssetId',
          name: 'Default Logo Image',
          type: 'asset',
          value: null,
        },
      ],
      target: '{{inherit.brandAssetId}}',
      targetType: 'asset',
      toggleable: true,
      visibility: true,
    },
    {
      description:
        'Full screen background that appears behind cards, collection images, and logo',
      key: 'background',
      name: 'Collection View Background',
      previewS3key:
        'global/view-layout-defs/collection/cv1/assets/cv1-background.svg',
      props: [
        {
          description:
            'What asset should be used as the default background image?',
          key: 'defaultBackgroundAsset',
          name: 'Default Background Image',
          type: 'asset',
          value: null,
        },
        {
          key: 'backgroundFit',
          name: 'Background Image Scaling Mode',
          type: 'string',
          value: 'AspectFill',
        },
        {
          description:
            'What background color should be used if a background image is not available?',
          key: 'defaultBackgroundColor',
          name: 'Default Background Color (Fallback)',
          type: 'color',
          value: '#4d4d4d',
        },
      ],
      target: '{{inherit.backgroundAssetId}}',
      targetType: 'asset',
      toggleable: false,
      visibility: true,
    },
    {
      description: 'Main collection image',
      key: 'hero',
      name: 'Collection Hero Image',
      previewS3key:
        'global/view-layout-defs/collection/cv1/assets/cv1-hero.svg',
      props: [
        {
          description:
            'What image should be used if a collection image is not available?',
          key: 'defaultHeroAssetId',
          name: 'Default Collection Image',
          type: 'asset',
          value: null,
        },
      ],
      target: '{{inherit.imageAssetId}}',
      targetType: 'asset',
      toggleable: true,
      visibility: true,
    },
    {
      description: 'Main collection Image',
      itemProps: [
        {
          key: 'titleColor',
          name: 'Card Title Color',
          type: 'color',
          value: '#ffffff',
        },
        {
          key: 'subtitleColor',
          name: 'Card Subtitle Color',
          type: 'color',
          value: '#eeeeee',
        },
        {
          key: 'maxTitleLines',
          name: 'Max Title Lines',
          type: 'integer',
          value: '2',
        },
      ],
      key: 'card',
      name: 'Collection Contents Card',
      previewS3key:
        'global/view-layout-defs/collection/cv1/assets/cv1-card.svg',
      props: [
        {
          key: 'backgroundColor',
          name: 'Card Background Color',
          type: 'color',
          value: '#d64747',
        },
      ],
      target: null,
      targetType: null,
      toggleable: false,
      visibility: true,
    },
  ],
  options: [],
  previewS3key: 'global/view-layout-defs/collection/cv1/assets/cv1-base.svg',
  viewType: 'collection',
};
