import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import CollectionViewHeaderSharePlus from '../../components/menus/chrome/CollectionViewHeaderSharePlus';
import OptionsMenu from '../../components/menus/options/OptionsMenu';
import {
  useChromeActions,
  useChromeContext,
} from '../../context/workspace/chrome.context';
import { useWorkspace } from '../../context/workspace/workspace.context';
import { FontAwesomeLibraries } from '../../enums/font-awesome.enum';
import { Styles } from '../../models/styles.model';

interface Props {
  toggleIsMenuVisible?: () => void;
}

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  } as ViewStyle,
});

export const CollectionViewHeaderRightColumn: FunctionComponent<Props> =
  function CollectionViewHeaderRightColumn(
    props: PropsWithChildren<Props>,
  ): JSX.Element {
    const [workspace] = useWorkspace();
    const [chromeContext] = useChromeContext();
    const { toggleSharePlusSelect } = useChromeActions();

    if (chromeContext.sharePlusSelect) {
      return <CollectionViewHeaderSharePlus />;
    }

    return (
      <>
        <OptionsMenu
          triggerIcon={'list'}
          triggerIconSize={32}
          triggerIconColor={workspace.styles?.primaryColor}
          triggerIconStyle={styles.homeIcon}
          options={[
            {
              icon: 'paper-plane',
              iconLibrary: chromeContext.sharePlusSelect
                ? FontAwesomeLibraries.FAS
                : FontAwesomeLibraries.FAL,

              onSelect: (): void => {
                toggleSharePlusSelect();
              },
              title: 'Add / Remove Share',
            },
          ]}
        />
      </>
    );
  };

export default CollectionViewHeaderRightColumn;
