import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';

import Loading from '../../components/util/Loading';
import { useDomain } from '../../context/domain/domain.context';
import { DomainStackParamList } from '../../models/domain.model';
import { Styles } from '../../models/styles.model';
import { fetchPublicDetails } from '../../services/bootstrap.service';

import { Errors } from '../../enums/errors.enum';
import { DomainNavigationEnum } from '../../enums/navigation.enum';

const styles: Styles = StyleSheet.create({
  loading: {
    width: 160,
  },
  loadingContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});

interface Props
  extends StackScreenProps<
    DomainStackParamList,
    DomainNavigationEnum.LoadingScreen
  > {
  handleError: (error: string) => void;
}

const LoadingScreen: React.FunctionComponent<Props> = function LoadingScreen(
  props: Props,
): JSX.Element {
  const [, setDomainContext] = useDomain();

  useEffect(() => {
    if (props.route.params.appCode) {
      fetchPublicDetails(props.route.params.appCode)
        .then((response: any) => {
          setDomainContext({
            applicationId: response.applicationId,
            tenantId: null
          });
          return response;
        })
        .then((response: any) => {
          if (response.applicationId) {
            props.navigation.replace(
              DomainNavigationEnum.WorkspaceNavigator,
              response,
            );
          } else {
            throw response;
          }
        })
        .catch((err: Error) => {
          console.log(err);

          props.navigation.navigate(DomainNavigationEnum.AppCodeScreen, {
            error: Errors.APP_CODE_DOES_NOT_EXIST,
          });
        });
    }
  }, [props.route.params.appCode]);

  return (
    <>
      <View style={styles.loadingContainer}>
        <Loading style={styles.loading} />
      </View>
    </>
  );
};

export default LoadingScreen;
