/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';

import { Styles } from '../../models/styles.model';

interface Props {
  onPress?: () => void;
  opacity?: number;
  tintColor?: string;
  zIndex?: number;
}

const styles: Styles = StyleSheet.create({
  background: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  } as ViewStyle,
  backgroundTouchable: {
    height: '100%',
    width: '100%',
  } as ViewStyle,
});

export const TouchableOverlay: React.FunctionComponent<Props> =
  function TouchableOverlay(
    props: React.PropsWithChildren<Props>,
  ): JSX.Element {
    const { onPress, opacity, tintColor, zIndex } = props;

    return (
      <View
        style={[
          styles.background,
          { opacity: opacity },
          { zIndex: typeof zIndex === 'undefined' ? 1 : zIndex },
          tintColor ? { backgroundColor: tintColor } : {},
        ]}
      >
        <TouchableWithoutFeedback onPress={onPress}>
          <View style={[styles.backgroundTouchable]} />
        </TouchableWithoutFeedback>
      </View>
    );
  };

export default TouchableOverlay;
