/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */

import { ContextI } from 'models/context.model';
import { FeaturesContextProps } from 'models/features.model';
import { SharePlusBundleDb } from 'models/share-plus.model';
import { WorkspaceContextProps } from 'models/workspace.model';
import { PromiseResponse } from 'models/promise.model';

import { ColumnListItem } from '../../models/column-list.model';

type FeaturesContextI = ContextI<FeaturesContextProps>;

interface SharePlusActionsExport {
  addSharePlusItem: (item: ColumnListItem | undefined) => void;
  clearCurrentBundle: () => void;
  hasItems: () => boolean;
  handleSharePlusSendBundleRequestById: (
    id: number,
    workspaceContext: WorkspaceContextProps,
  ) => PromiseResponse<boolean>;
  handleSharePlusSendCurrentBundleRequest: (
    workspaceContext: WorkspaceContextProps,
  ) => PromiseResponse<boolean>;
  isSharePlusItemSelected: (item: ColumnListItem | undefined) => void;
  moveBundleToInProgress: (id: number) => void;
  refreshContextWithCurrentBundle: () => void;
  removeSharePlusItem: (item: ColumnListItem | undefined) => void;
  renderItemCount: () => string;
  saveCurrentBundleAsDraft: () => void;
  handleClearBundleSuccess: () => void;
  handleSetBundleSuccess: (id: number) => void;
}

export function sharePlusActions(
  context: FeaturesContextI,
): SharePlusActionsExport {
  const [featuresContext, setFeaturesContext] = context;

  function handleClearBundleSuccess(): void {
    setFeaturesContext({
      sharePlusSuccessPayload: null,
    });
  }

  function handleSetBundleSuccess(id: number): void {
    setFeaturesContext({
      sharePlusSuccessPayload: { id: id },
    });
  }

  async function handleSharePlusSendCurrentBundleRequest(
    workspaceContext: WorkspaceContextProps,
  ): PromiseResponse<boolean> {
      return [null, null];
  }

  async function handleSharePlusSendBundleRequestById(
    id: number,
    workspaceContext: WorkspaceContextProps,
  ): PromiseResponse<boolean> {
    return [false, null];
  }

  async function addSharePlusItem(
    item: ColumnListItem | undefined,
  ): PromiseResponse<any> {
    return Promise.resolve([null, 'unsupported']);
  }

  async function removeSharePlusItem(
    item: ColumnListItem | undefined,
  ): PromiseResponse<any> {
    return Promise.resolve([null, 'unsupported']);
  }

  async function clearCurrentBundle(): PromiseResponse<any> {
    return Promise.resolve([false, null]);
  }

  async function saveCurrentBundleAsDraft(): PromiseResponse<boolean> {
    return Promise.resolve([false, null]);
  }

  async function moveBundleToInProgress(id: number): PromiseResponse<number> {
    return [null, null];
  }

  async function refreshContextWithCurrentBundle(): PromiseResponse<SharePlusBundleDb> {
    return [null, null];
  }

  function clearCurrentBundleInContext(): void {
  }

  function isSharePlusItemSelected(item: ColumnListItem | undefined): boolean {
    return false;
  }

  function hasItems(): boolean {
    return false
  }

  function renderItemCount(): string {
    return '';
  }

  return {
    addSharePlusItem,
    clearCurrentBundle,
    handleClearBundleSuccess,
    handleSetBundleSuccess,
    handleSharePlusSendBundleRequestById,
    handleSharePlusSendCurrentBundleRequest,
    hasItems,
    isSharePlusItemSelected,
    moveBundleToInProgress,
    refreshContextWithCurrentBundle,
    removeSharePlusItem,
    renderItemCount,
    saveCurrentBundleAsDraft,
  };
}