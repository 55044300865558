/* eslint-disable max-lines-per-function */
import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from 'react-native-popup-menu';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';

import Icon from '../../../components/icons/Icon';
import { FontAwesomeLibraries } from '../../../enums/font-awesome.enum';
import { Styles } from '../../../models/styles.model';
import { colors, sizes } from '../../../themes';

interface OptionProps {
  icon?: IconName;
  iconColor?: string;
  iconSize?: number;
  iconStyle?: FontAwesomeIconStyle;
  iconLibrary?: FontAwesomeLibraries;
  textStyle?: TextStyle | Array<TextStyle>;
  title?: string;
}

interface OptionPropsSingle extends OptionProps {
  onSelect: () => void;
}

interface Props {
  isTop?: boolean;
  options: Array<OptionPropsSingle>;
  optionProps?: OptionProps;
  trigger?: JSX.Element;
  triggerIcon?: IconName;
  triggerIconColor?: string;
  triggerIconLibrary?: FontAwesomeLibraries;
  triggerIconSize?: number;
  triggerIconStyle?: FontAwesomeIconStyle;
}

export const OptionsMenu: FunctionComponent<Props> = function OptionsMenu(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const {
    isTop,
    options,
    optionProps,
    trigger,
    triggerIcon,
    triggerIconLibrary,
    triggerIconColor,
    triggerIconSize,
    triggerIconStyle,
  } = props;

  return (
    <Menu>
      <MenuTrigger
        customStyles={{
          TriggerTouchableComponent: TouchableOpacity,
        }}
      >
        {trigger ? (
          trigger
        ) : (
          <Icon
            color={triggerIconColor || colors.gray}
            icon={triggerIcon || 'ellipsis-v'}
            size={triggerIconSize || 24}
            style={[styles.baseIcon, styles.ellipsesTouch, triggerIconStyle]}
            library={triggerIconLibrary || FontAwesomeLibraries.FAL}
          />
        )}
      </MenuTrigger>
      <MenuOptions
        customStyles={{
          optionWrapper: styles.menuOptionWrapper,
          optionsContainer: isTop
            ? styles.menuOptionsWrapperTop
            : styles.menuOptionsWrapper,
        }}
      >
        {options.map((option: OptionPropsSingle) => {
          const {
            icon,
            iconColor,
            iconLibrary,
            iconSize,
            iconStyle,
            onSelect,
            textStyle,
            title,
          } = option;

          return (
            <MenuOption
              key={title}
              onSelect={onSelect}
              customStyles={{
                optionWrapper: [
                  styles.menuOptionWrapper,
                  styles.menuOptionWrapperFirst,
                ],
              }}
            >
              <Icon
                color={optionProps?.iconColor || iconColor || colors.darkGray}
                library={optionProps?.iconLibrary || FontAwesomeLibraries.FAL}
                icon={optionProps?.icon || icon || 'circle-dot'}
                size={optionProps?.iconSize || iconSize || 23}
                style={[
                  styles.baseIcon,
                  styles.ellipsesTouch,
                  optionProps?.iconStyle,
                  iconStyle,
                ]}
              />
              <Text
                numberOfLines={2}
                ellipsizeMode={'tail'}
                style={[
                  styles.baseText,
                  styles.buttonTitle,
                  optionProps?.textStyle,
                  textStyle,
                ]}
              >
                {title}
              </Text>
            </MenuOption>
          );
        })}
      </MenuOptions>
    </Menu>
  );
};

const styles: Styles = StyleSheet.create({
  baseIcon: { color: colors.gray } as FontAwesomeIconStyle,
  baseText: { color: colors.gray, fontSize: sizes.sm16 } as TextStyle,
  buttonTitle: {
    marginLeft: sizes.sm,
  } as TextStyle,
  menuOptionWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingVertical: sizes.sm,
  } as ViewStyle,
  menuOptionWrapperFirst: {
    marginTop: 0,
  } as ViewStyle,
  menuOptionsWrapper: {
    borderRadius: sizes.sm,
    marginTop: 40,
    paddingHorizontal: sizes.sm,
    paddingVertical: sizes.xs,
    width: 275,
  } as ViewStyle,
  menuOptionsWrapperTop: {
    borderRadius: sizes.sm,
    marginTop: -70,
    paddingHorizontal: sizes.sm,
    paddingVertical: sizes.xs,
    width: 275,
  } as ViewStyle,
});

export default OptionsMenu;
