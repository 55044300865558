import { v4 as uuidv4 } from 'uuid';

import { env } from '../helpers/env';

export type LogFunction = (msg: string, context?: unknown) => void;
export type MethodLogger = { error: LogFunction; log: LogFunction };

enum Severity {
  ERROR = 'error',
  INFO = 'info',
  LOG = 'log',
  TRACE = 'trace',
  WARN = 'warn',
}

const isLogEnabled: boolean = !!env.REACT_APP_ENABLE_LOG || true;

const shouldOverrideLogEnabled: boolean = true;
const isVerbose: boolean = false;

let correlationId: string;
let actionCorrelationId: string | null;

export function setCorrelationId(id: string): void {
  correlationId = id;
  actionCorrelationId = null;
}

export function refreshCorrelationId(): void {
  correlationId = uuidv4();
  actionCorrelationId = null;
}

export function setActionCorrelationId(id?: string): void {
  actionCorrelationId = id || uuidv4();
}

export function refreshActionCorrelationId(): void {
  actionCorrelationId = uuidv4();
}

export class Logger {
  private isLocalLogEnabled: boolean = false;
  private severityLevel: string =
    env.REACT_APP_LOG_LEVEL || Severity.LOG;

  public fileName: string;

  constructor(fileName: string) {
    this.fileName = fileName;

    this.handleLogEvent = this.handleLogEvent.bind(this);
  }

  private handleLogEvent(
    severity: string,
    logText: string,
    context?: unknown,
  ): void {
    if (shouldOverrideLogEnabled || (isLogEnabled && this.isLocalLogEnabled)) {
      let msg: string;

      msg = `[${correlationId}] ${this.fileName} -> ${logText}`;
      if (actionCorrelationId) {
        msg = `[${correlationId}] {${actionCorrelationId}}  ${this.fileName} -> ${logText}`;
      }

      if (context) {
        (console as any)[severity](msg, context);
      } else {
        (console as any)[severity](msg);
      }
    }
  }

  public disableLog(): void {
    this.isLocalLogEnabled = false;
  }

  public enableLog(): void {
    this.isLocalLogEnabled = true;
  }

  public getCorrelationId(): string {
    return correlationId;
  }

  public methodLog(methodName: string, silent?: boolean): MethodLogger {
    if (!correlationId) {
      refreshCorrelationId();
    }

    if (!isVerbose && !silent) {
      this.log(`${methodName}()`);
    }

    return {
      error: (msg?: string, context?: unknown): void => {
        this.error(`${methodName}()${msg ? ' :: ' : ''}${msg}`, context);
      },
      log: (msg?: string, context?: unknown): void => {
        this.log(`${methodName}()${msg ? ' :: ' : ''}${msg}`, context);
      },
    };
  }

  public log(msg?: string, context?: unknown): void {
    this.handleLogEvent(Severity.LOG, msg || '', context);
  }

  public error(msg?: string, context?: unknown): Error {
    this.handleLogEvent(Severity.LOG, msg || '', context);
    return new Error(msg);
  }
}
