import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  ImageStyle,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from 'react-native';

import AssetImage from '../../../../../components/images/AssetImage';
import ColumnListItemMenu from '../../../../../components/menus/list/ColumnListItemMenu';
import { H4 } from '../../../../../components/typography';
import { Styles } from '../../../../../models/styles.model';
import { ColumnListItem as ColumnListItemModel } from '../../../../../models/column-list.model';
import { colors } from '../../../../../themes';
import { sizes } from '../../../../../themes/sizes';

interface Props {
  data: ColumnListItemModel;
  touchableProps?: TouchableOpacityProps;
}

const styles: Styles = StyleSheet.create({
  columnListItem: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // overflow: 'hidden',
    paddingHorizontal: sizes.sm,
    paddingTop: sizes.mdPlus,
  } as ViewStyle,
  columnListItemIcon: {
    backgroundColor: '#d8d8d8', // TODO: DYNAMIC!
    flexDirection: 'row',
    height: sizes.lg,
    marginRight: sizes.sm,
    width: sizes.lg,
  } as ViewStyle,
  columnListItemText: {
    color: colors.textWhite,
    flex: 1,
  } as TextStyle,
  thumbnailImage: {
    height: '100%',
    width: '100%',
  } as ImageStyle,
});

export const ColumnListItem: FunctionComponent<Props> = function ColumnListItem(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { data, touchableProps } = props;
  return (
    <TouchableOpacity {...touchableProps}>
      <View style={styles.columnListItem}>
        <ColumnListItemMenu data={data} />
        <View style={styles.columnListItemIcon}>
          {data.thumbnailAssetId ? (
            <AssetImage
              assetId={+data.thumbnailAssetId}
              style={styles.thumbnailImage}
              resizeMode={'cover'}
            />
          ) : (
            <></>
          )}
        </View>

        <H4
          style={styles.columnListItemText}
          numberOfLines={2}
          ellipsizeMode={'tail'}
        >
          {data.title}
        </H4>
      </View>
    </TouchableOpacity>
  );
};

export default ColumnListItem;
