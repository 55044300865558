/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import {
  useChromeActions,
  useChromeContext,
} from '../../../../context/workspace/chrome.context';
import { useWorkspace } from '../../../../context/workspace/workspace.context';
import { Styles } from '../../../../models/styles.model';
import { colors } from '../../../../themes';

import { ChromeInternalMenuTypes } from '../../../../enums/navigation.enum';
import DrawerMenuInternal from '../DrawerMenuInternal';

interface Props {}

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  } as ViewStyle,
});

export const ColumnMenuSharePlus: FunctionComponent<Props> =
  function ColumnMenuSharePlus(props: PropsWithChildren<Props>): JSX.Element {
    const [workspace] = useWorkspace();

    const {
      handleBackToRootMenuColumn,
      isInternalMenuItemSelected,
      sharePlus,
    } = useChromeActions();

    const {
      handleSharePlusCurrentTap,
      handleSharePlusSavedTap,
      handleSharePlusWaitingTap,
    } = sharePlus;

    return (
      <DrawerMenuInternal
        iconColor={colors.textDark}
        selectedColor={workspace.styles?.primaryColor}
        items={[
          {
            icon: 'chevron-left',
            iconColor: colors.white,
            onPress: (): void => {
              handleBackToRootMenuColumn();
            },
            style: {
              backgroundColor: colors.darkGray,
              marginLeft: 0,
              paddingLeft: 20,
            },
            textStyle: { color: colors.white },

            title: 'Share+',
          },
          {
            icon: 'paper-plane',
            isSelected: isInternalMenuItemSelected(
              ChromeInternalMenuTypes.SharePlusCurrent,
            ),
            onPress: (): void => handleSharePlusCurrentTap(),
            title: 'Current / In Progress',
          },
          {
            icon: 'pencil-ruler',
            isSelected: isInternalMenuItemSelected(
              ChromeInternalMenuTypes.SharePlusSaved,
            ),
            onPress: (): void => handleSharePlusSavedTap(),
            title: 'Saved Drafts',
          },
          // {
          //   icon: 'inbox-out',
          //   isSelected: isInternalMenuItemSelected(
          //     ChromeInternalMenuTypes.SharePlusShared,
          //   ),
          //   onPress: (): void => handleSharePlusSharedTap(),
          //   title: 'Previously Shared',
          // },
          {
            icon: 'wifi-slash',
            isSelected: isInternalMenuItemSelected(
              ChromeInternalMenuTypes.SharePlusPending,
            ),
            onPress: (): void => handleSharePlusWaitingTap(),
            title: 'Waiting to Sync',
          },
        ]}
      />
    );
  };

export default ColumnMenuSharePlus;
