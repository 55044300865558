/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react';
import { Platform, StyleSheet, View, ViewStyle } from 'react-native';
import { NativeModules } from 'react-native';
import PSPDFKit from 'pspdfkit';
import { Styles } from '../../models/styles.model';
import { ViewerParams } from '../../models/viewer.model';
import { env } from '../../helpers/env'

interface Props {
  params: ViewerParams;
}

const styles: Styles = StyleSheet.create({
  container: { flex: 1 } as ViewStyle,
  pspdfKit: { flex: 1 } as ViewStyle,
});

export const PspdfViewer: FunctionComponent<Props> = function PspdfViewer(
  props: PropsWithChildren<Props>,
): JSX.Element {

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let instance;

    if(!container) {
      return;
    }

    (async function() {
      instance = await PSPDFKit.load({
        container,
        document: props.params.uri || '',
        baseUrl: `${window.location.protocol}//${window.location.host}/${env.PUBLIC_URL}`,
        licenseKey: env.REACT_APP_PSPDFKIT_LICENSE_WEB
      });
    })();

    return () => { PSPDFKit && PSPDFKit.unload(container) };
  }, []);

  return (
    <View style={styles.container}>
        <div ref={containerRef} style={{height:"100%"}}/>
    </View>
  )
};

export default PspdfViewer;
