/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { isNumber } from 'lodash';

import { useDomain } from '../context/domain/domain.context';
import { DomainStackParamList } from '../models/domain.model';
import Workspace from '../navigation/Workspace';
import AppCodeScreen from '../screens/domain/AppCode';
import LoadingScreen from '../screens/domain/Loading';

import { DomainNavigationEnum } from '../enums/navigation.enum';

const Stack: any = createStackNavigator<DomainStackParamList>();

const DomainNavigation: React.FunctionComponent<Record<string, unknown>> =
  () => {
    const [domainContext] = useDomain();

    const { applicationId, initialized } = domainContext;

    return initialized ? (
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{ headerShown: false }}
          initialRouteName={
            isNumber(applicationId)
              ? DomainNavigationEnum.WorkspaceNavigator
              : DomainNavigationEnum.AppCodeScreen
          }
        >
          <Stack.Screen
            component={AppCodeScreen}
            gestureEnabled={'false'}
            options={{
              gestureEnabled: false,
            }}
            name={DomainNavigationEnum.AppCodeScreen}
          />
          <Stack.Screen
            component={LoadingScreen}
            options={{
              cardOverlayEnabled: true,
              cardStyle: { backgroundColor: 'transparent' },
              cardStyleInterpolator: ({ current: { progress } }: any): any => ({
                cardStyle: {
                  backgroundColor: '#fff',
                  opacity: progress.interpolate({
                    inputRange: [0, 0.5, 0.9, 1],
                    outputRange: [0, 0.25, 0.7, 1],
                  }),
                },
              }),

              gestureEnabled: false,
              mode: 'modal',
            }}
            name={DomainNavigationEnum.LoadingScreen}
          />
          {!!applicationId && (
            <Stack.Screen
              component={Workspace}
              name={DomainNavigationEnum.WorkspaceNavigator}
              initialParams={{ applicationId: applicationId }}
            />
          )}
        </Stack.Navigator>
      </NavigationContainer>
    ) : (
      <View />
    );
  };

export default DomainNavigation;
