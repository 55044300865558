// import 'react-native-get-random-values';
// import 'react-native-url-polyfill/auto';

import { GetObjectCommand, S3Client, S3ClientConfig } from '@aws-sdk/client-s3';
import { getSignedUrl as awsGetSignedUrl } from '@aws-sdk/s3-request-presigner';
import { env } from '../helpers/env';

// This function will return a signed URL you can use to download a file from the S3 bucket.
// Returns a string that is the URL of the file.
export async function fetchSignedUrl({
  bucket,
  key,
  s3ClientConfig,
}: {
  bucket: string;
  key: string;
  s3ClientConfig: S3ClientConfig | null;
}): Promise<string> {
  if (!s3ClientConfig) {
    throw new Error('Missing s3ClientConfig');
  }

  const client: S3Client = new S3Client(s3ClientConfig);

  const command: GetObjectCommand = new GetObjectCommand({
    Bucket: bucket,
    Key: key,
  });

  return await awsGetSignedUrl(client, command, { expiresIn: 3600 });
}

export function getPublicUrlFromS3Key(s3Key:string): string{
  const s3PublicBucket: string = `${env.REACT_APP_STORYSLAB_BUCKET_PUBLIC_CONTENT}`;
  return `https://${s3PublicBucket}.s3.amazonaws.com/${s3Key}`;
}
