/* eslint-disable max-lines-per-function */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  Dimensions,
  GestureResponderEvent,
  ImageStyle,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { RouteProp } from '@react-navigation/native';

import TouchableOverlay from '../../../../components/overlay/TouchableOverlay';
import { Styles } from '../../../../models/styles.model';
import { sizes } from '../../../../themes';

import CollectionViewHeader from '../../../../components/headers/CollectionViewHeader';
import { useColumnList } from '../../../../hooks/column-list.hook';
import { ColumnListItem } from '../../../../models/column-list.model';
import ColumnList from '../../components/lists/cv1/ColumnList';
import { TemplateNavigationEnum } from '../../enums/navigation.enum';
import { TemplateStackParamList } from '../../models/template.model';

interface Props {
  route: RouteProp<TemplateStackParamList, TemplateNavigationEnum>;
}

export const Cv1Layout: FunctionComponent<Props> = function LayoutCv1(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { route } = props;
  const { width } = Dimensions.get('window');

  const {
    collectionStack,
    columnData,
    handleRowTap,
    navigation,
    scrollViewRef,
    scrollViewWidth,
  } = useColumnList(route.params.id);

  return (
    <View style={styles.container}>
      <TouchableOverlay
        onPress={(): void => {
          navigation.goBack();
        }}
        opacity={0.6}
        tintColor={'#ffffff'}
        zIndex={0}
      />

      <CollectionViewHeader
        collectionStack={collectionStack}
        showMenuItems={true}
      />

      <ScrollView
        // ContentContainerStyle={styles.contentContainer}
        decelerationRate={0}
        horizontal={true}
        snapToAlignment={'start'}
        snapToInterval={width * 0.86} //Your element width
        style={[styles.horizontalScroll, { width: scrollViewWidth }]}
        onContentSizeChange={(): void => {
          if (scrollViewRef.current) {
            scrollViewRef.current.scrollToEnd({ animated: true });
          }
        }}
        ref={scrollViewRef}
      >
        {columnData?.map((columnList: any, index: number) => {
          return (
            <ColumnList
              data={columnList}
              key={index}
              onPressItem={(
                event: GestureResponderEvent,
                itemData: ColumnListItem | undefined,
              ): void => {
                handleRowTap(event, itemData, index);
              }}
            />
          );
        })}
      </ScrollView>
    </View>
  );
};

const styles: Styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  } as ViewStyle,
  contentContainer: {
    alignContent: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  } as ViewStyle,
  horizontalScroll: {
    flexGrow: 0,
  } as ViewStyle,
  logo: {
    bottom: sizes.sm,
    height: 180,
    position: 'absolute',
    right: sizes.sm,
    width: 500,
  } as ImageStyle,
});

export default Cv1Layout;
