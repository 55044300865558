import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import StorySlabLogoColor from '../../assets/logos/storyslab-color-logo.svg';
import { Styles } from 'models/styles.model';

interface Props {
  onLongPress: () => void;
}

const styles: Styles = StyleSheet.create({
  storyslabLoginTouchable: {
    height: 50,
    width: 50,
  },
  storyslabLoginWrapper: {
    alignItems: 'center',
    bottom: 0,
    height: 50,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    width: 50,
  },
});

export const StorySlabDot: React.FunctionComponent<Props> =
  function StorySlabDot(props: React.PropsWithChildren<Props>): JSX.Element {
    return (
      <View style={styles.storyslabLoginWrapper}>
        <TouchableOpacity
          style={styles.storyslabLoginTouchable}
          onLongPress={props.onLongPress}
        >
          <StorySlabLogoColor width={30} height={30} />
        </TouchableOpacity>
      </View>
    );
  };

export default StorySlabDot;
