/* eslint-disable @typescript-eslint/no-empty-interface */

import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  ImageStyle,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import AssetImage from '../../../../components/images/AssetImage';
import { H3 } from '../../../../components/typography';
import { useWorkspace } from '../../../../context/workspace/workspace.context';
import { ComponentTargetTypes } from '../../../../enums/component.enum';
import { generateComponentConfig } from '../../../../helpers/component.helper';
import { ComponentConfig, ComponentNavigationOnPress } from '../../../../models/data';
import { Styles } from '../../../../models/styles.model';
import { colors, fontFamily } from '../../../../themes';
import { sizes } from '../../../../themes/sizes';

interface Props {
  logoConfig: ComponentConfig<ComponentTargetTypes.COLLECTION>;
  style?: ViewStyle | Array<ViewStyle>;
  data?: Array<any>;
  onPress?: ComponentNavigationOnPress;
}

export const PD2BottomMenu: FunctionComponent<Props> = function Pd2BottomMenu(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { logoConfig, data, style } = props;
  const [workspace] = useWorkspace();

  const [logoComponentConfig, setLogoComponentConfig] =
    useState<ComponentConfig<ComponentTargetTypes.COLLECTION>>();

  useEffect(() => {
    if (logoConfig) {
      generateComponentConfig({ config: logoConfig, workspace }).then(
        (config: ComponentConfig<ComponentTargetTypes.COLLECTION>) => {
          setLogoComponentConfig(config);
        },
      );
    }
  }, [logoConfig]);

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity
        onPress={(): void => {
          if (props.onPress && logoComponentConfig?.target) {
            props.onPress(logoComponentConfig?.target as any);
          }
        }}
      >
        <View style={styles.logoWrapper}>
          {logoComponentConfig?.visibility &&
            logoComponentConfig?.props.image && (
              <AssetImage
                assetId={+logoComponentConfig?.props.image}
                style={styles.logo}
                resizeMode={'contain'}
              />
            )}
        </View>
      </TouchableOpacity>

      <View style={styles.menuItems}>
        {data &&
          data.map((menuItem: any, index: number) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={(): void => {
                  if (props.onPress) {
                    console.log(menuItem);
                    props.onPress(menuItem);
                  }
                }}
              >
                <View style={styles.menuItem}>
                  <H3 style={styles.menuItemText}>{menuItem.text}</H3>
                </View>
              </TouchableOpacity>
            );
          })}
      </View>
    </View>
  );
};

const styles: Styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginLeft: 96,
  } as ViewStyle,
  logo: {
    height: 100,
    maxHeight: 150,
    width: 350,
  } as ImageStyle,
  logoWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 150,
    justifyContent: 'center',
    marginRight: 10,
    overflow: 'hidden',
    width: 350,
  } as ImageStyle,
  menuItemText: {
    color: colors.textWhite,
    fontFamily: fontFamily.light,
    fontSize: sizes.mdPlus,
  } as TextStyle,
  menuItems: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
  } as ImageStyle,
});

export default PD2BottomMenu;
