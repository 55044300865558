import React from 'react';
import { StyleSheet } from 'react-native';

import { Col, Row } from '../../components/grid';
import { Styles } from '../../models/styles.model';
import { sizes } from '../../themes/sizes';

import Card from './Card';

interface Props {
  footer?: JSX.Element | null;
  header: JSX.Element;
}

const styles: Styles = StyleSheet.create({
  bodyRow: {
    marginVertical: sizes.mdPlus,
  },
  footerRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const HeaderFooterCard: React.FunctionComponent<Props> =
  function HeaderFooterCard(
    props: React.PropsWithChildren<Props>,
  ): JSX.Element {
    return (
      <Card>
        <Row style={styles.headerRow}>{props.header}</Row>
        <Row>
          <Col style={styles.bodyRow}>{props.children}</Col>
        </Row>
        {props.footer && (
          <Row>
            <Col style={styles.footerRow}>{props.footer}</Col>
          </Row>
        )}
      </Card>
    );
  };

export default HeaderFooterCard;
