import React from 'react';
import { ImageProps, StyleSheet } from 'react-native';
import Image from '../../components/images/Image';

import { Styles } from '../../models/styles.model';

interface Props {
  uri?: string;
  source?: ImageProps;
}

const styles: Styles = StyleSheet.create({
  backgroundImage: {
    height: '100%',
    position: 'absolute',
    resizeMode: 'cover',
    width: '100%',
  },
});

export const BackgroundImage: React.FunctionComponent<Props> =
  function BackgroundImage(props: React.PropsWithChildren<Props>): JSX.Element {
    return props.source ? (
      <Image source={props.source} style={styles.backgroundImage} />
    ) : (
      <Image
        style={styles.backgroundImage}
        resizeMode="cover"
        source={{
          uri: props.uri,
        }}
      />
    );
  };

export default BackgroundImage;
