/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AppState, AppStateStatus } from 'react-native';
import {
  effectStartSyncDependencyList,
  shouldAuthenticateAppUser,
  shouldFetchTemporaryCredentials,
  shouldStartSync,
} from '../../helpers/context.helper';
import {
  Logger,
  MethodLogger,
  refreshCorrelationId,
} from '../../helpers/logger.helper';
import {
  areCredentialsExpired,
  isUserLoggedIn,
  isUserLoggedInAndHasTempCredentials,
} from '../../helpers/workspace-navigation.helper';
import { ContextI } from '../../models/context.model';
import {
  TemporaryCognitoCredentials,
  WorkspaceContextProps as ContextProps,
  WorkspaceContextProps,
  WorkspaceAssetsById,
  CollectionsItemById,
} from '../../models/workspace.model';
import { authenticateAppUser } from '../../services/auth.service';
import { fetchTemporaryCredentials } from '../../services/cognito.service';
import { workspaceActions } from '../../actions/workspace.actions';
import { WorkspaceSyncState } from '../../enums/sync.enum';
import { TemplateNames } from '../../templates';

// TODO: Move all the bootstrapping logic elsewhere
// FROM-HERE
import { extractAuthDetails, simpleHash } from '../../helpers/util.helper';
import { ApiRequestMethods } from '../../models/api.model';
import { ClientApiUrls } from '../../enums/client-api.enum';
import { fetchFromClientApi } from '../../services/api.service';
import { ManifestTypes, NavigationItem } from '@storyslab/storyslab.common.models';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { fetchSignedUrl } from '../../services/s3.service';
import { AssetsItem, ViewsManifestItem, ContentFilesItem } from '../../models/data';
import { CollectionsItem } from '../../models/data/collections.model';

interface FetchManifestResp {
  signedUrl: string;
  lastModified: string; // Date string
}
// TO-HERE

interface Props {
  applicationId: number | null;
  initialValue: any;
}

export type WorkspaceContextI = ContextI<WorkspaceContextProps>;

const Context: React.Context<WorkspaceContextI> = createContext([] as any);

const storageKey: string = '@Workspace';

const logger: Logger = new Logger('workspace.context.tsx');

export function useWorkspace(): WorkspaceContextI {
  const methodLog: MethodLogger = logger.methodLog('useWorkspace', true);
  const context: WorkspaceContextI = useContext(Context);

  if (!context) {
    throw methodLog.error('useWorkspace must be used within a Global Provider');
  }
  return context;
}

export function useWorkspaceActions(): any {
  const methodLog: MethodLogger = logger.methodLog('useWorkspaceActions', true);
  const context: WorkspaceContextI = useContext(Context);

  if (!context) {
    throw methodLog.error('useWorkspace must be used within a Global Provider');
  }

  return workspaceActions(context);
}

const WorkspaceProvider: React.FunctionComponent<Props> = ({
  applicationId,
  initialValue,
  children,
}: PropsWithChildren<Props>) => {
  const methodLog: MethodLogger = logger.methodLog('WorkspaceProvider', true);
  methodLog.log(`ApplicationId: ${applicationId}`);

  if (!applicationId) {
    methodLog.error('ApplicationId is required');
    throw new Error('ApplicationId is required');
  }

  const augmentedStorageKey: string = `${storageKey}-${applicationId}`;
  const [workspaceContext, setContext] = useState<Partial<ContextProps>>({});
  const prevAppState: React.MutableRefObject<AppStateStatus> = useRef(
    AppState.currentState,
  );
  const [appState, setAppState] = useState<AppStateStatus>(
    AppState.currentState,
  );
  const [isDbInitialized, setIsDbInitialized] = useState(false);

  const value: WorkspaceContextI = useMemo<WorkspaceContextI>(
    () => [
      workspaceContext,
      (
        state:
          | Partial<ContextProps>
          | ((prevState: Partial<ContextProps>) => Partial<ContextProps>),
      ): void => {
        if (typeof state === 'function') {
          setContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              ...state(prevState),
            };
          });
        } else {
          setContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              ...state,
            };
          });
        }
      },
    ],
    [workspaceContext],
  );

  // For the web we are just going to initialize
  useEffect(() => {

    if(!!applicationId) {
      setContext({
       ...initialValue,
       initialized:true,
       templateName: TemplateNames.DEFAULT,
      });
    }
  }, []);

  useEffect(() => {
    refreshCorrelationId();

    if (shouldAuthenticateAppUser(workspaceContext)) {
      authenticateAppUser({
        accessToken: workspaceContext.credentials?.accessToken || '',
        applicationId: workspaceContext.applicationId || -1,
        idToken: workspaceContext.credentials?.idToken || '',
        tenantId: workspaceContext.tenantId || -1,
      })
        .then(([user]: [user: any]) => {
          setContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              entitlements: user?.entitlements || null,
              errorState: null,
              user: {
                ...workspaceContext.user,
                deviceId: parseInt(user?.deviceId, 10),
                id: parseInt(user?.id, 10),
              },
            };
          });
        })
        .catch((err: any) => {
          console.log(err);
          setContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              entitlements: undefined,
              errorState: { message: err },
              user: undefined,
            };
          });
        });
    }
  }, [workspaceContext.credentials]);

  useEffect(() => {
    if (
      isUserLoggedIn(workspaceContext) &&
      shouldFetchTemporaryCredentials(workspaceContext)
    ) {
      fetchTemporaryCredentials(workspaceContext)
        .then((temporaryCredentials: TemporaryCognitoCredentials | null) => {
          setContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              errorState: null,
              temporaryCognitoCredentials: temporaryCredentials,
            };
          });
        })
        .catch((err: any) => {
          console.log(err);

          setContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              errorState: { message: err },
              temporaryCognitoCredentials: null,
            };
          });
        });
    }
  }, [workspaceContext.credentials, workspaceContext.entitlements]);

  // TODO: All of this logic should probably be broken up and placed into seperate modules
  useEffect(() => {
    // We need to grab our asset manifest and store into the workspace context
    
    if(isUserLoggedInAndHasTempCredentials(workspaceContext)) {

      const authDetails: any = extractAuthDetails(workspaceContext);

      async function fetchAndSetAssetsManifest() {
        let [ assetsManifestResp, error ] = await fetchFromClientApi<FetchManifestResp>({
          auth: authDetails,
          body: {
            manifestType: ManifestTypes.ASSETS,
            s3ClientConfig: workspaceContext.temporaryCognitoCredentials,
          },
          method: ApiRequestMethods.POST,
          url: `${ClientApiUrls.FetchManifest}?cacheBuster=${workspaceContext.temporaryCognitoCredentials?.credentials?.expiration}`,
        });

        let response = await axios({
          method: 'get',
          url: assetsManifestResp?.signedUrl,
          responseType: 'json'
        });

        if(response.data?.data) {

          // We are going to now construct the object which maps asset IDs to their AssetsItem entry
          // This will be stored in the context of the workspace for other components to use such as AssetImage
          let currentAssetsById:WorkspaceAssetsById = {};

          response.data.data.forEach((asset:AssetsItem) => {
            currentAssetsById[asset.id] = asset;
          });

          window.sessionStorage.setItem('assetsById', JSON.stringify(currentAssetsById));
        }
      }

      // We need to get the collections data
      async function fetchAndSetCollectionsManifest() {
        let [ collectionsManifestResp, error ] = await fetchFromClientApi<FetchManifestResp>({
          auth: authDetails,
          body: {
            manifestType: ManifestTypes.COLLECTIONS,
            s3ClientConfig: workspaceContext.temporaryCognitoCredentials,
          },
          method: ApiRequestMethods.POST,
          url: `${ClientApiUrls.FetchManifest}?cacheBuster=${workspaceContext.temporaryCognitoCredentials?.credentials?.expiration}`,
        });
          
        // We now will use the signedUrl to get the manifest.json file
        let response = await axios({
          method: 'get',
          url: collectionsManifestResp?.signedUrl,
          responseType: 'json'
        });

        if(response.data?.data) {

          // We are going to now construct the object which maps collections item IDs to their CollectionsItem entry
          // This will be stored in the context of the workspace for other components to use such as CollectionsItem
          let currentCollectionsItemById:CollectionsItemById = {};

          response.data.data.forEach((collection:CollectionsItem) => {
            currentCollectionsItemById[collection.id] = collection;
          });

          window.sessionStorage.setItem('collectionsItemById', JSON.stringify(currentCollectionsItemById));
        }
      }

      async function fetchAndSetViewsManifest() {
        let [ viewsManifestResp, error ] = await fetchFromClientApi<FetchManifestResp>({
          auth: authDetails,
          body: {
            manifestType: ManifestTypes.VIEWS,
            s3ClientConfig: workspaceContext.temporaryCognitoCredentials,
          },
          method: ApiRequestMethods.POST,
          url: `${ClientApiUrls.FetchManifest}?cacheBuster=${workspaceContext.temporaryCognitoCredentials?.credentials?.expiration}`,
        });

        let response = await axios({
          method: 'get',
          url: viewsManifestResp?.signedUrl,
          responseType: 'json'
        });

        if(response.data?.data) {
          let currentViews:{ [key: number]: ViewsManifestItem<any> } = {};

          response.data.data.forEach((viewsManifestItem:ViewsManifestItem<any>) => {
            currentViews[viewsManifestItem.id] = viewsManifestItem;
          });

          setContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              [ManifestTypes.VIEWS]: currentViews,
            };
          });
        }
      }

      async function fetchAndSetContentFilesManifest() {
        let [ contentFilesManifestResp, error ] = await fetchFromClientApi<FetchManifestResp>({
          auth: authDetails,
          body: {
            manifestType: ManifestTypes.CONTENT_FILES,
            s3ClientConfig: workspaceContext.temporaryCognitoCredentials,
          },
          method: ApiRequestMethods.POST,
          url: `${ClientApiUrls.FetchManifest}?cacheBuster=${workspaceContext.temporaryCognitoCredentials?.credentials?.expiration}`,
        });

        let response = await axios({
          method: 'get',
          url: contentFilesManifestResp?.signedUrl,
          responseType: 'json'
        });

        if(response.data?.data) {
          let currentContentFilesItems:{ [key: number]: ContentFilesItem } = {};

          response.data.data.forEach((contentFilesItem:ContentFilesItem) => {
            currentContentFilesItems[contentFilesItem.id] = contentFilesItem;
          });

          window.sessionStorage.setItem('contentFilesItemById', JSON.stringify(currentContentFilesItems));
        }
      }

      async function fetchAndSetNavigationManifest() {
        let [ navigationManifestResp, error ] = await fetchFromClientApi<FetchManifestResp>({
          auth: authDetails,
          body: {
            manifestType: ManifestTypes.NAVIGATION_ITEMS,
            s3ClientConfig: workspaceContext.temporaryCognitoCredentials,
          },
          method: ApiRequestMethods.POST,
          url: `${ClientApiUrls.FetchManifest}?cacheBuster=${workspaceContext.temporaryCognitoCredentials?.credentials?.expiration}`,
        });

        let response = await axios({
          method: 'get',
          url: navigationManifestResp?.signedUrl,
          responseType: 'json'
        });

        if(response.data?.data) {
          
          setContext((prevState: Partial<ContextProps>) => {
            return {
              ...prevState,
              [ManifestTypes.NAVIGATION_ITEMS]: response.data?.data,
            };
          });
        }
      }

      async function bootstrapWorkspace() {
        await fetchAndSetAssetsManifest();
        await fetchAndSetCollectionsManifest();
        await fetchAndSetViewsManifest();
        await fetchAndSetContentFilesManifest();
        // We need to update the navigation items last or else we will navigate before all the other manifests have loaded
        await fetchAndSetNavigationManifest();
      };

      bootstrapWorkspace();
    }
  }, [ ...effectStartSyncDependencyList(workspaceContext) ]);

  return workspaceContext.initialized ? (
    <Context.Provider value={value as WorkspaceContextI}>
      {children}
    </Context.Provider>
  ) : null;
};

export default WorkspaceProvider;
