import getTime from 'date-fns/getTime';

export function convertSecondsToMilliseconds(seconds: number): number {
  return Math.round(seconds * 1000);
}

export function getTimestampInFuture(seconds: number): number {
  return Date.now() + convertSecondsToMilliseconds(seconds);
}

export function isDateExpiredGmt(gmtDate: Date | string | undefined): boolean {
  if (!gmtDate) {
    return true;
  }

  const localDateGmt: Date = new Date(new Date().toISOString());

  if (typeof gmtDate === 'string') {
    gmtDate = new Date(gmtDate);
  }

  return localDateGmt > gmtDate;
}

export function convertDateStringToEpoch(dateString: string): number {
  if (!dateString) {
    return Date.now();
  }

  return new Date(dateString).getTime();
}
