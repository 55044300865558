import { stringify } from 'querystring';

import { Platform } from 'react-native';
// TODO: Come up with alternative for react-native-app-auth on the web client
// import { authorize } from 'react-native-app-auth';

import { WorkspaceContextI } from '../context/workspace/workspace.context';
import { getTimestampInFuture } from '../helpers/date.helper';
import { getAuthUrl, getAppStateFromUrlParameters, getUrlParameter, clearUrlParameters } from '../helpers/url.helper';
import { generateHeaders, getUserDeviceDetails } from '../helpers/util.helper';
import { ApiRequestParams } from '../models/api.model';
import { OAuthPayloadRaw } from '../models/auth.model';
import { IdentityProvider } from '../models/workspace.model';
import { env } from '../helpers/env';

export async function getJWT({
  code,
  grantType,
  identityProvider,
  refreshToken,
  applicationId,
  tenantId,
}: {
  code?: string;
  grantType: string;
  identityProvider: string;
  refreshToken?: string;
  applicationId: number;
  tenantId: number;
}): Promise<Array<any>> {
  let body: any = {
    grant_type: grantType,
    identity_provider: identityProvider,
    redirect_uri: 'https://api-dev.storyslab.io/auth/redirect',
    tenant_id: +tenantId,
  };

  if (code) {
    body = { ...body, code };
  }

  if (refreshToken) {
    body = { ...body, refresh_token: refreshToken };
  }

  return await fetch(`${env.REACT_APP_API_GATEWAY_AUTH}/token`, {
    body: stringify(body),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'post',
  })
    .then((response: any) => response.json())
    .then((result: any) => {
      if (result.error) {
        return [null, result.error.message];
      }

      return [result.data, null];
    });
}

export async function authenticateAppUser(params: {
  accessToken: string;
  applicationId: number;
  idToken: string;
  tenantId: number;
}): Promise<any> {
  return await fetch(`${env.REACT_APP_API_GATEWAY_AUTH}/app`, {
    body: stringify(getUserDeviceDetails()),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...generateHeaders(params),
    },
    method: 'post',
  })
    .then((response: any) => {
      return response.json();
    })
    .then((result: any) => {
      if (result.error) {
        throw result.error.message;
      }

      if (result.data && result.data.user) {
        return result.data.user;
      } else {
        throw new Error('data.user is required.');
      }
    })
    .then((user: any) => {
      if (!user.entitlements) {
        throw new Error('user.entitlements are required.');
      }

      if (user.entitlements.length === 0) {
        throw new Error('user has no entitlements');
      }

      if (!user.deviceId) {
        throw new Error('user.deviceId is required.');
      }

      return [user, null];
    })
    .catch((err: Error) => {
      return [null, err];
    });
}

export async function genericLogin(params: {
  [key: string]: string | number;
}): Promise<any> {
  // Return await fetch(`${process.env.REACT_APP_API_GATEWAY}/oauth/login`, {

  return await fetch(`${env.REACT_APP_API_GATEWAY_AUTH}/login`, {
    body: stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'post',
  })
    .then((response: any) => response.json())
    .then((result: any) => {
      if (result.error) {
        throw result.error.message;
      }
      return [result, null];
    })
    .catch((err: Error) => {
      return [null, err];
    });
}

export async function oauthIdpLogin({
  applicationId,
  provider,
  workspaceContext,
  tenantId
}: {
  applicationId: number;
  provider: IdentityProvider;
  workspaceContext: WorkspaceContextI;
  tenantId: number | null | undefined;
}): Promise<any> {
  const [workspace, setWorkspace] = workspaceContext;

  if (!provider || !provider.cognitoName) {
    throw new Error('provider.cognitoName is required');
  }

  if (!applicationId) {
    throw new Error('applicationId is required');
  }

  if (!tenantId) {
    throw new Error('tenantId is required');
  };

  try {
    window.location.href = getAuthUrl(
      applicationId,
      provider.cognitoName,
      workspace.appCode || '',
      tenantId,
    );

  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

// This function will look at the current parameters in the URL and
// if "state" object is detected it will process and complete
// setting up of the JWT and credentials for the session.
export async function oauthIdpCallback(workspaceContext:WorkspaceContextI): Promise<any> {
  const state = getAppStateFromUrlParameters();
  const code: string = getUrlParameter('code');
  const identityProvider: string = getUrlParameter('identity_provider');
  const [workspace, setWorkspace] = workspaceContext;

  if(state && code) {
    const [jwt, err] = await getJWT({
      applicationId: +state.applicationId,
      code: code,
      grantType: 'authorization_code',
      identityProvider: identityProvider,
      tenantId: state.tenantId,
    });

    if (!err) {
      setWorkspace({
        credentials: {
          accessToken: jwt.access_token,
          expiresAt: getTimestampInFuture(jwt.expires_in),
          expiresIn: jwt.expires_in,
          idToken: jwt.id_token,
          refreshToken: jwt.refresh_token,
          tokenType: jwt.token_type,
        },

        tenantId:state.tenantId
      });
    } else {
      console.log(`oauthIdpLogin Error: `, err);
    }

    // Clear the paramters from the url
    clearUrlParameters();
  }
}