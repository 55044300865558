import React from 'react';
import { Linking, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import StorySlabLogo from '../../assets/logos/storyslab-bw-logo.svg';
import TextButton from '../../components/buttons/Text';
import { Styles } from '../../models/styles.model';
import { colors, fontFamily, sizes } from '../../themes';

interface Props {
  logo?: JSX.Element;
  title?: string;
}

const styles: Styles = StyleSheet.create({
  headerLogo: {
    flexDirection: 'row',
  },
  headerText: {
    color: colors.textLight,
    fontFamily: fontFamily.regular,
    fontSize: sizes.mdPlus,
    lineHeight: 60,
    marginLeft: sizes.md,
  },
  logoImage: {
    aspectRatio: 1,
    width: 60,
  },
});

export const LogoHeader: React.FunctionComponent<Props> = function LogoHeader(
  props: React.PropsWithChildren<Props>,
): JSX.Element {
  const { logo, title } = props;

  return (
    <>
      {logo ? (
        logo
      ) : (
        <View style={styles.headerLogo}>
          <View style={styles.logoImage} ><StorySlabLogo /></View>
          <Text style={styles.headerText}>{title}</Text>
        </View>
      )}
      <View>
        <TouchableOpacity
          onPress={(): void => {
            Linking.openURL('mailto:support@storyslab.com');
          }}
        >
          <TextButton>Need Help?</TextButton>
        </TouchableOpacity>
      </View>
    </>
  );
};

export default LogoHeader;
