import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  ImageStyle,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import AssetImage from '../../../../components/images/AssetImage';
import { H3 } from '../../../../components/typography';
import { ComponentNavigationOnPress } from '../../../../models/data';
import { Styles } from '../../../../models/styles.model';
import { colors, fontFamily, sizes } from '../../../../themes';

import HorizontalBase, { Props as BaseProps } from './horizontal-base';

interface Item {
  hideTitles: boolean;
  id: number;
  image: number;
  key: number;
  text: string;
  textColor: string;
}

interface RenderItem {
  item: Item;
  index: number;
  separators: unknown;
}

interface Props extends Partial<BaseProps> {
  config?: any;
  data?: Array<Partial<Item>> | undefined;
  onPress?: ComponentNavigationOnPress;
}

const defaultHeight: number = 120;
const defaultWidth: number = 120;

const styles: Styles = StyleSheet.create({
  backgroundImage: {
    alignSelf: 'center',
    height: defaultHeight,
    width: defaultWidth,
  } as ImageStyle,
  bannerText: {
    color: colors.textWhite,
    fontFamily: fontFamily.extraBold,
    marginTop: sizes.sm,
    textAlign: 'center',
    textTransform: 'uppercase',
  } as TextStyle,
  flatList: {} as ViewStyle,
  item: {
    alignItems: 'center',
    flexDirection: 'column',
    marginHorizontal: sizes.md,
    width: defaultWidth + sizes.md * 2,

    zIndex: 10000,
  } as ViewStyle,
});

export const HorizontalBottomTitleScroll: FunctionComponent<Props> =
  function HorizontalBottomTitleScroll(
    props: PropsWithChildren<Props>,
  ): JSX.Element {
    return (
      <HorizontalBase
        {...props}
        style={[styles.flatList, props.style]}
        data={props.data}
        renderItem={(datum: any): JSX.Element => {
          return (
            <View style={styles.item}>
              <TouchableOpacity
                onPress={(): void => {
                  if (props.onPress) {
                    props.onPress(datum.item);
                  }
                }}
              >
                {datum.item.image && (
                  <AssetImage
                    assetId={datum.item.image}
                    style={styles.backgroundImage}
                    resizeMode={'cover'}
                  />
                )}
                {!datum.item.hideTitles && (
                  <H3
                    style={[
                      styles.bannerText,
                      datum.item.textColor
                        ? { color: datum.item.textColor }
                        : {},
                    ]}
                  >
                    {datum.item.text}
                  </H3>
                )}
              </TouchableOpacity>
            </View>
          );
        }}
      />
    );
  };

export default HorizontalBottomTitleScroll;
