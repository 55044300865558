import React from 'react';
import { View } from 'react-native';

import { createDynamicStyleSheet } from '../../helpers/styles.helper';
import { Styles } from '../../models/styles.model';
import { sizes } from '../../themes/sizes';

const styles: Styles = createDynamicStyleSheet(
  {
    container: {
      alignItems: 'center',
      margin: sizes.md,
    },
  },
  {
    phone: {
      container: {
        marginTop: '5%',
      },
    },
    tablet: {
      container: {
        marginTop: '10%',
      },
    },
  },
);

export const CardContainer: React.FunctionComponent<any> =
  function CardContainer(props: React.PropsWithChildren<any>): JSX.Element {
    return <View style={styles.container}>{props.children}</View>;
  };

export default CardContainer;
