/* eslint-disable @typescript-eslint/no-empty-interface */

import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { Route } from '@react-navigation/native';

import PdfViewer from '../../components/viewers/PspdfViewer';
// import VideoViewer from 'components/viewers/VideoViewer';
import { useWorkspace } from '../../context/workspace/workspace.context';
import { ComponentTargetTypes } from '../../enums/component.enum';
import { selectViewerType } from '../../helpers/file.helper';
import { ContentTypes } from '../../models/content.model';
import { AssetsItemDb } from '../../models/data';
import { Styles } from '../../models/styles.model';
import { ViewerParams } from '../../models/viewer.model';
import { fetchSignedUrl } from '../../services/s3.service'
import { getAssetById } from '../../accessors/asset.accessor';
import { env } from '../../helpers/env';

interface ViewerProps {
  params: any;
}

export interface Props extends ViewerProps {
  contentType: ContentTypes;
  header: JSX.Element;
  route: Route<'Viewer', any>;
}

const styles: Styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  } as ViewStyle,
  headerContainer: {} as ViewStyle,
  viewerContainer: {
    flex: 1,
  } as ViewStyle,
});

const Viewer: FunctionComponent<ViewerProps> = function Viewer(
  props: PropsWithChildren<ViewerProps>,
): JSX.Element {
  const [workspace] = useWorkspace();
  const [viewerParams, setViewerParams] = useState<ViewerParams | null>();

  // For the web this is how we fetch the asset for viewing
  useEffect(() => {
 
    // We now will fetch a signed URL to pass to the viewers to use
    (async () => {
      let currentAsset = await getAssetById({ id: props.params.target });

      if(currentAsset) {
        let results = await fetchSignedUrl({
          bucket: env.REACT_APP_STORYSLAB_BUCKET_TENANT_ASSETS,
          key: currentAsset.s3Key,
          s3ClientConfig: workspace.temporaryCognitoCredentials || null,
        });
        setViewerParams({
          contentType: props.params.type,
          uri: results,
          name: props.params.name
        });
      }
    })();
  }, [props.params]);

  if (!viewerParams) {
    return <></>;
  }

  // This is where we need the original file name, as for web the uri is a signed URL that doesn't have a file extension
  const viewerType: ContentTypes = selectViewerType(viewerParams.name);

  switch (viewerType) {
    // TODO: Get the video viewer working
    case ContentTypes.PDF:
    case ContentTypes.IMAGE:
      return <PdfViewer params={viewerParams} />;
    // case ContentTypes.VIDEO:
    //   return <VideoViewer params={viewerParams} />;

    default:
      return <></>;
  }
};

export const ViewerLayout: FunctionComponent<Props> = function ViewerLayout(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { header, route } = props;

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>{header}</View>
      <View style={styles.viewerContainer}>
        <Viewer params={route.params} />
      </View>
    </View>
  );
};

export default ViewerLayout;
