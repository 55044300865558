import React, { useEffect, useState } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { RouteProp } from '@react-navigation/native';

import {
  useWorkspace,
  WorkspaceContextI,
} from '../../../../context/workspace/workspace.context';
import { ComponentTargetTypes } from '../../../../enums/component.enum';
import { Styles } from '../../../../models/styles.model';

import { ComponentConfigWrapper } from '../../../../components/util/ComponentConfigWrapper';
import { useComponentNavigation } from '../../../../hooks/component-navigation.hook';
import TopDrawer from '../../components/drawers/TopDrawer';
import PD2BottomMenu from '../../components/menus/pd2-bottom.menu';
import HorizontalLowerThirdScroll from '../../components/scrolls/horizontal-lower-third';
import HorizontalTitleStripeScroll from '../../components/scrolls/horizontal-title-stripe';
import { TemplateNavigationEnum } from '../../enums/navigation.enum';
import { TemplateStackParamList } from '../../models/template.model';

interface Props {
  workspace?: WorkspaceContextI;
  route: RouteProp<TemplateStackParamList, TemplateNavigationEnum>;
}

const styles: Styles = StyleSheet.create({
  container: {
    // BackgroundColor: '#3c3c3c',
    backgroundColor: '#4c4c4c',
    flex: 1,
    flexDirection: 'column',
  } as ViewStyle,
  lowerThirdScroll: {
    marginTop: 132,
  } as ViewStyle,
  overlay: {
    backgroundColor: 'rgba(255,255,255, 0.7)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 20,
  } as ViewStyle,
  pd2BottomMenu: {
    marginTop: 30,
  } as ViewStyle,
  titleStripeScroll: {
    marginTop: 50,
  } as ViewStyle,
});

export const Pd2v20210625Layout: React.FunctionComponent<Props> =
  function Pd2v20210625Layout(
    props: React.PropsWithChildren<Props>,
  ): JSX.Element {
    const { route } = props;

    const [componentConfig, setComponentConfig] = useState<any>({});
    const [workspace] = useWorkspace();

    const setNavigationItemId: (
      id: number,
      type: ComponentTargetTypes,
    ) => void = useComponentNavigation();

    // TODO Remove once Jared converts JSON array to object.
    useEffect(() => {
      if (route.params && route.params.config.components.length > 0) {
        setComponentConfig(
          route.params.config.components.reduce((acc: any, current: any) => {
            acc[current.key] = current;
            return acc;
          }, {}),
        );
      }
    }, [route.params?.config]);

    function handleItemTap(item: any): void {
      setNavigationItemId(item.id, item.type);
    }

    return componentConfig ? (
      <>
        <View style={styles.container}>
          {/* {workspace.highlightSelectedItem && <View style={styles.overlay} />} */}
          <ComponentConfigWrapper config={componentConfig.topDrawer}>
            <TopDrawer onPress={handleItemTap} />
          </ComponentConfigWrapper>

          <ComponentConfigWrapper config={componentConfig.topSlider}>
            <HorizontalLowerThirdScroll
              shouldLeftIndent={true}
              style={styles.lowerThirdScroll}
              onPress={handleItemTap}
              selectedItem={workspace.highlightSelectedItem}
            />
          </ComponentConfigWrapper>

          <ComponentConfigWrapper config={componentConfig.bottomSlider}>
            <HorizontalTitleStripeScroll
              shouldLeftIndent={true}
              style={styles.titleStripeScroll}
              onPress={handleItemTap}
            />
          </ComponentConfigWrapper>

          <ComponentConfigWrapper config={componentConfig.footer}>
            <PD2BottomMenu
              logoConfig={componentConfig.logo}
              style={styles.pd2BottomMenu}
              onPress={handleItemTap}
            />
          </ComponentConfigWrapper>
        </View>
      </>
    ) : (
      <></>
    );
  };

export default Pd2v20210625Layout;
