import React from 'react';

import SplitIconButton from '../../components/buttons/SplitIcon';
import { GenericLoginPreferences } from '../../models/workspace.model';

interface Props {
  preferences?: GenericLoginPreferences;
  onPress?: () => void;
}

export const GenericLoginButton: React.FunctionComponent<Props> =
  function GenericLoginButton(
    props: React.PropsWithChildren<Props>,
  ): JSX.Element {
    return props.preferences ? (
      <SplitIconButton
        iconKey={props.preferences.iconKey}
        iconUrl={props.preferences.iconUrl}
        isFirst={true}
        primaryColor={'#0d69b9'} // Coming soon.
        onPress={props.onPress}
        title={props.preferences.displayName}
      />
    ) : (
      <></>
    );
  };

export default GenericLoginButton;
